import React, { useEffect } from 'react'
import api from '../../api'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, ButtonBase } from '@material-ui/core';
import { Map, ImageOverlay, Marker, Tooltip, ZoomControl } from 'react-leaflet';
import DevicetrendChart from 'views/DevicetrendChart';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import 'leaflet/dist/leaflet.css';
import "../../assets/css/leaflet.css";
import { useSelector } from 'react-redux';
import Warning from "components/Typography/Warning";
import Danger from "components/Typography/Danger";
import Success from 'components/Typography/Success';
import FloorTempIcon from 'assets/img/FloorTempIcon';
import FloorHumIcon from 'assets/img/FloorHumIcon';
import AirQualityIcon from 'assets/img/AirQualityIcon';
import ControlWhite from 'assets/img/ControlWhite';
import Control from 'assets/img/Control';
import Modal from '@material-ui/core/Modal';
import { SemanticToastContainer } from 'react-semantic-toasts';
import '../../assets/css/leaflet.sass';
import CardContent from '@material-ui/core/CardContent';
import ReactSimpleRange from "react-simple-range";
import {
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import NotificationLow from "assets/img/Notification";
import SwitchSelector from "react-switch-selector";
import Box from '@material-ui/core/Box';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import FloorTempIconWhite from 'assets/img/FloorTempIconWhite';
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
const Leaflet = require('leaflet');

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '2%'
  },
  alerts: {
    width: '8%',
    height: '7%',
    borderRadius: '3%'
  },
  ahucard: {
    width: '10%',
    height: '10vh',
    borderRadius: '1vh'
  },
  errorcard: {
    width: '73%',
    height: '10vh',
    marginLeft: '2%',
  },
  datacards: {
    width: '100%',
    height: '8.5vh',
    margin: '4%',
    borderRadius: '0.8vw',
    boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)"
  },
  emptycards: {
    width: '100%',
    height: '8.5vh',
    margin: '4%',
    borderRadius: '0.8vw',
    boxShadow: "inset 0px 0px 0px 2px rgb(179 184 179)"
  },
  vavcard: {
    width: '11%',
    height: '42vh',
    boxShadow: 'inset 0 4px 20px rgba(0, 0, 0, .1)',
    backgroundColor: '#ffffff',
    margin: '1%'
  },
  vavbutton: {
    width: '27%',
    height: '3vh',
    backgroundColor: "#D3D3D3",
    border: "none",
    cursor: "pointer",
    fontSize: "1.0vh",
    borderRadius: "0.4vw",
    margin: '2%'
  },
  selector: {
    width: '13%',
    height: '20vh',
    marginTop: '-16%',
    borderRadius: '0.8vw'
  },
  selctioncards: {
    margin: '4%'
  },
  mapcard: {
    width: '83%',
    height: '64vh',
    margin: '1%'
  },
  graphcard: {
    width: '91%',
    height: '25vh',
    margin: '2%',
    borderRadius: '0.8vw'
  },
  danger: {
    fontWeight: 'bold',
    fontSize: '3.1vh'
  }, paper: {
    position: 'absolute',
    width: 650,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },paper1: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height:"43vh",
    width:"69vh"
  };
}

function Devicemap(props) {
  const classes = useStyles();
  const mapRef = React.createRef()
  const [AhuOpen, setAhuOpen] = React.useState(false)
  const [heatMapData, setHeatMapdata] = React.useState({
    rectData: [],
    addressPoints: [],
    mapSubType: props.param ? props.param.toLocaleLowerCase() : "ahu"
  })
  const alerts = useSelector(state => state.alarm.alarmData)
  const [criticalAlertsAhu, setcriticalAlertsAhu] = React.useState(0);
  const [soluAlertsAhu, setsoluAlertsAhu] = React.useState(0);
  const [eachAhuData, setEachAhuDAta] = React.useState([])
  const [iconDevice, setIconDevice] = React.useState({});
  const [tempOpen, setTempOpen] = React.useState(false);
  const [humOpen, setHumOpen] = React.useState(false);
  const [luxOpen, setLuxOpen] = React.useState(false);
  const buildingID = useSelector(state => state.isLogged.data.building.id);
  const iconDevice1 = new Leaflet.Icon({
    iconUrl: require('../../assets/img/thl-1.png'),
    iconRetinaUrl: require('../../assets/img/thl-1.png'),
    iconSize: new Leaflet.Point(25, 25),
    className: 'leaflet-div-icon-1'
  });
  const iconDevice3 = new Leaflet.Icon({
    iconUrl: require('../../assets/img/AHU.png'),
    iconRetinaUrl: require('../../assets/img/AHU.png'),
    iconSize: new Leaflet.Point(60, 60),
    className: 'leaflet-div-icon-2'
  });
  const [deviceTrendData, setDeviceTrend] = React.useState([])
  const [openmodal, setOpenmodal] = React.useState(false)
  const [modalStyle] = React.useState(getModalStyle);
  const [setpt, setSetpt] = React.useState("");
  const [text, setText] = React.useState(false);
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');
  
  const options1 = [
    {
      label: "Auto",
      value: "auto",
      selectedBackgroundColor: "green",
      fontSize:"12"
    },
    {
      label: "Manual",
      value: "manual",
      selectedBackgroundColor: "green",
      fontSize:"12"

    },
  ];
  const initialSelectedIndex1 = options1.findIndex(
    ({ value }) => value === "manual"
  );

  useEffect(() => {
    localStorage.removeItem("type")
    let type = heatMapData.mapSubType === "aqi" ? "all" : heatMapData.mapSubType === '' ? 'ahu' : heatMapData.mapSubType
    switch (type) {
      case "ahu": setAhuOpen(true)
        setIconDevice(iconDevice3)
        break;
      default: break;
    }
    let f_id = localStorage.getItem('floorID')
    api.floor.devicemap(f_id, type.toLocaleUpperCase()).then((res) => {
      if (res.length !== 0) {
        let sdevices = []
        let CriticalTotal = 0
        let solutionTotal = 0
        if (alerts.system.length === 0 && alerts.solution.length === 0) {
          let con = 0
          res.map(element => {
            let obj = {}
            con++
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            obj["alerts_cri"] = 0
            obj["alerts_solu"] = 0
            sdevices.push(obj)
            if (res.length === con) {
              setEachAhuDAta(sdevices)
            }
            return element
          })
        }


        if (alerts.system.length > 0 && alerts.solution.length > 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.system.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                CriticalTotal++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  setcriticalAlertsAhu(CriticalTotal)
                  let si = 0
                  let counts = 0
                  alerts.solution.map(ele1 => {
                    if (element.name === ele1.device_name) {
                      counts++
                      si++
                      solutionTotal++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachAhuDAta(sdevices)
                        setsoluAlertsAhu(solutionTotal)
                      }
                    } else {
                      counts++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachAhuDAta(sdevices)
                        setsoluAlertsAhu(solutionTotal)
                      }
                    }
                    return ele1
                  })

                }
              } else {
                count++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  setcriticalAlertsAhu(CriticalTotal)
                  let si = 0
                  let counts = 0
                  alerts.solution.map(ele => {
                    if (element.name === ele.device_name) {
                      counts++
                      si++
                      solutionTotal++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachAhuDAta(sdevices)
                        setsoluAlertsAhu(solutionTotal)
                      }
                    } else {
                      counts++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachAhuDAta(sdevices)
                        setsoluAlertsAhu(solutionTotal)
                      }
                    }
                    return ele
                  })
                }
              }
              return ele
            })
            return element
          })
        }
        if (alerts.system.length > 0 && alerts.solution.length === 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.system.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                CriticalTotal++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  obj["alerts_solu"] = 0
                  setcriticalAlertsAhu(CriticalTotal)
                  sdevices.push(obj)
                  setEachAhuDAta(sdevices)
                }
              } else {
                count++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  obj["alerts_solu"] = 0
                  setcriticalAlertsAhu(CriticalTotal)
                  sdevices.push(obj)
                  setEachAhuDAta(sdevices)
                }
              }
              return ele
            })
            return element
          })


        }
        if (alerts.system.length === 0 && alerts.solution.length > 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.solution.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                solutionTotal++
                if (alerts.solution.length === count) {
                  obj["alerts_solu"] = ci
                  obj["alerts_cri"] = 0
                  sdevices.push(obj)
                  setsoluAlertsAhu(solutionTotal)
                  setEachAhuDAta(sdevices)
                }
              } else {
                count++
                if (alerts.solution.length === count) {
                  obj["alerts_solu"] = ci
                  obj["alerts_cri"] = 0
                  sdevices.push(obj)
                  setsoluAlertsAhu(solutionTotal)
                  setEachAhuDAta(sdevices)
                }
              }
              return ele
            })
            return element
          })
        }
        let zoneData = []
        setHeatMapdata({
          ...heatMapData,
          rectData: zoneData,
          addressPoints: res,
          mapSubType: type
        })
      } else {
        setHeatMapdata({
          ...heatMapData,
          rectData: [],
          addressPoints: [],
          mapSubType: ""
        })
        setEachAhuDAta([]);
      }
    }).catch((error)=>{
        setOpenerr(true)
        setErrmsg(error.response.data.message)
    })
    api.dashboard.getMetricData(buildingID).then(res => {
    }).catch((error)=>{
      setOpenerr(true)
      setErrmsg(error.response.data.message)
    })
    const trendData = [{
      // 'ahu_chill_water_temperature':[],
      //'ahu_chilled_valve': [],
      'ChW Valve': [],
      'RAT': [],
      'SAT': []
      // 'ahu_filter_status':[],
      // 'ahu_in_air_temperature':[],
      // 'ahu_on_off':[],
      // 'ahu_run_status':[],
      // 'ahu_set_point':[],
      //'ahu_supply_air_temperature': [],
      // 'ahu_trip_status':[],
      // 'ahu_vfd_mode':[],
      // 'fan_motor_speed':[],
      // 'mode':[],
      //'supply_air_flow': []
    }]
    api.floor.ae(props.data.location.state.data).then(res => {
      if (res.length !== 0) {
        for (let keysss in trendData[0]) {
          res.forEach(obj => {
            for (let da in obj) {
              if (da === keysss) {
                trendData[0][keysss].push(obj[da])
              }
            }
          })
        }
        setDeviceTrend(trendData)
      } else {
        setDeviceTrend([]);
      }
    }).catch((error)=>{
      setOpenerr(true)
      setErrmsg(error.response.data.message)
    })
    //eslint-disable-next-line
  }, [localStorage.getItem('floorName')]);


  const handleDeviceClick = (value) => {}

  const handleerrorclose = () => {
    setOpenerr(false);
    setErrmsg('');
  };
  
  const onChangetype = newValue => {
    let type = heatMapData.mapSubType === "aqi" ? "all" : heatMapData.mapSubType
    switch (newValue) {
      case "AHU":
        setAhuOpen(true)
        setTempOpen(false)
        setHumOpen(false)
        setLuxOpen(false)
        setIconDevice(iconDevice3)
        break
      case "luminousity":
        setLuxOpen(true)
        setAhuOpen(false)
        setTempOpen(false)
        setHumOpen(false)
        setIconDevice(iconDevice1)
        break
      case "temperature":
        setTempOpen(true)
        setLuxOpen(false)
        setAhuOpen(false)
        setHumOpen(false)
        setIconDevice(iconDevice1)
        break
      case "humidity":
        setHumOpen(true)
        setTempOpen(false)
        setLuxOpen(false)
        setAhuOpen(false)
        setIconDevice(iconDevice1)
        break
      default: break;
    }
    newValue === 'AHU' ?

      api.floor.devicemap(props.data.location.state.data, newValue.toLocaleUpperCase()).then(res => {
        let zoneData = []
        setAhuOpen(true)
        setHeatMapdata({
          ...heatMapData,
          rectData: zoneData,
          addressPoints: res,
          mapSubType: type
        })
      }).catch((error) =>{
        setOpenerr(true)
        setErrmsg(error.response.data.message)
      })
      :
      api.floor.heatmap(props.data.location.state.data, newValue.toLocaleUpperCase()).then(res => {
        let zoneData = [], devData = [];
        let obj = {}, deviceObj = {};
        res.map(_res => {
          obj = {}
          obj.zone_id = _res.id
          obj.bound = JSON.parse(_res.coordinates)
          obj.color = _res.color
          obj.value = _res.value
          zoneData.push(obj)
          _res.devices.map(_device => {
            deviceObj = {}
            deviceObj.id = _device.ssid
            deviceObj.name = _device.ss_name
            deviceObj.type = _device.name
            deviceObj.color = _device.color
            deviceObj.value = _device.param_value
            deviceObj.coordinates = JSON.parse(_device.coordinates)
            devData.push(deviceObj)
            return _device
          })
          return _res
        })
        setHeatMapdata({
          ...heatMapData,
          rectData: zoneData,
          addressPoints: devData,
          mapSubType: type
        })
      }).catch((error)=>{
        setOpenerr(true)
        setErrmsg(error.response.data.message)
      })
  };
  const onClickIssue = (id, name, param) => {
    localStorage.setItem("deviceID", "Ahu2");
    localStorage.setItem("deviceName", "Ahu1");
    props.data.history.push({
      pathname: `/admin/glAhu`,
      state: {
        data: id,
        name: name
      }
    })
  }
  const handlevavclick = () => {
    setOpenmodal(true)
    setSetpt('')
  }
  
  const handleClose = () => {
    setOpenmodal(false)
    setText(false)
  }
  const onChange = (newValue) => {
  };
  const body = (
    <div>
      <Grid container direction='column'justify='space-evenly'>
        <CancelOutlinedIcon  style={{marginLeft:"571px",marginTop:"24px",color:"pink",fontSize:"5vh"}} onClick={handleClose} />
          <Box boxShadow={3}  style={modalStyle} className={classes.paper}  spacing={1}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <Grid container xs={12} direction="column">
                  <Grid item xs={6} style={{fontSize:"3vh",color:"black",marginTop:"-1vh"}}>Software</Grid>
                  <Grid item xs={6}  style={{fontSize:"2vh",textDecorationLine:"underline",color:"#0123b4"}}>3F-PH1-VAV1</Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container xs={12} >
                  <Grid item xs={12}>
                    <ButtonBase style={{width:"100%",alignItems:"flex-end"}}  >
                      <Card style={{height:"35px",marginLeft:"22vh",marginTop:"1vh"}}>
                        <Grid container xs={12}>
                          <Grid xs={6}>
                              <NotificationLow />
                            </Grid>
                          </Grid>    
                      </Card>
                    </ButtonBase>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
                <Grid container item spacing={1}>
                  <Grid item xs={4}>
                    <Card className={classes.paper1} style={{height:"15vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",}}>
                      <Grid container xs={12} spacing={1} direction="column" style={{justifyContent:"right",alignItems:"center",marginTop:"-1vh"}}>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",fontSize:"11px"}}>
                                Temperature Set Point                          
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                                  <Grid item xs={12} style={{whiteSpace:"nowrap",fontSize:"3vh",color:"#0123b4",fontWeight:"bold"}}>
                                      23°C                    
                                  </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                                  <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                        <div style={{width:'100px',marginTop:"-1vh",marginLeft:"-1vh"}}>
                                          <ReactSimpleRange 
                                          min={0}
                                          max={100}
                                          step={1}
                                          trackColor='blue'
                                          thumbColor="blue"
                                          label={true}
                                          eventWrapperPadding={8}/>
                                        </div>               
                                  </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper1} style={{height:"15vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                      <Grid container xs={12} spacing={1}>                       
                        <Grid container item xs={12}>
                              <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",fontSize:"11px"}}>
                                  Damper Control                          
                              </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                            <div style={{ width: "15vh", height: "3vh",marginLeft:"-1vh",marginTop:"-1vh"}}>
                              <SwitchSelector
                                  onChange={onChange}
                                  options={options1}
                                  initialSelectedIndex={initialSelectedIndex1}
                                  // border="1px solid #0123B4"
                                  backgroundColor={"#e9e5e5"}
                                  fontColor={"rgba(0, 0, 0, 0.87)"}
                                // wrapperBorderRadius={true}
                                //optionBorderRadius={true}
                              />
                            </div>                         
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                              <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                    <div style={{color:"#0123b4",fontWeight:"bold"}}>50%</div>                      
                              </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                            <div style={{width:'100px',marginTop:"-4px",marginLeft:"26px"}}>
                                  <ReactSimpleRange 
                                  min={0}
                                  max={100}
                                  step={1}
                                  trackColor='blue'
                                  thumbColor="blue"
                                  label={true}
                                  eventWrapperPadding={8}
                                  />
                            </div>                          
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper1} style={{height:"15vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                      <Grid container xs={12} direction="column" spacing={1} style={{alignContent:"center",alignItems:"center"}}>
                        <Grid item xs={2} style={{whiteSpace:"nowrap",color:"black",marginLeft:"-6vh"}}>
                          Design CFM
                        </Grid> 
                        <Grid item xs={10}>
                          <Grid container xs={12} direction="column" spacing={1} style={{color:"#0123b4",whiteSpace:"nowrap",fontSize:"2vh",fontWeight:"bold"}}>
                            <Grid item xs={6}>250</Grid>
                            <Grid item xs={6}>230</Grid>
                          </Grid>
                        </Grid> 
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container item spacing={1}>
                  <Grid item xs={4}>
                    <Card className={classes.paper1} style={{height:"15vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",backgroundColor:"#fed0c1",color:"#fc6434"}} >
                      <div style={{fontSize:"5vh",marginTop:"2vh",fontWeight:"bold"}}>
                        26°C
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper1} style={{height:"15vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",}}>
                      <Grid container xs={12} spacing={1} direction="column" >                        
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{marginTop:"-2vh"}}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={85} orientation="up" percentage={50} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                            Damper Position      
                          </Grid>
                        </Grid>
                      </Grid>  
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper1} style={{height:"15vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",}}>
                      <Grid container xs={12} spacing={1} direction="column">                       
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{marginTop:"-2vh"}}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={85} orientation="up" percentage={25} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                            CFM
                          </Grid>
                        </Grid>
                      </Grid> 
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
          </Box> 
          <Grid container direction='row' justify="space-evenly" alignItems='center'>
          </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={classes.root}>
      <Snackbar open={openerr} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert style={{ cursor: "pointer" }} severity="error" variant="filled" onClose={handleerrorclose}>
          {errmsg}
        </Alert>
      </Snackbar>
      <Grid container xs={12} direction='row'>
        <Grid xs={9}>
          <Grid container direction="row">
            <Card className={classes.ahucard}>
              <CardContent style={{ textAlign: 'center', marginTop: '-14%' }}>
                <div className={classes.danger}><Danger>{parseInt(criticalAlertsAhu) + parseInt(soluAlertsAhu)}</Danger></div>
                <Typography gutterBottom style={{ fontSize: '0.83vw', fontWeight: 'bold' }}>
                  Total Alarm
                </Typography>
              </CardContent>
            </Card>
            <Card className={classes.ahucard}>
              <CardContent style={{ textAlign: 'center', marginLeft: '18%' }}>
                <Grid container direction='column' spacing={2}>
                  <Grid container direction='row'>
                    <Typography style={{ fontSize: '0.8vw' }}>Critical</Typography>
                    <Typography style={{ color: "red", fontSize: '1.0vw', marginLeft: '12%', fontWeight: 'bold' }}>{parseInt(criticalAlertsAhu)}</Typography>
                  </Grid>
                  <Grid container direction='row'>
                    <Typography style={{ fontSize: '0.8vw' }}>Low</Typography>
                    <Typography style={{ color: "#ff9800", fontSize: '1.0vw', marginLeft: '12%', fontWeight: 'bold' }}>{parseInt(soluAlertsAhu)}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.errorcard}>
              {eachAhuData.map(Element =>
                parseInt(Element.alerts_cri) + parseInt(Element.alerts_solu) === 0 ?
                  <ButtonBase style={{ width: "14%" }} onClick={() => onClickIssue(Element.ssid, Element.name, 'temperature')}>
                    <Card className={classes.datacards}>
                      <Typography style={{ justifyContent: 'center', textAlign: 'center', marginTop: '25%', fontSize: '0.83vw' }}>{Element.name}</Typography>
                      <Success style={{ justifyContent: 'center', textAlign: 'center' }}>{parseInt(Element.alerts_cri) + parseInt(Element.alerts_solu)}</Success>
                    </Card>
                  </ButtonBase>
                  : parseInt(Element.alerts_cri) + parseInt(Element.alerts_solu) === 1 ?
                    <ButtonBase style={{ width: "14%" }} onClick={() => onClickIssue(Element.ssid, Element.name, 'temperature')}>
                      <Card className={classes.datacards}>
                        <Typography style={{ justifyContent: 'center', textAlign: 'center', marginTop: '25%', fontSize: '0.83vw' }}>{Element.name}</Typography>
                        <Warning style={{ justifyContent: 'center', textAlign: 'center' }}>{parseInt(Element.alerts_cri) + parseInt(Element.alerts_solu)}</Warning>
                      </Card>
                    </ButtonBase>
                    :
                    <ButtonBase style={{ width: "14%" }} onClick={() => onClickIssue(Element.ssid, Element.name, 'temperature')}>
                      <Card className={classes.datacards}>
                        <Typography style={{ justifyContent: 'center', textAlign: 'center', marginTop: '25%', fontSize: '0.83vw' }}>{Element.name}</Typography>
                        <Danger style={{ justifyContent: 'center', textAlign: 'center' }}>{parseInt(Element.alerts_cri) + parseInt(Element.alerts_solu)}</Danger>
                      </Card>
                    </ButtonBase>
              )}
              <ButtonBase style={{ width: "14%" }}>
                <Card className={classes.emptycards}>
                  <Typography style={{ justifyContent: 'center', textAlign: 'center' }}></Typography>
                </Card>
              </ButtonBase>
              <ButtonBase style={{ width: "14%" }}>
                <Card className={classes.emptycards}>
                  <Typography style={{ justifyContent: 'center', textAlign: 'center' }}></Typography>
                </Card>
              </ButtonBase>
              <ButtonBase style={{ width: "14%" }}>
                <Card className={classes.emptycards}>
                  <Typography style={{ justifyContent: 'center', textAlign: 'center' }}></Typography>
                </Card>
              </ButtonBase>
              <ButtonBase style={{ width: "14%" }}>
                <Card className={classes.emptycards}>
                  <Typography style={{ justifyContent: 'center', textAlign: 'center' }}></Typography>
                </Card>
              </ButtonBase>
              <ButtonBase style={{ width: "14%" }}>
                <Card className={classes.emptycards}>
                  <Typography style={{ justifyContent: 'center', textAlign: 'center' }}></Typography>
                </Card>
              </ButtonBase>
              <ButtonBase style={{ width: "13%" }}>
                <Card className={classes.emptycards}>
                  <Typography style={{ justifyContent: 'center', textAlign: 'center' }}></Typography>
                </Card>
              </ButtonBase>
            </Card>
          </Grid>
          <Grid container direction='row'>
            <Card className={classes.vavcard}>
              <Typography style={{ textAlign: "center", fontSize: '2vh', margin: '2%' }}>VAV</Typography>
              <Grid container direction="row">
                <button onClick={handlevavclick} className={classes.vavbutton} style={{ backgroundColor: "#21ba45", color: 'white' }}>1</button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
                <button onClick={handlevavclick} className={classes.vavbutton}></button>
              </Grid>
            </Card>
            <Card className={classes.mapcard}>
              <Map
                ref={mapRef}
                doubleClickZoom={false}
                zoomControl={false}
                dragging={false}
                scrollWheelZoom={false}
                crs={Leaflet.CRS.Simple}
                center={[0, 0]}
                attributionControl={false}
                // bounds={[[0, 0], [600, 730]]}
                bounds={[[0, 0], [420, 590]]}
                className={"floor-map"}
                onClick={(e) => { console.log({ x: e.latlng.lat, y: e.latlng.lng }) }}
              >
                <ImageOverlay
                  interactive
                  url={'https://localhost/' + localStorage.floorName + '.jpg'}
                  // bounds={[[50, 15], [600, 730]]}
                  // bounds={[[100, -8], [525, 640]]}
                  bounds={[[0, 0], [420, 590]]}
                />
                {heatMapData.addressPoints.map((value) =>
                  <HeatmapLayer
                    radius={5}
                    minOpacity={0.4}
                    blur={19}
                    maxZoom={0.5}
                    points={[value]}
                    latitudeExtractor={m => m.coordinates[0]}
                    longitudeExtractor={m => m.coordinates[1]}
                    intensityExtractor={m => heatMapData.mapSubType !== 'aqi' ? parseFloat(m.value) : ""}
                  />
                )}
                {heatMapData.mapSubType !== 'aqi' &&
                  heatMapData.addressPoints ? heatMapData.addressPoints.map((value1, index) =>
                    <Marker key={index}
                      position={[value1.coordinates[0], value1.coordinates[1]]}
                      onclick={() => handleDeviceClick(value1)}
                      icon={iconDevice}
                    >
                      <Tooltip opacity={1} >
                        {Object.keys(value1).map((key) => (
                          key !== "ssid" && key !== "coordinates" && key !== "zoneId" && key !== "zone_coordinates" && key !== "type" && key !== "zoneColor" && key !== "id" ? <p>{key}:{value1[key]}</p> : <></>
                        ))}
                      </Tooltip>
                    </Marker>
                  ) : ''}
                <ZoomControl position="bottomright" />
              </Map>
            </Card>
          </Grid>
          <Grid container direction='column' justify='space-evenly'>
            <Card className={classes.selector}>
              {AhuOpen ?
                <ButtonBase style={{ width: "100%", margin: '4%', color: "white", fontSize: '0.90vw', backgroundColor: "#5d8aa8" }} onClick={() => { onChangetype('AHU') }}>
                  <div style={{ marginRight: '8%' }}><ControlWhite /></div>
                  <Typography style={{ fontSize: '0.80vw' }}>Controllers</Typography>
                </ButtonBase>
                :
                <ButtonBase style={{ width: "100%", margin: '4%', color: "black", fontSize: '0.90vw' }} onClick={() => { onChangetype('AHU') }}>
                  <div style={{ marginRight: '8%' }}><Control /></div>
                  <Typography style={{ fontSize: '0.80vw' }}>Controllers</Typography>
                </ButtonBase>
              }
              {tempOpen ?
                <ButtonBase style={{ width: "100%", margin: '4%', backgroundColor: "#5d8aa8", fontSize: '0.90vw' }} onClick={() => { onChangetype('temperature') }} >
                  <div style={{ marginRight: '9%' }}><FloorTempIconWhite /></div>
                  <Typography style={{ fontSize: '0.80vw' }}>Temperature</Typography>
                </ButtonBase>
                :
                <ButtonBase style={{ width: "100%", margin: '4%', fontSize: '0.90vw' }} onClick={() => { onChangetype('temperature') }}>
                  <div style={{ marginRight: '9%' }}><FloorTempIcon /></div>
                  <Typography style={{ fontSize: '0.80vw' }}>Temperature</Typography>
                </ButtonBase>
              }
              {humOpen ?
                <ButtonBase style={{ width: "100%", margin: '4%',backgroundColor:"#5d8aa8", fontSize: '0.90vw' }} onClick={() => { onChangetype('humidity') }} >
                  <div style={{ marginRight: '20%' }}><FloorHumIcon /></div>
                  <Typography style={{ fontSize: '0.80vw' }}>Humidity</Typography>
                </ButtonBase>
                :
                <ButtonBase style={{ width: "100%", margin: '4%', color: "black", fontSize: '0.90vw' }} onClick={() => { onChangetype('humidity') }}>
                  <div style={{ marginRight: '20%' }}><FloorHumIcon /></div>
                  <Typography style={{ fontSize: '0.80vw' }}>Humidity</Typography>
                </ButtonBase>
              }
              {luxOpen ?
                <ButtonBase style={{ width: "100%", margin: '4%', backgroundColor:"#5d8aa8", fontSize: '0.90vw' }} onClick={() => { onChangetype('aqi') }} >
                  <div style={{ marginRight: '12%' }}><AirQualityIcon /></div>
                  <Typography style={{ fontSize: '0.80vw' }}>AirQuality</Typography>
                </ButtonBase>
                :
                <ButtonBase style={{ width: "100%", margin: '4%',color:"black", fontSize: '0.90vw' }} onClick={() => { onChangetype('aqi') }}>
                  <div style={{ marginRight: '12%' }}><AirQualityIcon /></div>
                  <Typography style={{ fontSize: '0.80vw' }}>AirQuality</Typography>
                </ButtonBase>
              }
            </Card>
          </Grid>
        </Grid>
        <Grid container xs={3} direction='column' spacing={2}>
          {
            deviceTrendData.map((trend) => (
              Object.keys(trend).map((key) => (
                <Card className={classes.graphcard}>
                  <DevicetrendChart data={trend[key]} param={key} changeContext={props.changeContext} history={props.data.history} />
                </Card>
              ))
            ))
          }
        </Grid>
      </Grid>
      {openmodal ?
        <Modal
          open={openmodal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        :
        <div></div>
      }
      <SemanticToastContainer position='top-center' />
    </div>
  )

}

export default Devicemap
