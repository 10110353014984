import React from 'react'

function NotificationIcon() {
  return (
  
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 50 50">
  <g id="Group_35" data-name="Group 35" transform="translate(-1570 -18)">
    <path id="Path_85" data-name="Path 85" d="M9,0H41a9,9,0,0,1,9,9V41a9,9,0,0,1-9,9H9a9,9,0,0,1-9-9V9A9,9,0,0,1,9,0Z" transform="translate(1570 18)" fill="#c70b12"/>
    <g id="Group_34" data-name="Group 34" transform="translate(-168 1)">
      <path id="Path_222" data-name="Path 222" d="M-11.978-2.184a1.375,1.375,0,0,0,.508,1.117,2.153,2.153,0,0,0,1.409.419H-4.7a4.782,4.782,0,0,0,.667,2.272A4.849,4.849,0,0,0-2.38,3.307a4.435,4.435,0,0,0,2.374.641A4.492,4.492,0,0,0,2.38,3.313,4.778,4.778,0,0,0,4.037,1.631,4.82,4.82,0,0,0,4.7-.647h5.357a2.153,2.153,0,0,0,1.409-.419,1.375,1.375,0,0,0,.508-1.117,2.315,2.315,0,0,0-.368-1.231,6.911,6.911,0,0,0-.92-1.168q-.552-.571-1.1-1.143a2.938,2.938,0,0,1-.654-1.13,8.465,8.465,0,0,1-.349-1.485q-.114-.787-.165-1.523a18.9,18.9,0,0,0-.527-4.4,8.212,8.212,0,0,0-1.517-3.167A6.051,6.051,0,0,0,3.663-19.31a4.127,4.127,0,0,0-1.289-2.107,3.455,3.455,0,0,0-2.38-.863,3.437,3.437,0,0,0-2.368.863A4.127,4.127,0,0,0-3.663-19.31a6.094,6.094,0,0,0-2.723,1.879A8.094,8.094,0,0,0-7.9-14.263a19.149,19.149,0,0,0-.521,4.4q-.051.736-.165,1.523a8.465,8.465,0,0,1-.349,1.485,2.938,2.938,0,0,1-.654,1.13q-.546.571-1.1,1.143a6.911,6.911,0,0,0-.92,1.168A2.315,2.315,0,0,0-11.978-2.184Zm2.463-.381v-.152a4.164,4.164,0,0,1,.381-.476q.267-.3.6-.654t.667-.724a4.034,4.034,0,0,0,.6-.882,6.236,6.236,0,0,0,.432-1.136A10.124,10.124,0,0,0-6.557-8q.1-.774.152-1.7a15.844,15.844,0,0,1,.571-4.393,5.465,5.465,0,0,1,1.39-2.418,4.789,4.789,0,0,1,1.961-1.1.69.69,0,0,0,.368-.184.567.567,0,0,0,.14-.375,2.6,2.6,0,0,1,.565-1.619,1.738,1.738,0,0,1,1.4-.628,1.752,1.752,0,0,1,1.416.628,2.6,2.6,0,0,1,.565,1.619.506.506,0,0,0,.133.375.718.718,0,0,0,.362.184,4.789,4.789,0,0,1,1.961,1.1,5.51,5.51,0,0,1,1.4,2.418A15.654,15.654,0,0,1,6.4-9.7q.038.927.146,1.7A11.529,11.529,0,0,0,6.83-6.589a5.94,5.94,0,0,0,.425,1.136,4.033,4.033,0,0,0,.6.882q.343.368.679.724t.6.654a4.465,4.465,0,0,1,.375.476v.152ZM-2.723-.647H2.723A3.053,3.053,0,0,1,1.9,1.4a2.568,2.568,0,0,1-1.9.755A2.574,2.574,0,0,1-1.9,1.4,2.989,2.989,0,0,1-2.723-.647Z" transform="translate(1763 52)" fill="#fff"/>
    </g>
  </g>
</svg>

   
  )
}

export default NotificationIcon