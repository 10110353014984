import React, { useEffect, useState,useMemo,useRef,useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../api";
import {
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    ListSubheader,
    TextField,
    InputAdornment
  } from '@material-ui/core';
  import SearchIcon from '@material-ui/icons/Search';
  import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ChartistGraph from "react-chartist";
// @material-ui/core
//import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
//import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Hidden from "@material-ui/core/Hidden";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesi from "assets/jss/material-dashboard-react/views/iconsStyle.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import TotalValueRenderer from './totalValueRenderer.jsx';
import Button from "components/CustomButtons/Button.js";
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import "../../assets/css/syncfusion-material.css";
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';
import 'semantic-ui-css/semantic.min.css'
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import { format, compareAsc } from 'date-fns'


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    gridItem:{
      padding:'0 0',
    }
  });
  const useStyles1 = makeStyles(styles);
  const useStyles2=makeStyles(stylesi)




function getColumns(myheading=null){
  //console.log("my heading in get com",myheading)
 // myheading = (myheading === null) ? { 'DevicaseName': "DeviceName", 'date': "date", 'time': "time", 'Category': " Category", 'Key': "Key", 'Value': "Value", 'Unit': "Unit" } : myheading;
 myheading={'date':'date','time':'time','MotorStatus':'MotorStatus','WaterflowRate(lpm)':'WaterflowRate_in_lpm','WF_per_Day(L)':'WF_per_Day_in_L','voltage(v)':'voltage_in_v','Current(A)':'Current_in_A','Power(W)':'power_in_watt','FaultCondition':'FaultCondition','WarningCondition':'WarningCondition'}
let mykeys = Object.keys(myheading);
let columnDefs = [];
for (let i=0; i<mykeys.length; i++){
 // console.log("mykeys--->",mykeys[i])
    switch (mykeys[i]){
      case 'Device_name' :
        columnDefs.push({'field': mykeys[i], 'filter': true,width:'157px','sortable': true,'tooltipField': 'Device_name' }); 
        break;
        case 'date' :
          columnDefs.push({'field': mykeys[i], 'filter': true,width:'165px','sortable': true,'tooltipField': 'Device_name' }); 
          break;
          case 'time' :
            columnDefs.push({'field': mykeys[i], 'filter': true,width:'157px','sortable': true,'tooltipField': 'Device_name' }); 
            break;
        case 'FaultCondition' :
            columnDefs.push({'field': mykeys[i], 'filter': true,width:'157px','sortable': true,'tooltipField': 'Device_name' ,
            'cellStyle': function(params) { if (params.value=='NO FAULT'){ return {backgroundColor:'green',color: 'black'}; } else { { return {backgroundColor:'red',color: 'black'}; } }  }  
          }); //NO WARNING
            break;
        case 'WarningCondition' :
              columnDefs.push({'field': mykeys[i], 'filter': true,width:'157px','sortable': true,'tooltipField': 'Device_name' ,
              'cellStyle': function(params) { if (params.value=='NO WARNING'){ return {backgroundColor:'green',color: 'black'}; } else { { return {backgroundColor:'yellow',color: 'black'}; } }  }  
            }); //NO WARNING
              break; 
      case 'Acknowledgement' : 
      columnDefs.push({'field': mykeys[i], 'filter': true,width:'170px', 'suppressMenuHide':true ,'floatingFilterComponentParams': {suppressFilterButton:false},'sortable': true, 'cellRenderer': TotalValueRenderer});
      break;
      // case 'measured_time':console.log("----->")
      //       break;
      default :
       columnDefs.push({'field': mykeys[i], headerName:  mykeys[i],  'sortable': true,'suppressMenuHide':false ,'floatingFilterComponentParams': {suppressFilterButton:false},});  
    }
    
}
return columnDefs;
}



  function prepareReportData(rData) {
    rData.map((r_d) => {
      let mes_time = r_d["measured_time"].split(' ');
      r_d.date = mes_time[0]
      r_d.time = mes_time[1]
    })
 // console.log("rodata----->",rData)
    return { "Headings": getColumns(rData[0]), "RowData": rData }
  }

function Vdashaboard() {

    const classes1 = useStyles();
    const classes=useStyles1();
    const classesi=useStyles2();
    const [searchText, setSearchText] = useState("");
    const [deviceData,setDeviceData]=useState([])
    const [dataDisply,setDataDisply]=useState({ "Headings":   getColumns(), "RowData":  [] })
    const[devNames,setDevName]=useState([])
    const [selectedOption, setSelectedOption] = useState('');
    const[selectedDevId,setSelectedDevId]=useState('')
    const buildingID = useSelector((state) => state.isLogged.data.building.id);
    const [start,setStart]=useState(new Date(new Date(). getTime() - (24 * 60 * 60 * 1000)))
    const [end,setEnd]=useState(new Date(new Date(). getTime() ))
    const containsText = (text, searchText) =>
            text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

      var displayedOptions = useMemo(
        () => devNames.filter((option) => containsText(option, searchText)),
        [searchText,devNames]);
   
    
    useEffect(() => {
    api.floor.devicemap(buildingID,'solarPump').then(res=>{
      if(res.length>0){
        setDeviceData(res)
        let name=res.map(data=>data.name)
        setDevName(name)
        setSelectedOption(name[0])
        setSelectedDevId(res[0].ssid)
        api.floor.solarPump(res[0].ssid).then(res=>{
          setDataDisply( prepareReportData(res))
        })
      }
    })
    },[])

   const handleClick=(event)=>{
    //console.log("----------------------------------->",event.target.value)
    deviceData.forEach(data=>{
        if(data.name==event.target.value){
            // let myobj={}
            // myobj["DeviceName"]=data.name
            // myobj["MeasuredTime"]=data.measured_time
            // myobj["EventLogType"]=data.EventLogType
            // myobj["MotorStatus"]=data.MotorStatus
            // myobj["WaterFlowRate"]=data.WaterFlowRate
            // myobj["VoltageIn"]=data.VoltageIn
            // myobj["CurrentIn"]=data.CurrentIn
            // myobj["PowerUsage"]=data.PowerUsage
            // myobj["FaultCode"]=data.FaultCode
            // myobj["WarningCode"]=data.WarningCode
            // myobj["SunRadiation"]=data.SunRadiation
            // setDataDisply(myobj)
            //setDataDisply(data)
            let iid=data.ssid
           // console.log("my id",iid)
            setSelectedDevId(iid)
            api.floor.solarPump(iid).then(res=>{
            // console.log("last one hour data",prepareReportData(res))
             setDataDisply( prepareReportData(res))
          })

        }
    })
   }

   const getDataBtwDates=()=>{
    if (end <=start){
      toast({
        type: "error",
        icon: "exclamation triangle",
        title: "Error",
        description: "End date should be greater than start date",
        time: 0,
      });
    }else{
      let setStart=format(new Date(start), 'yyyy-MM-dd HH:mm:ss')
      let setEnd=format(new Date(end), 'yyyy-MM-dd HH:mm:ss')
      api.floor.solarPumpData(selectedDevId,setStart,setEnd).then(res=>{
        setDataDisply( prepareReportData(res))
      })
    }
   }

   const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);
   const last1hr=()=>{
    api.floor.solarPump(selectedDevId).then(res=>{
      //console.log("last one hour data",prepareReportData(res))
      setDataDisply( prepareReportData(res))
   })
   }
   const gridRef = useRef();
   const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);
//    var tifOptions = Object.keys(dataDisply).map(function(key) {
//     return <option value={key}>{key}-----{dataDisply[key]}</option>
// });

const onFirstDataRendered1 = useCallback((params)=>{
  gridRef.current.api.sizeColumnsToFit();
},[]);
const onSelectionChanged1= useCallback((alarms) => {
      
}, []);
  return (
    <div>
        <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
              <p className={classes.cardCategory}>Customer Name :</p>
              <p className={classes.cardCategory} >{localStorage.getItem("username")}</p>
        
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{color:''}}>
          <p className={classes.cardCategory}>Customer City :</p>
              <p className={classes.cardCategory} >{localStorage.getItem("buildingName")}</p>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
          <p className={classes.cardCategory}>Customer State :</p>
          <p className={classes.cardCategory}>Karnataka</p>
           
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <p className={classes.cardCategory}>123456789012345678901234</p>
            </CardHeader>
          </Card>
        </GridItem> */}
       
      </GridContainer>
      <GridContainer >
      <GridItem xs={2} sm={2} md={2}>
      <Card>
            <Box >
              <FormControl fullWidth>
                <InputLabel id="search-select-label">Devices</InputLabel>
                <Select
                  // Disables auto focus on MenuItems and allows TextField to be in focus
                  MenuProps={{ autoFocus: false }}
                  labelId="search-select-label"
                  id="search-select"
                  value={selectedOption}
                  label="Devices"
                  onChange={(e) => setSelectedOption(e.target.value)}
                  onClick={handleClick}
                  onClose={() => setSearchText("")}
                  // This prevents rendering empty string in Select's value
                  // if search text would exclude currently selected option.
                  renderValue={() => selectedOption}
                >
                  {/* TextField is put into ListSubheader so that it doesn't
            act as a selectable item in the menu
            i.e. we can click the TextField without triggering any selection.*/}
                  <ListSubheader>
                    <TextField
                      size="small"
                      // Autofocus on textfield
                      autoFocus
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {displayedOptions.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card plain>
                  <TableContainer component={Paper}>
                    <Table  aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>parameter</StyledTableCell>
                          <StyledTableCell align="right">value</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(dataDisply).map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row}
                            </StyledTableCell>
                            <StyledTableCell align="right">   {dataDisply[row]}</StyledTableCell>

                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </GridItem>
            </GridContainer> */}
        </Card>
        </GridItem>
        <GridItem xs={2} sm={2} md={2}>
        <Card style={{marginLeft:"-9%"}}>
        <Button variant="contained" color='transparent' style={{width:"100%"}} onClick={last1hr} >
                         last 1hr data                  
                                        </Button></Card>
        </GridItem>
       
        <GridItem xs={2} sm={2} md={2}>
        <Card style={{marginLeft:"-20%",width:"127%"}}>
        <label>From</label>
                  <DateTimePicker
                    name="start"
                    value={start} 
                    step={15}
                    //valueDisplayFormat={{ dateStyle: "short" }}
                    relativeSize={true}
                    onChange={value => setStart(value)}
                  />
                  </Card>
        </GridItem>
        <GridItem xs={2} sm={2} md={2}>
        <Card style={{marginLeft:"-7%",width:"126%"}}>
        <label>To</label>
                  <DateTimePicker
                    name="end"
                    value={end} 
                    step={15}
                    onChange={value => setEnd(value)}
                  />
                  </Card>
        </GridItem>
        <GridItem xs={2} sm={2} md={2}>
        <Card style={{marginLeft:"8%"}}>
        <Button variant="contained" color='transparent' style={{width:"100%"}} onClick={getDataBtwDates}   >
                         submit                
                                        </Button></Card>
        </GridItem>
        <GridItem xs={2} sm={2} md={2}>
        <Card>
        <Button variant="contained" color='transparent' style={{width:"100%"}}  onClick={onBtExport} >
                         Export to CSV                
                                        </Button></Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>      
            <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
            <AgGridReact
          ref={gridRef} 
          rowData={dataDisply["RowData"]} 
          columnDefs={dataDisply["Headings"]} 
           rowSelection={'single'} 
           onSelectionChanged={onSelectionChanged1}
          pagination={true}
          paginationPageSize={10}
          suppressMenuHide="true"
         // defaultColDef={defaultColDef}
         defaultColDef={defaultColDef}
          suppressFilterButton="true"
                     onFirstDataRendered = {onFirstDataRendered1}
          >
          </AgGridReact>
          </div>
          </GridItem>
            </GridContainer>
            <SemanticToastContainer position="top-center" />
    </div>

  )
}

export default Vdashaboard