import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Select, FormControl, MenuItem, InputLabel, Button as Buttons } from '@material-ui/core';
import { Radio, Card, Grid } from 'semantic-ui-react';
import { Card as Cards } from '@material-ui/core';
import { SemanticToastContainer, toast } from 'react-semantic-toasts'
import { message, Spin } from 'antd';
import SwitchSelector from "react-switch-selector";
import CircularProgress from '@material-ui/core/CircularProgress';
import api from "../../api";
import { Map, ImageOverlay, Marker, Tooltip, ZoomControl } from 'react-leaflet';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import 'leaflet/dist/leaflet.css';
import "../../assets/css/leaflet.css";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import '../../assets/css/leaflet.sass'
import { useSelector } from "react-redux";
import { CircleSlider } from "react-circle-slider";

const Leaflet = require('leaflet');
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2%'
    },
    zone: {
        width: '36%',
        height: '5%'
    },
    area: {
        width: '22%',
        height: '5%',
        marginLeft: '2%'
    },
    mapcard: {
        width: '100%',
        height: '74vh',
        marginTop: '2%',
        backgroundColor: '#ffffff',
        borderRadius: '0.83vw'
    },
    autobutton: {
        width: '3%',
        height: '3vh',
        fontSize: '0.7vw',
        borderRadius: '0.83vw',
        fontWeight: 'bold'
    },
    zonename: {
        fontSize: '1vw',
        fontWeight: 'bold'
    },
    areaname: {
        fontSize: '1vw',
        fontWeight: 'bold'
    },
    controlcards: {
        height: '27vh',
        borderRadius: '0.83vw',
        width: '23vw'
    },
    maincards: {
        width: '23vw',
        height: '27vh',
        borderRadius: '0.83vw'
    }
}))

export default function GlLms(props) {
    const classes = useStyles();
    const mapRef = React.createRef()
    let area = [], newArea = [], occupancy_area = [];
    let slavesArr = [];
    // const [setpt, set_setpt] = React.useState("");
    const [floor, setFloor] = useState([]);
    const [zone, setZone] = useState([]);
    const [areas, setAreas] = useState([]);
    const [devvice, setDevice] = useState([]);
    // const [fdata,setFdata]=useState(props.fdata);
    const [zdata, setZdata] = useState();
    const [adata, setAdata] = useState();
    // const [ddata,setDdata]=useState();
    const [boolfloor, setboolfloor] = React.useState(true);
    const [boolzone, setboolzone] = React.useState(props.zone);
    const [boolarea, setboolarea] = React.useState(props.area);
    const [booldevice, setbooldevice] = React.useState(false);
    // const [channelclick,setChannelclick]= useState(false);
    const [selectedChannel, setSelectedChannel] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [dropfloor, setDropfloor] = useState(props.dropfloor);
    const [dropzone, setDropzone] = useState([]);
    const [droparea, setDroparea] = useState([]);
    // const [dropdevice,setDropdevice]=useState([]);
    const [tempOpen, setTempOpen] = React.useState(false);
    const [humOpen, setHumOpen] = React.useState(false);
    const [luxOpen, setLuxOpen] = React.useState(false);
    const [ahuOpen, setAhuOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [locationId, setLocation] = React.useState(props.location);
    const [heatMapData, setHeatMapdata] = React.useState({
        rectData: [],
        addressPoints: [],
        mapSubType: props.param ? props.param.toLocaleLowerCase() : "temperature"
    })
    const [iconDevice, setIconDevice] = React.useState({});
    const [intensity, setIntensity] = React.useState(0);
    const campusId = useSelector(state => state.isLogged.data.campus.id)
    const userId = useSelector(state => state.isLogged.data.user.id)
    const userName = useSelector(state => state.isLogged.data.user.name)

    useEffect(() => {
        api.campus.getTreeList(campusId).then(res => {
            let arr = [];
            res.children.map(_each => {
                _each.children.map(_each => {
                    arr.push(_each)
                    area.push(_each.children)
                    return _each;
                })
                return _each
            })
            setFloor(arr)
            setLocation(arr[0].id)
            setIconDevice(iconDevice1)
            api.floor.heatmap(arr[0].id, heatMapData.mapSubType.toLocaleUpperCase()).then(res => {
                let zoneData = [], devData = [];
                let obj = {}, deviceObj = {};
                res.map(_res => {
                    obj = {}
                    obj.zone_name = _res.name
                    obj.zone_id = _res.id
                    obj.bound = JSON.parse(_res.coordinates)
                    obj.color = _res.color
                    obj.value = _res.value
                    zoneData.push(obj)
                    _res.devices.map(_device => {
                        deviceObj = {}
                        deviceObj.id = _device.ssid
                        deviceObj.name = _device.ss_name
                        deviceObj.type = _device.name
                        deviceObj.value = _device.param_value
                        deviceObj.coordinates = JSON.parse(_device.coordinates)
                        devData.push(deviceObj)
                        return _device
                    })
                    return _res
                })
                setHeatMapdata({
                    ...heatMapData,
                    rectData: zoneData,
                    addressPoints: devData,
                })
            })
            return res;
        })
        setDropfloor(props.dropfloor)
        setZone(props.zone)
        //eslint-disable-next-line
    }, [props])

    const handlezonechange = (name, id) => {
        api.controls.zonelights(id).then(res => {
            let masterArr = [];
            res.lights.map(_each => {
                if (_each.deviceType === "ANALOG_CONTROLLER") {
                    let statusarr = [];
                    let intensityarr = [];
                    const { master, channels } = _each;
                    let channel_value = channels.map((item, i) => item.name)
                    let value = channel_value.length === 2 ? 5 : channel_value[0].slice(8, 9)
                    if (channels.length === 2) {
                        statusarr.push(channels[0].status)
                        statusarr.push(channels[1].status)
                        intensityarr.push(channels[0].light_level)
                        intensityarr.push(channels[1].light_level)
                    } else {
                        statusarr.push(channels[0].status)
                        intensityarr.push(channels[0].light_level)
                    }
                    let findstatus = statusarr.every(i => i === statusarr[0])
                    let zonestatus = findstatus ? statusarr[0] : ""
                    let sum = 0
                    intensityarr.forEach((_ele, index) => {
                        sum += _ele
                    })
                    let values = (sum / (intensityarr.length * 100)) * 100
                    let findmode = statusarr.every(i => i === statusarr[0])
                    let zonemode = (findmode && (statusarr[0] === "On" || statusarr[0] === "Off")) ? "Manual" : (findmode && (statusarr[0] === "Auto")) ? "Auto" : "Mixed modes"
                    let status = master.cmd === undefined ? 'Server' : master.cmd
                    masterArr.push({ macId: master.mac, channel: JSON.parse(value) })
                    let areaObj = {
                        "id": master.areaId,
                        "zoneId": master.zoneId,
                        "name": master.areaName,
                        "master": masterArr,
                        "status": zonestatus,
                        "mode": zonemode,
                        "Intensity": Math.ceil(values),
                        "occupancy": '',
                        "cmd": status === 'Null' ? 'Tab' : status.charAt(0).toUpperCase() + status.slice(1),
                        "created_at": master.created_at
                    }
                    area.push(areaObj)
                }
                if (_each.deviceType === "DALI_CONTROLLER") {
                    const { master, slaves } = _each;
                    let areaObj = {};
                    slaves.map(_slave => {
                        let statusarr = slaves.filter(_s => _s.areaId === _slave.areaId).map(({ status }) => status)
                        let findstatus = statusarr.every(i => i === statusarr[0])
                        let zonestatus = findstatus ? statusarr[0] : ""
                        let intensityarr = slaves.filter(_s => _s.areaId === _slave.areaId).map(({ light_level }) => light_level)
                        let c_from = slaves.filter(_s => _s.areaId === _slave.areaId).map(({ cmd_data }) => cmd_data)
                        let findc_from = c_from.every(i => i === c_from[0])
                        let zonecmd = findc_from ? c_from[0] : "Mixed Modes"
                        let sum = 0
                        intensityarr.forEach((_ele, index) => {
                            sum += _ele
                        })
                        let value = (sum / (intensityarr.length * 100)) * 100
                        let findmode = statusarr.every(i => i === statusarr[0])
                        let zonemode = (findmode && (statusarr[0] === "On" || statusarr[0] === "Off")) ? "Manual" : (findmode && (statusarr[0] === "Auto")) ? "Auto" : "Mixed modes"
                        let time = slaves.filter(_s => _s.areaId === _slave.areaId).map(({ created_at }) => created_at).sort().reverse()
                        let status = zonecmd === undefined ? 'Server' : zonecmd
                        areaObj = {
                            "id": _slave.areaId,
                            "zoneId": master.zoneId,
                            "name": _slave.areaName,
                            "master": [{
                                macId: master.mac,
                                slaves: slaves.filter(_s => _s.areaId === _slave.areaId).map(({ mac }) => mac),
                                selection: "slaves"
                            }],
                            "status": zonestatus,
                            "mode": zonemode,
                            "Intensity": Math.ceil(value),
                            "occupancy": '',
                            "cmd": status === 'Null' ? 'Tab' : status.charAt(0).toUpperCase() + status.slice(1),
                            "created_at": time[0]
                        }
                        area.push(areaObj);
                        return _slave
                    })
                }
                return _each
            })
            res.occupancy.map(_item => {
                let occupancyobj = {
                    "id": _item.area,
                    "data": _item.data
                }
                occupancy_area.push(occupancyobj)
                return _item
            })
            newArea = area.reduce((acc, current) => {
                let x = acc.find(item => item.id === current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    if (x.master.map(_elem => _elem.macId).indexOf(current.master[0].macId) < 0) {
                        x.master.push(current.master[0])
                        acc.concat([x])
                    }
                    return acc
                }
            }, [])
            occupancy_area.forEach(_element => {
                newArea.forEach(element => {
                    if (_element.id === element.id) {
                        element.occupancy = _element.data
                    } else { }
                });
            });
            newArea.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
            area.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
            setAreas(newArea)
            setDropzone(newArea)
        })
        setLocation(id)
        setZdata(name)
        setboolarea(false)
        setboolfloor(false)
        setboolzone(true)
        setbooldevice(false)
    }
    const handleareachange = (name, id) => {
        api.controls.devicelights(id).then(res => {
            res.lights.map(_each => {
                if (_each.deviceType === "ANALOG_CONTROLLER") {
                    const { master, channels } = _each;
                    let cmd = master.cmd === undefined ? 'Server' : master.cmd
                    let deviceObj;
                    if (channels.length === 2) {
                        let status = channels.map((_item, index) => _item.status)
                        let findstatus = status.every(i => i === status[0])
                        let devicestatus = findstatus ? status[0] : ""
                        deviceObj = {
                            "id": master.mac,
                            "name": master.name,
                            "channel1": channels[0].dimmable,
                            "channel2": channels[1].dimmable,
                            "channel0": channels[0].dimmable && channels[1].dimmable,
                            "length": channels.length,
                            "master": [{
                                macId: master.mac
                            }],
                            "zone": master.zoneId,
                            "status1": channels[0].status,
                            "status2": channels[1].status,
                            "mode": ((channels[0].status === "On" || channels[0].status === "Off") || (channels[1].status === "on" || channels[1].status === "off")) ? "Manual" : "Auto",
                            "intensity": (channels[0].light_level === channels[1].light_level) ? channels[0].light_level : '',
                            "cmd": cmd === 'Null' ? 'Tab' : cmd.charAt(0).toUpperCase() + cmd.slice(1),
                            "created_at": master.created_at,
                            "status": devicestatus
                        }
                    } else {
                        deviceObj = {
                            "id": master.mac,
                            "name": master.name,
                            "channel1": channels[0].dimmable,
                            "lenght": channels.length,
                            "channel_name": channels[0].name,
                            "master": [{
                                macId: master.mac
                            }],
                            "zone": master.zoneId,
                            "status1": channels[0].status,
                            "mode": (channels[0].status === "On" || channels[0].status === "Off") ? "Manual" : "Auto",
                            "intensity": channels[0].light_level,
                            "cmd": cmd === 'Null' ? 'Tab' : cmd.charAt(0).toUpperCase() + cmd.slice(1),
                            "created_at": master.created_at,
                            "status": channels[0].status
                        }
                    }
                    slavesArr.push(deviceObj);
                }
                if (_each.deviceType === "DALI_CONTROLLER") {
                    const { master, slaves } = _each;
                    let slaveObj = {};
                    slaves.map(_slave => {
                        let dev_cmd = _slave.cmd_data !== undefined ? _slave.cmd_data : "Server"
                        slaveObj = {
                            "id": _slave.mac,
                            "mastername": master.name,
                            "name": _slave.name,
                            "zone": _slave.zoneId,
                            "master": [{
                                macId: master.mac,
                                slaves: [_slave.mac],
                                selection: "slaves"
                            }],
                            "status": _slave.status,
                            "Intensity": _slave.light_level,
                            "mode": (_slave.status === "On" || _slave.status === "Off") ? "Manual" : "Auto",
                            "cmd": dev_cmd === 'Null' ? 'Tab' : dev_cmd.charAt(0).toUpperCase() + dev_cmd.slice(1),
                            "created_at": _slave.created_at
                        }
                        slavesArr.push(slaveObj);
                        return _slave
                    })
                }
                return _each
            })
            slavesArr = slavesArr.reduce((acc, current) => {
                const x = acc.find(item => item.id === current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            slavesArr.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
            setDevice(slavesArr)
            setDroparea(slavesArr)

        })
        setLocation(id)
        setAdata(name)
        setboolarea(true)
        setboolzone(false)
        setboolfloor(false)
    }
    // const handledevicechange=(name,id)=>{
    //     setDdata(name)
    //     let i=0
    //     let display_device=[]
    //     device.map(element=>{
    //         if(element.id==id){
    //             display_device.push(element)
    //             i++
    //             if(i==device.length){
    //                 setDropdevice(display_device)
    //             }
    //         }else{
    //             i++
    //             if(i==device.length){
    //                 setDropdevice(display_device)
    //             }
    //         }
    //         return element
    //     })
    //     setboolarea(false)
    //     setboolzone(false)
    //     setbooldevice(true)
    // }
    const submit = (res, value, intensity, type) => {
        const user = {
            id: userId,
            name: userName
        }
        let p_zone = '';
        let req = {};
        let msg = '', channel;
        switch (value) {
            case 1: msg = 'switched ON'; break;
            case 0: msg = 'switched OFF'; break;
            case 4: msg = 'switched to Auto mode'; break;
            default: msg = 'set to intensity ' + intensity; break;
        }
        switch (selectedChannel.split("_")[0]) {
            case 'channel1': channel = 1; break;
            case 'channel2': channel = 2; break;
            case 'channel0': channel = 0; break;
            default: break;
        }
        let wac = [], dali = [];
        setLoading(true)
        res.master.map(_dev => {
            if (_dev.macId.substr(0, 4) === "50ac") {
                wac.push(_dev)
            } else {
                dali.push(_dev)
            }
            return _dev
        })
        if (type === 'z') {
            req = (wac.length > 0 && dali.length > 0) ?
                {
                    zone: res.id,
                    DALI: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        dali,
                    },
                    WAC: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        wac,
                    }
                } : (wac.length > 0) ? {
                    zone: res.id,
                    WAC: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        wac,
                    }
                } : {
                    zone: res.id,
                    DALI: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        dali,
                    }
                }
        } else if (type === 'a') {
            req = (wac.length > 0 && dali.length > 0) ?
                {
                    zone: res.zoneId,
                    DALI: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        dali,
                    },
                    WAC: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        wac,
                    }
                } : (wac.length > 0) ? {
                    zone: res.zoneId,
                    WAC: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        wac,
                    }
                } : {

                    zone: res.zoneId,
                    DALI: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        dali,
                    }
                }
        } else if (type === 'd') {
            let channel;
            if (res.id.slice(0, 3) === "50d") {
                req = {
                    zone: res.zone,
                    type: 'dali',
                    DALI: {
                        mode: (value === 4) ? "auto" : "manual",
                        intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                        dali: res.master
                    }
                }
            } else {
                if (selectedChannel.includes(res.id) || (res.channel0 === true || res.channel1 === true || res.channel2 === true)) {
                    if (selectedChannel === '') {
                        res.channel_name === 'Channel 2' ? channel = 2 : channel = 1
                    }
                    switch (selectedChannel.split("_")[0]) {
                        case 'channel1': channel = 3; break;
                        case 'channel2': channel = 4; break;
                        case 'channel0': channel = 5; break;
                        default: break;
                    }
                    setLoading(true)
                    req = {
                        zone: res.zone,
                        type: 'wac',
                        WAC: {
                            mode: (value === 4) ? "auto" : "manual",
                            intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                            wac: [{
                                macId: res.id,
                                channel: channel
                            }]
                        }
                    }
                } else if (res.channel0 !== true || res.channel1 !== true || res.channel2 !== true) {
                    if (res.length === 2) {
                        channel = 5
                    } else {
                        if (res.channel_name === 'Channel 4') {
                            channel = 4
                        } else channel = 3
                    }
                    req = {
                        zone: res.zone,
                        type: 'wac',
                        WAC: {
                            mode: (value === 4) ? "auto" : "manual",
                            intensity: (value === 0) ? 0 : (value === 1) ? 100 : parseInt(intensity, 10),
                            wac: [{
                                macId: res.id,
                                channel: channel
                            }]
                        }
                    }
                } else {
                    message.error("Please select a channel for this device!")
                    setLoading(false)
                }

            }
        }
        api.controls.controlLights(req, user).then(result => {
            if (result.message === "Please connect to a network") {
                message.error("Please connect to a network!!!", 5)
                setLoading(false)
                res.status = p_zone
            } else if (result.message === "failure") {
                setLoading(false)
                toast({
                    type: 'error',
                    icon: 'exclamation triangle',
                    title: 'Error',
                    description: 'Failed to control the device ' + res.name,
                    time: 0
                });
                res.status = p_zone
            } else {
                let interval = null;
                let timerun = 0
                interval = setInterval(() => {
                    timerun = timerun + 3
                    api.controls.eventStatus(result.batchId, result.batchLength).then(result1 => {
                        if (result1.message === "success") {
                            setLoading(false);
                            setSuccess(true)
                            clearInterval(interval);
                            toast({
                                type: 'success',
                                icon: 'check circle',
                                title: 'Success',
                                description: 'Device ' + res.name + ' successfully ' + msg,
                                time: 3000
                            });
                            if (intensity === 0 || value !== 2) {
                                if (value === 1) {
                                    res.status = 'On'
                                    res.mode = 'Manual'
                                } else if (value === 0) {
                                    res.status = 'Off'
                                    res.mode = 'Manual'
                                } else {
                                    res.status = 'Auto'
                                    res.mode = 'Auto'
                                }
                            }
                            else if (intensity === 0 && value === 2) {
                                res.status = 'Off'
                                res.mode = 'Manual'
                                res.Intensity = intensity
                            } else {
                                res.status = 'On'
                                res.mode = 'Manual'
                                res.Intensity = intensity
                            }
                        }
                    })
                    if (timerun >= 40) {
                        clearInterval(interval);
                        setLoading(false);
                        toast({
                            type: 'error',
                            icon: 'exclamation triangle',
                            title: 'Error',
                            description: 'Failed to control the device ' + res.name,
                            time: 0
                        });
                        res.status = p_zone
                    }
                }, 3000);
            }
        })
    }
    // const handleChangeForsetpoint=(event)=>{
    //     set_setpt(event.target.value)
    // }
    // const handleClick = (res,value,type) => {
    //     const user = {
    //         id: '78e8fd9b-4118-4b96-9df6-380d928e2c4a',
    //         name: 'raghunandan'
    //     }
    //     if(res.id!='f94c5f59-b9b4-4cd0-bf1e-513d9a424f63'){
    //         let req=res.master
    //         api.controls.controlSp(req,user,setpt).then(res=>{
    //         })
    //     }
    //     set_setpt("")
    // };
    const options1 = [
        {
            label: "Temperature",
            value: "temperature",
            selectedBackgroundColor: "#3f51b5"
        },
        {
            label: "Humidity",
            value: "humidity",
            selectedBackgroundColor: "#3f51b5"
        },
        {
            label: "Luminousity",
            value: "luminousity",
            selectedBackgroundColor: "#3f51b5"
        },
        {
            label: "Occupancy",
            value: "occupancy",
            selectedBackgroundColor: "#3f51b5",
        }
    ];
    const initialSelectedIndex1 = options1.findIndex(({ value }) => value === "controls");
    const options = [
        {
            label: "o",
            value: "o",
            selectedBackgroundColor: "red",
            selectedFontColor: 'white'
        },
        {
            label: "|",
            value: "|",
            selectedBackgroundColor: "#34c759",
            selectedFontColor: 'white'
        }
    ];

    const onChange = (_flr, newValue, zonetype) => {
        if (newValue === 'o') {
            submit(_flr, 0, 0, zonetype)
        } else {
            submit(_flr, 1, 100, zonetype)
        }
    };
    const initialSelectedIndex = options.findIndex(({ value }) => value === "|");
    const handlesliderChange = () => {
        setIntensity(value)
        // submit(_flr,2,value)
    }

    const iconDevice1 = new Leaflet.Icon({
        iconUrl: require('../../assets/img/sensor-icon.png'),
        iconRetinaUrl: require('../../assets/img/sensor-icon.png'),
        iconSize: new Leaflet.Point(16, 16),
        className: 'leaflet-div-icon-1'
    });
    const onChangetype = newValue => {
        setValue(newValue)
        setHeatMapdata({
            ...heatMapData,
            mapSubType: newValue
        })
        let type = heatMapData.mapSubType === "aqi" ? "all" : heatMapData.mapSubType
        switch (newValue) {
            case "AHU":
                setAhuOpen(true)
                setTempOpen(false)
                setHumOpen(false)
                setLuxOpen(false)
                setIconDevice(iconDevice1)
                break
            case "luminousity":
                setLuxOpen(true)
                setAhuOpen(false)
                setTempOpen(false)
                setHumOpen(false)
                setIconDevice(iconDevice1)
                break
            case "temperature":
                setTempOpen(true)
                setLuxOpen(false)
                setAhuOpen(false)
                setHumOpen(false)
                setIconDevice(iconDevice1)
                break
            case "humidity":
                setHumOpen(true)
                setTempOpen(false)
                setLuxOpen(false)
                setAhuOpen(false)
                setIconDevice(iconDevice1)
                break
            default: break;
        }
        value === 'AHU' ?
            api.floor.devicemap(locationId, newValue.toLocaleUpperCase()).then(res => {
                let zoneData = []
                setHeatMapdata({
                    ...heatMapData,
                    rectData: zoneData,
                    addressPoints: res,
                    mapSubType: type
                })
            })
            :
            api.floor.heatmap(locationId, newValue.toLocaleUpperCase()).then(res => {
                let zoneData = [], devData = [];
                let obj = {}, deviceObj = {};
                res.map(_res => {
                    obj = {}
                    obj.zone_name = _res.name
                    obj.zone_id = _res.id
                    obj.bound = JSON.parse(_res.coordinates)
                    obj.color = _res.color
                    obj.value = _res.value
                    zoneData.push(obj)
                    _res.devices.map(_device => {
                        deviceObj = {}
                        deviceObj.id = _device.ssid
                        deviceObj.name = _device.ss_name
                        deviceObj.type = _device.name
                        deviceObj.value = _device.param_value
                        deviceObj.coordinates = JSON.parse(_device.coordinates)
                        devData.push(deviceObj)
                        return _device
                    })
                    return _res
                })
                setHeatMapdata({
                    ...heatMapData,
                    rectData: zoneData,
                    addressPoints: devData,
                    mapSubType: type
                })
            })
    };
    return (
        <div className={classes.root}>
            <FormControl variant="filled" className={classes.zone}>
                <InputLabel id="demo-simple-select-label">Zone</InputLabel>
                <Select
                    style={{ borderRadius: '0.83vw' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Zone"
                    value={zdata}
                >
                    {zone.map(_item => (
                        <MenuItem key={_item.id} value={_item.name} onClick={() => handlezonechange(_item.name, _item.id)}>{_item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.area}>
                <InputLabel id="demo-simple-select-label">Area</InputLabel>
                <Select
                    style={{ borderRadius: '0.83vw' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Area"
                    value={adata}
                >
                    {areas.map(_item => (
                        <MenuItem key={_item.id} value={_item.name} onClick={() => handleareachange(_item.name, _item.id)}>{_item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div style={{ width: "100%", height: "4vh", marginTop: '1%' }}  >
                <SwitchSelector
                    onChange={onChangetype}
                    options={options1}
                    initialSelectedIndex={initialSelectedIndex1}
                    // border="1px solid #0123B4"
                    backgroundColor={"#e9e5e5"}
                    fontColor={"rgba(0, 0, 0, 0.87)"}
                    wrapperBorderRadius={5}
                    optionBorderRadius={8}
                />
            </div>
            <Grid columns={3}>
                <Grid.Row>
                    <Grid.Column style={{ width: '65%' }}>
                        <Cards className={classes.mapcard}>
                            <Map
                                ref={mapRef}
                                doubleClickZoom={false}
                                zoomControl={false}
                                dragging={false}
                                scrollWheelZoom={false}
                                crs={Leaflet.CRS.Simple}
                                center={[0, 0]}
                                attributionControl={false}
                                // bounds={[[0, 0], [600, 730]]}
                                bounds={[[0, 0], [420, 590]]}
                                className={"floor-map"}
                                onClick={(e) => { console.log({ x: e.latlng.lat, y: e.latlng.lng }) }}
                            >
                                <ImageOverlay
                                    interactive
                                    url={'https://localhost/' + localStorage.floorName + '.jpg'}
                                    // bounds={[[50, 15], [600, 650]]}
                                    // bounds={[[100, -8], [525, 640]]}
                                    bounds={[[0, 0], [420, 590]]}
                                />
                                {heatMapData.addressPoints.map((value) =>
                                    <HeatmapLayer
                                        radius={5}
                                        minOpacity={0.4}
                                        blur={19}
                                        maxZoom={0.5}
                                        points={[value]}
                                        latitudeExtractor={m => m.coordinates[0]}
                                        longitudeExtractor={m => m.coordinates[1]}
                                        intensityExtractor={m => heatMapData.mapSubType !== 'aqi' ? parseFloat(m.value) : ""}
                                    />
                                )}
                                {heatMapData.mapSubType !== 'aqi' &&
                                    heatMapData.addressPoints ? heatMapData.addressPoints.map((value1, index) =>
                                        <Marker key={index}
                                            position={[value1.coordinates[0], value1.coordinates[1]]}
                                            icon={iconDevice}
                                        >
                                            <Tooltip opacity={1} >
                                                {Object.keys(value1).map((key) => (
                                                    key !== "id" && key !== "coordinates" ? <p>{key}:{value1[key]}</p> : <></>
                                                ))}
                                            </Tooltip>
                                        </Marker>
                                    ) : ''}
                                <ZoomControl position="bottomright" />
                            </Map>
                        </Cards>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{ marginTop: '4%' }}>
                            {boolfloor === true && dropfloor.lenght !== 0 ?
                                <Spin spinning={loading} size="default" style={{ justifyContent: 'center', alignContent: 'center', position: 'fixed' }}>
                                    <Card.Group>
                                        {dropfloor.map((_flr) => (
                                            <Card className={classes.maincards}>
                                                <Card.Content>
                                                    <Grid columns={2}>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                {_flr.occupancy !== 0 ?
                                                                    <Card.Header style={{ "fontWeight": "bold" }}>{
                                                                        <SupervisorAccountIcon style={{ "fontSize": "30px" }} />}
                                                                    </Card.Header>
                                                                    : <div></div>
                                                                }
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Card.Header className={classes.zonename}>{_flr.name.charAt(0).toUpperCase() + _flr.name.slice(1)}</Card.Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Buttons
                                                                    variant='contained'
                                                                    className={classes.autobutton}
                                                                    onClick={() => submit(_flr, 4, 0, 'z')}
                                                                >
                                                                    Auto
                                                                </Buttons>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <div className="your-required-wrapper" style={{ width: '5vw', height: '3vh' }}>
                                                                    <SwitchSelector
                                                                        onChange={(newValue) => onChange(_flr, newValue, 'z')}
                                                                        options={options}
                                                                        initialSelectedIndex={initialSelectedIndex}
                                                                        fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                        fontSize={8}
                                                                    />
                                                                </div>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Card.Content>
                                            </Card>
                                        ))}
                                    </Card.Group>
                                </Spin>
                                : boolzone === true ?
                                    <Spin spinning={loading} size="default" style={{ justifyContent: 'center', alignContent: 'center', position: 'fixed' }}>
                                        <Card.Group>
                                            {dropzone.map((_flr) => (
                                                <Card className={classes.maincards}>
                                                    <Card.Content>
                                                        <Grid columns={2}>
                                                            <Grid.Row>
                                                                <Grid.Column>
                                                                    {_flr.occupancy !== 0 ?
                                                                        <Card.Header style={{ "fontWeight": "bold" }}>{
                                                                            <SupervisorAccountIcon style={{ "fontSize": "30px" }} />}
                                                                        </Card.Header>
                                                                        : <div></div>
                                                                    }
                                                                    <Grid.Column>
                                                                        <Card.Header className={classes.areaname}>{_flr.name.charAt(0).toUpperCase() + _flr.name.slice(1)}</Card.Header>
                                                                    </Grid.Column>
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <CircleSlider
                                                                        value={intensity}
                                                                        onChange={handlesliderChange}
                                                                        size={120}
                                                                        knobRadius={12}
                                                                        // progressWidth={5}
                                                                        showTooltip={true}
                                                                        showPercentage={true}
                                                                    // stepSize={1}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row style={{ marginTop: '-5%' }}>
                                                                <Grid.Column>
                                                                    <Buttons
                                                                        variant='contained'
                                                                        className={classes.autobutton}
                                                                        onClick={() => submit(_flr, 4, 0, 'a')}
                                                                    >
                                                                        Auto
                                                                    </Buttons>
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <div className="your-required-wrapper" style={{ width: '5vw', height: '3vh' }}>
                                                                        <SwitchSelector
                                                                            onChange={(newValue) => onChange(_flr, newValue, 'a')}
                                                                            options={options}
                                                                            initialSelectedIndex={initialSelectedIndex}
                                                                            fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                            fontSize={8}
                                                                        />
                                                                    </div>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Card.Content>
                                                </Card>
                                            ))}
                                        </Card.Group>
                                    </Spin>
                                    : boolarea === true ?
                                        <Spin spinning={loading} size="default" style={{ justifyContent: 'center', alignContent: 'center', position: 'fixed' }}>
                                            <Card.Group >
                                                {droparea.map((_flr) => (
                                                    <Card className={classes.maincards}>
                                                        <Card.Content>
                                                            <Grid columns={2}>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <Card.Header className={classes.areaname}>{_flr.name.charAt(0).toUpperCase() + _flr.name.slice(1)}</Card.Header>
                                                                        {_flr.id.slice(0, 3) === "50d" ?
                                                                            <div></div>
                                                                            :
                                                                            <div>
                                                                                {_flr.channel0 !== false && (_flr.channel !== null || _flr.channel2 !== null) ?
                                                                                    <div>
                                                                                        <Radio
                                                                                            style={{ marginRight: "2%" }}
                                                                                            name={'channel1_' + _flr.id}
                                                                                            label="Channel 1"
                                                                                            value={'channel1_' + _flr.id}
                                                                                        />
                                                                                        <Radio
                                                                                            style={{ marginRight: "2%" }}
                                                                                            name={'channel2_' + _flr.id}
                                                                                            label="Channel 2"
                                                                                            value={'channel2_' + _flr.id}
                                                                                        />
                                                                                        <Radio
                                                                                            style={{ marginBottom: "4%", marginTop: "2%" }}
                                                                                            name={'channel0_' + _flr.id}
                                                                                            label="Both Channels"
                                                                                            value={'channel0_' + _flr.id}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <div></div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <CircleSlider
                                                                            value={intensity}
                                                                            onChange={handlesliderChange}
                                                                            size={120}
                                                                            knobRadius={12}
                                                                            // progressWidth={5}
                                                                            showTooltip={true}
                                                                            showPercentage={true}
                                                                        // stepSize={1}
                                                                        />
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row style={{ marginTop: '-5%' }}>
                                                                    <Grid.Column>
                                                                        <Buttons
                                                                            variant='contained'
                                                                            className={classes.autobutton}
                                                                            onClick={() => submit(_flr, 4, 0, 'd')}
                                                                        >
                                                                            Auto
                                                                        </Buttons>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="your-required-wrapper" style={{ width: '5vw', height: '3vh' }}>
                                                                            <SwitchSelector
                                                                                onChange={(newValue) => onChange(_flr, newValue, 'd')}
                                                                                options={options}
                                                                                initialSelectedIndex={initialSelectedIndex}
                                                                                fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                                fontSize={8}
                                                                            />
                                                                        </div>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Card.Content>
                                                    </Card>
                                                ))}
                                            </Card.Group>
                                        </Spin>
                                        :
                                        <div></div>
                            }
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <SemanticToastContainer position='top-center' />
            {loading === false ? null :
                <CircularProgress color="inherit" />
            }
        </div>
    )
}

