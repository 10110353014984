import React, { useEffect, useState,useMemo,useRef,useCallback } from "react";
import { withStyles,makeStyles, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import api from "../../api";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import swal from 'sweetalert';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import TableFooter from '@material-ui/core/TableFooter';


const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "Vgreen",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
const useStyles =makeStyles((theme)=>({
  root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      width: "100%",
    },
    text: {
      marginTop: "10vh",
      marginLeft: "45vh",
      fontWeight: "bold",
      fontFamily: "Arial",
      fontSize: "14px",
      whiteSpace:"nowrap"
  },
  input: {
    textalign: "center",
    fontWeight: "bold",
    marginTop: "8.5vh",
    width: "50vh",
    marginLeft: "9vh",
},
  th:{
      border: "1px solid #dddddd",
    },
    table:{
      minWidth:650,
    }
    
}))

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function Vdevice(){
    const gridRef = useRef();
    const classes = useStyles()
    const [username, setUsername] = React.useState([]);
    const [devicename, setDevicename] = React.useState([]);
    const [devname,setDevName]=React.useState([]);
    const[users,setUsers]=React.useState([]);
    const[data,setData]=React.useState([])
    const[zone_id,setUserid]=React.useState([]);
    const[ss_id,setDeviceid]=React.useState([]);
    const[devices,setDevices]=useState([])
    const[userdevices,setUserdevices]=React.useState([]);
    const[event,setEvent]=React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);


  
    useEffect(() => {
        api.floor.getdevicedata().then(res=>{
            console.log("res========",res)
            setDevicename(res)
            let name=res.map(data=>data.name)
            console.log("name",name)
            setDevName(name)
        })
        api.floor.getuserdata().then(res=>{
          console.log("gtuser",res)
             setUsername(res)
            let username=res.map(data=>data.username)
             setUsers(username)
            let id=res.map(data=>data.id)
            setEvent(id)
        })
        api.floor.userdevices().then(res=>{
          console.log("userdecice",res)
            setDevices(res)
            let userName=res.map(data=>data.userName)
            setUserdevices(userName)
        })
    },[])
    
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChange = (id,name,building_id) => {
        setUsers(name);
        setUserid(building_id)
      };
      const handleChangeForDevicename = (id,name) => {
        setDevName(name);
        setDeviceid(id)
    };
    const handleSubmit  = async (event) => {
        event.preventDefault();
        const payload = {
            zone_id,
            ss_id
        };
        console.log("event",payload)
        api.floor.mapdeviceuser(payload).then(res=>{
          if(res == "User and Device are already mapped"){
            toast({
              type: "error",
              icon: "exclamation triangle",
              title: "Error",
              description: "User and Device are already mapped",
              time: 3000,
              });
        }  else {
              toast({
                description:"User and Device are successfully mapped",
                time: 3000,
                type: "success",
                icon: "check circle",
                title: "Success",
              })
            }
            api.floor.userdevices().then(res=>{
              // console.log("userdecice",res)
                setDevices(res)
            })   
      
          
        })
      };
  
  const handleIconClicks = (id,name) => {
    swal({
        content: {
            element: "input",
            attributes: {
                placeholder: "Please enter the username ",               
            },
        },
    
        buttons: {
            cancel: true,
            confirm: true,
        },
    }).then(function (name) {
      const payload = {
        id,
        name
  }
  console.log("ffffffffffffffffffff",payload)
      api.floor.edituser(payload).then(res=>{
        console.log("userrrr",res)
        if(res == "Username already exits"){
          toast({
            type: "error",
            icon: "exclamation triangle",
            title: "Error",
            description: "User and Device are already mapped",
            time: 3000,
            });
      }  else {
            toast({
              description:"Username updated",
              time: 3000,
              type: "success",
              icon: "check circle",
              title: "Success",
            })
          }

        api.floor.userdevices().then(res=>{
          // console.log("userdecice",res)
            setDevices(res)
        })            
    })
  },
    )}
  

const handleClicks = (id, name, PumpId) => {
  const DeviceName=PumpId
  swal({
    title: 'Enter Details',
    text: 'Username:',
    content: {
      element: 'input',
      attributes: {
        type: 'text',
        id: 'username-input',
        placeholder: 'Please enter the username',
        value: name,
      },
    },
    buttons: {
      cancel: true,
      confirm: true,
    },
  }).then((username) => {
    console.log("username",username)
    if (username === null) {
      console.log('Cancel');
    } else if (username === '' || username) {
      swal({
        title: 'Enter Details',
        text: 'DeviceUID:',
        content: {
          element: 'input',
          attributes: {
            type: 'text',
            id: 'uuid-input',
            placeholder: 'Please enter the DeviceUID',
            maxLength: 30,
            minLength: 6,
            value: DeviceName
          },
        },
        buttons: {
          cancel: true,
          confirm: true,
        },
      }).then((ss_address_value) => {
        const enteredValue = document.getElementById('uuid-input').value;
        console.log("enteredValue",enteredValue.length)
        if (enteredValue.length < 30) {
          swal('Error', 'UUID must be at least 30 characters long', 'error');
        }else if (ss_address_value === null) {
          console.log('Cancel');
        }  else if (ss_address_value === '' || ss_address_value) {
          const payload = {
            id,
            name: username,
            ss_address_value,
          };
          console.log("payload",payload)
          api.floor.editdevice(payload).then(res=>{
            console.log("device",res)
            if(res == "DeviceUID updated"){
              toast({
                description:"DeviceUID updated",
                time: 3000,
                type: "success",
                icon: "check circle",
                title: "Success",
              })
            
          }  else if(res =="DeviceName and DeviceUID updated"){
            toast({
              description:"DeviceName and DeviceUID updated",
              time: 3000,
              type: "success",
              icon: "check circle",
              title: "Success",
            })
          }else if(res == "DeviceName updated"){
            toast({
              description:"DeviceName updated",
              time: 3000,
              type: "success",
              icon: "check circle",
              title: "Success",
            })
          }else {
            toast({
              type: "error",
              icon: "exclamation triangle",
              title: "Error",
              description: "devicename already exists",
              time: 3000,
              });
              }
    
            api.floor.userdevices().then(res=>{
              // console.log("userdecice",res)
                setDevices(res)
            })            
        })
        }
      }).catch(() => {
        console.log('Cancel');
      });
    }
  }).catch(() => {
    console.log('Cancel');
  });
};



    const handledeleteuser=(id,name)=>{
      swal("Are you sure?", {
        text: "Are you sure you want to delete this user?",
        icon: "warning",
        buttons: true,
        dangerMode: false,

      }).then((willDelete)=> {
        if (willDelete) {
          const payload = {
            id,
            // name          
      };
  
          api.floor.deleteuser(payload).then(res=>{
            console.log("deleeuser",res)  
            toast({
              description:"Username successfully deleted",
              time: 3000,
              type: "success",
              icon: "check circle",
              title: "Success",
            })
            api.floor.userdevices().then(res=>{
              console.log("karthik",res)
                setDevices(res)
            })
        })      
        } else {
          console.log("cancel")
        }
      },
      )
      
       
    
      
    
  };
    
    const handledeletedevice=(id,name)=>{
      swal("Are you sure?", {
        text: "Are you sure you want to delete this device?",
        icon: "warning",
        buttons: true,
        dangerMode: true,

      }).then((willDelete) =>{
        if (willDelete) {
          const payload = {
            id,
            // name          
      };
      
          api.floor.deletedevice(payload).then(res=>{
            console.log("deletedevice",res)
            toast({
              description:"Device successfully deleted",
              time: 3000,
              type: "success",
              icon: "check circle",
              title: "Success",
            })  
            api.floor.userdevices().then(res=>{
              console.log("userdecice",res)
                setDevices(res)
            })
        })
        } else {
          console.log("cancel")
        }
       
    },
    )}


   
    return (
        <div className={classes.root} style={{ marginTop: "0%" }}>
            <Grid container spacing={1}>
                    <ValidatorForm
                        style={{ width: "100%", marginTop: "55" }}
                        onSubmit={handleSubmit}
                        instantValidate={true}
                    >
                        <Grid container item xs={12} spacing={1} direction="column">
                            <Grid item xs={2} >
                                <Grid container item xs={12} spacing={1}>
                                <div 
                                    style={{fontWeight: "bold",
                                            fontFamily: "Arial",
                                            fontSize: "20px",
                                            marginLeft:"66vh",whiteSpace:"nowrap"}}>
                                                   
                                </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} >
                    <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <Grid container item xs={12} spacing={0} direction="column">
                            <Grid item xs={4}>
                                <div className={classes.text}>User Name</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.text}>Device Name</div>
                            </Grid>              
                        </Grid> 
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container item xs={12} spacing={0} direction="column">
                     <Grid item xs={3}>
                     <FormControl   variant="outlined" onSubmit = {handleSubmit}  style={{ width: "max-content", minWidth: "260%",marginLeft:"15vh",marginTop:"8vh" }}>
                <InputLabel id="search-select-label">Users</InputLabel>
                <Select
                        
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="user"
                        // value={users}
                        // onChange={handleChange}
                      >
                          {username.map((_item) => (
                            <MenuItem key={_item.id} value={_item.username}  
                            onClick={() => handleChange(_item.id, _item.username,_item.building_id)}
                            >
                              {_item.username}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                            </Grid> 
                            <Grid item xs={3}>
                            <FormControl  variant="outlined"  style={{ width: "max-content", minWidth: "260%",marginLeft:"15vh",marginTop:"4vh" }}>
                <InputLabel id="search-select-label">Devices</InputLabel>
                <Select
                
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="device"
                        // value={devname}
                        // onChange={handlefloorchange}
                      >
                          {devicename.map((_item) => (
                            <MenuItem key={_item.id} value={_item.name}
                            onClick={() => handleChangeForDevicename(_item.id, _item.name)}
                            >
                              {_item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                            </Grid> 
                        </Grid> 
                    </Grid>
                </Grid>
                        </Grid>
                            <Grid item xs={2} >
                                <Grid container item xs={12} spacing={1}>
                                    <Button
                                            type="submit"
                                            fullWidth
                                            //variant="contained"
                                            color="Vgreen"
                                            style={{width:"40vh",marginLeft:"80vh",marginTop:"5vh"}}
                                            >
                                                Submit
                                           
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <TableContainer component={Paper} style={{width:669,height:400,marginLeft:"28vh"}}>
                                  <Table className={classes.table} aria-label="customized table">
                                        <TableHead>
                                              <TableRow>
                                                  <StyledTableCell>User Name</StyledTableCell>
                                                  <StyledTableCell>Device Name</StyledTableCell>
                                              </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {(rowsPerPage > 0
            ? devices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : devices
          ).map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row">
                                                    {((row.userName )==null)?
                                                     <>-</>:
                                                     <>
                                                      {row.userName}
                                                      {/* <IconButton
                                                          aria-label="edit"
                                                          style={{ paddingLeft: 0 }}
                                                          onClick={() => handleIconClicks
                                                          (row.userId, row.userName)}>
                                                          <EditIcon />
                                                      </IconButton>
                                                      <IconButton
                                                          aria-label="delete"
                                                          style={{ paddingLeft: 0 }}
                                                          onClick={() => handledeleteuser
                                                          (row.userId, row.userName)}>
                                                          <DeleteIcon />
                                                      </IconButton> */}
                                                      </>
                                                     } 
                                                    </StyledTableCell>
                                                 
                                                   <StyledTableCell component="th" scope="row">
                                                   {((row.deviceName )==null)?
                                                     <>-</>:
                                                     <>
                                                      {row.deviceName}
                                                      <IconButton
                                                          aria-label="edit"
                                                          style={{ paddingLeft: 0 }}
                                                          onClick={() => handleClicks
                                                          (row.deviceId, row.deviceName, row.PumpId)}>
                                                          <EditIcon />
                                                      </IconButton>
                                                      <IconButton
                                                          aria-label="delete"
                                                          style={{ paddingLeft: 0 }}
                                                          onClick={() => handledeletedevice
                                                          (row.deviceId, row.deviceName)}>
                                                          <DeleteIcon />
                                                      </IconButton>
                                                      </>
}
                                                    </StyledTableCell>
                                          </StyledTableRow>
                                          ))}
                                        </TableBody>
                                      
                                        <TablePagination
              rowsPerPageOptions={[]}
              colSpan={3}
              count={devices.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                
                native: true,
              }}
              onChangePage={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
        
                                  </Table>
                            </TableContainer>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
            </Grid> 
            <SemanticToastContainer position="top-center" />   
        </div>
    )
                          }   