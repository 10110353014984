import React, { useState } from 'react';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TextField, InputAdornment } from "@material-ui/core";
import api from "../../api";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles =makeStyles((theme)=>({
    root: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
        width: "100%",
      },
    text: {
        marginTop: "10vh",
        marginLeft: "34vh",
        fontWeight: "bold",
        fontFamily: "Arial",
        fontSize: "14px",
        width:"42vh"
    },
    input: {
        textalign: "center",
        fontWeight: "bold",
        marginTop: "8.5vh",
        width: "50vh",
        marginLeft: "6vh",
    },
    err: {
        margin: "4px 0 0 0",
        fontSize: "13px",
        color: "#e64646",
        marginLeft:"12vh"
    }
}))

function AddUser(){
    const classes = useStyles()

    const [name, setUsername] = React.useState("");
    const [secret, setSecret] = React.useState("");
    const [city, setLocname] = React.useState("");
    const [customer_state, setcustomer_state] = React.useState("");
    const [farm_size_in_hector, setFarm] = React.useState("");
    const [number_of_panels_in_array, setPanels] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [confirm_password, setConfirmPassword] = React.useState("");
    const [msg, setMsg] = React.useState("");
    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = useState('');
    const [error1, setError1] = useState('');



    const handleChangeForUsername = (event) => {
        setUsername(event.target.value);
    };

    const handleChangeForSecret =(event) =>{
        setSecret(event.target.value);
    }

    const handleChangeForLocname = (event) => {
        setLocname(event.target.value);
    };
    const handleChangeForState = (event) => {
        setcustomer_state(event.target.value);
    };
    const handleChangeForFarmSize = (event) => {
        const { value } = event.target;
        if (/^\d*$/.test(value)) {
            setFarm(value);
            setError('');
          } else {
            setError('Only numbers are allowed');
          }
    };
    const handleChangeForPanels = (event) => {
        const { value } = event.target;
        if (/^\d*$/.test(value)) {
            setPanels(value);
            setError1('');
          } else {
            setError1('Only numbers are allowed');
          }
       
    };
      const onInputChange = (event) => {
        setPassword(event.target.value);
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,15}$/;
        const isValidPassword = pattern.test(event.target.value);
        setIsValid(isValidPassword);
        if(event.target.value != confirm_password ){
            const msg="Password and Confirm Password doesn't match"
            setMsg(msg) 
        }else{
            const msg=" "
            setMsg(msg)
        }

      }

      const handleClickShowPassword = () => setShowPassword((show) => !show);
      const handleClickShowPassword1 = () => setShowPassword1((show) => !show);


      const onInputChange1 = (event) => {
        setConfirmPassword(event.target.value);
        if(event.target.value != password ){
            const msg="Password and Confirm Password doesn't match"
            setMsg(msg) 
        }else{
            const msg=" "
            setMsg(msg)         
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      const handleMouseDownPassword1 = (event) => {
          event.preventDefault();
        };
      const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            name,
            password,
            confirm_password,
            city,
            secret,
            customer_state,
            farm_size_in_hector,
            number_of_panels_in_array
        };
        api.users.addUser(payload).then((res)=>{
            console.log("res",res)
            if(res == 'ACCEPTED'){
                 toast({
                    type: "success",
                    icon: "check circle",
                    title: "Success",
                    description: "User added successfully ",
                    time: 3000,
                  });
            }else{
                toast({
                    type: "error",
                    icon: "exclamation triangle",
                    title: "Error",
                    description: "User already exits",
                    time: 3000,
                  });
            }
        })
    }

    return(
        <div className={classes.root} style={{ marginTop: "0%" }}>
            <Grid container spacing={1}>
                <ValidatorForm
                    style={{ width: "100%", marginTop: "55" }}
                    onSubmit={handleSubmit}
                    instantValidate={true}
                >
                    <Grid container item xs={12} spacing={1} direction="column">
                    <Grid item xs={2} >
                        <Grid container item xs={12} spacing={1}>
                            <div 
                                style={{fontWeight: "bold",
                                        fontFamily: "Arial",
                                        fontSize: "20px",
                                        marginLeft:"66vh",whiteSpace:"nowrap"}}>
                                     ADD USER
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} >
                    <Grid container item xs={12} spacing={1}>
                    <Grid item xs={6}>
                        <Grid container item xs={12} spacing={2} direction="column">
                            <Grid item xs={4}>
                                <div className={classes.text}>Customer Name</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.text}>Password</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.text}>Confirm Password</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.text}>Secret</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.text}>Customer City</div>
                            </Grid>     
                            <Grid item xs={4}>
                                <div className={classes.text}>Customer State</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.text}>Farm Size (in hector)</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.text}>Number Of Panels In array</div>
                            </Grid>                      
                        </Grid> 
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container item xs={12} spacing={0} direction="column">
                            <Grid item xs={3}>
                            <TextField
                                required
                                size="small"
                                margin="normal"
                                // fullWidth
                                // placeholder="Device name"
                                label="Coustomer Name"
                                textalign={"center"}
                                name="Coustomer Name"
                                value={name}
                                onChange={handleChangeForUsername}
                                // autoComplete="email"
                                autoFocus
                                className={classes.input}
                                variant="outlined"
                                validators={["required"]}
                               
                            />
                            </Grid>
                            <Grid item xs={3}>
                            <TextField
                                                required
                                                size="small"
                                                margin="normal"
                                                // fullWidth
                                                // placeholder="Device name"
                                                label="Enter Password"
                                                textalign={"center"}
                                                name="Enter Password"
                                                value={password}
                                                onChange={onInputChange}
                                                // autoComplete="email"
                                                type={showPassword ? 'text' : 'password'}
                                                className={classes.input}
                                                inputProps={{ maxLength: 15 }}
                                                variant="outlined"
                                                validators={["required"]}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                           <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                            />
                              {password && !isValid && (
                                <div style={{ color: "red" ,width:"150vh"}}>
                                    Password must contain at least 8 characters including one uppercase letter, one lowercase letter, and one digit.
                                 </div>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                            <TextField
                                                required
                                                size="small"
                                                margin="normal"
                                                // fullWidth
                                                // placeholder="Device name"
                                                label="Confirm Password"
                                                textalign={"center"}
                                                name="Confirm Password"
                                                value={confirm_password}
                                                inputProps={{ maxLength: 15 }}
                                                onChange={onInputChange1}
                                                type={showPassword1 ? 'text' : 'password'}
                                                // autoComplete="email"
                                                className={classes.input}
                                                variant="outlined"
                                                validators={["required"]}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                           <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword1}
                                                                onMouseDown={handleMouseDownPassword1}
                                                                edge="end"
                                                                >
                                                                {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                 />                
                                              <span className='err' style={{whiteSpace:"nowrap",color:"red",marginLeft:"9vh"}}>{msg}</span>
                            </Grid>
                            <Grid item xs={3}>
                            <TextField
                                                required
                                                size="small"
                                                margin="normal"
                                                // fullWidth
                                                // placeholder="Device name"
                                                label="Enter your Birth Place"
                                                textalign={"center"}
                                                value={secret}
                                                onChange={handleChangeForSecret}
                                                // autoComplete="email"
                                                className={classes.input}
                                                variant="outlined"
                                                validators={["required"]}
                            />
                            </Grid>
                            <Grid item xs={3}>
                            <TextField
                                                required
                                                size="small"
                                                margin="normal"
                                                // fullWidth
                                                // placeholder="Device name"
                                                label="Customer City"
                                                textalign={"center"}
                                                name="Customer City"
                                                value={city}
                                                onChange={handleChangeForLocname}
                                                // autoComplete="email"
                                                className={classes.input}
                                                variant="outlined"
                                                validators={["required"]}
                            />
                            </Grid>
                            <Grid item xs={3}>
                            <TextField
                                                required
                                                size="small"
                                                margin="normal"
                                                // fullWidth
                                                // placeholder="Device name"
                                                label="Customer State"
                                                textalign={"center"}
                                                name="Customer State"
                                                value={customer_state}
                                                onChange={handleChangeForState}
                                                // autoComplete="email"
                                                className={classes.input}
                                                variant="outlined"
                                                validators={["required"]}
                            />
                            </Grid>
                            <Grid item xs={3}>
                            <TextField
                                                required
                                                size="small"
                                                margin="normal"
                                                // fullWidth
                                                // placeholder="Device name"
                                                label="Farm Size"
                                                textalign={"center"}
                                                name="Farm Size"
                                                value={farm_size_in_hector}
                                                onChange={handleChangeForFarmSize}
                                                error={!!error}
                                                helperText={error}
                                                // autoComplete="email"
                                                className={classes.input}
                                                variant="outlined"
                                                validators={["required"]}
                            />
                            </Grid>
                            <Grid item xs={3}>
                            <TextField
                                                required
                                                size="small"
                                                margin="normal"
                                                // fullWidth
                                                // placeholder="Device name"
                                                label="Panels"
                                                textalign={"center"}
                                                name="Panels"
                                                value={number_of_panels_in_array}
                                                onChange={handleChangeForPanels}
                                                error={!!error1}
                                                helperText={error1}
                                                // autoComplete="email"
                                                className={classes.input}
                                                variant="outlined"
                                                validators={["required"]}
                                               
                            />
                            </Grid>
                        </Grid> 
                    </Grid>
                </Grid>
                    </Grid>
                    <Grid item xs={2} >
                        <Grid container item xs={12} spacing={1}>
                            <Button
                                type="submit"
                                fullWidth
                                color="Vgreen"
                                style={{width:"40vh",marginLeft:"66vh",marginTop:"5vh"}}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
            <SemanticToastContainer position="top-center" />
        </div>
    )
}

export default AddUser