const initialState = {
    alarmData: {}
}


const alarmReducer = ( state = initialState, action) => {
    switch(action.type) {
        case "alarm": 
           // console.log("dfjhbsfkjhbkljbks",action)
            return {...state, alarmData: action.payload};
        default: 
            return state;
    }
}

export default  alarmReducer;