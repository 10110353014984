import React, { useEffect, useState } from 'react'
import api from '../../api'
import Success from 'components/Typography/Success';
import TimeSeries from "../TimeSeries.js";
import TimeSeries1 from "../TimeS";
import Devicetrend from 'views/Devicetrend';
import { Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%"

  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    autosize: true,
    clearable: false
  }
}))

const getMyValue = (input, params=[]) => {
	let test = -1;
	try{
		if (input !== undefined)
			test = input[params[0]];
		else
			return;
		if (test !== undefined){
			for(let i=1; i<params.length; i++){
				if (params[i] !== undefined)
					test = test[params[i]]
				else
					break;
			}
		}
	} catch (e) {
		console.log('My Error in getMyValue: ', e.message);
	}
	return test;
}

function GlEnergyMeter(props) {
  let myEMS='a14c6706-37fe-11ed-80b8-9829a659bba7';
  const classes = useStyles();
  const [present, setPresent] = useState('');
  const [apparent, setApparent] = useState('');
  const [active, setActive] = useState('');
  const [power, setPower] = useState('');
  const [voltage_ll, setVoltageLL] = useState('');
  const [voltage_ln, setVoltageLN] = useState('');
  const [current, setCurrent] = useState('');
  const [freq, setFreq] = useState('');
  const [energy, setEnergy] = useState({});
  const [lnphase1, setLNPhase1] = useState([]);
  const [lnphase2, setLNPhase2] = useState([]);
  const [lnphase3, setLNPhase3] = useState([]);
  const [llphase1, setLLPhase1] = useState([]);
  const [llphase2, setLLPhase2] = useState([]);
  const [llphase3, setLLPhase3] = useState([]);
  const [currentphase1, setCurrentPhase1] = useState([]);
  const [currentphase2, setCurrentPhase2] = useState([]);
  const [currentphase3, setCurrentPhase3] = useState([]);
  const [energy24hrs, setEnergy24hrs] = useState({});
  const [energy7days, setEnergy7days] = useState({});
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const [floor, setFloor] = useState([]);
  // const [deviceid, setDeviceid] = useState(initialState1);
  const [data, setData] = useState(initialState);
  const [ahu1, setAhu1] = useState({});
  const [graph, setGraph] = useState([]);
  const [activeEMS, setActiveEMS] = useState('a14c6706-37fe-11ed-80b8-9829a659bba7');
  const [energydevice, setEnergydevice] = useState([]);
  const zone_data = useSelector((state) => state.inDashboard.locationData);
  const initialState1 =
  props.location.state != null
    ? props.location.state.data
    : "01f8d696-5abc-4ba1-a3be-415bedaed456";
    const initialState =
    props.location.state != null ? props.location.state.name : "EMS";
    const buildingID = useSelector((state) => state.isLogged.data.building.id);

    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });

  const handlefloorchange = (name, id) => {
    setFdata(name);
    api.floor.devicemap(id, "energyMeter")
    .then((res) => {
      setEnergydevice(res);
    })
  };
  const handleChange = (name, id) => {
    setActiveEMS(id);
    // console.log("activeEMS in Handlechange",activeEMS)
    api.floor.EmsData(id)
    .then(res => {
      res.map(ele => {
        if (ele.param_id === 'presentDemand') {
          setPresent(ele.param_value)
        }
        else if (ele.param_id === 'apparentPowerTotal') {
          setApparent(ele.param_value)
        }
        else if (ele.param_id === 'activePowerTotal') {
          setActive(ele.param_value)
        }
        else if (ele.param_id === 'powerFactorTotal') {
          setPower(ele.param_value)
        }
        else if (ele.param_id === 'volatage_LL_average') {
          setVoltageLL(ele.param_value)
        }
        else if (ele.param_id === 'volatage_LN_average') {
          setVoltageLN(ele.param_value)
        }
        else if (ele.param_id === 'currentAverage') {
          setCurrent(ele.param_value)
        }
        else if (ele.param_id === 'Frequency') {
          setFreq(ele.param_value)
        }
      })
    })
    // .catch((error)=>{
    //     setPresent('')
    //     setApparent('')
    //     setActive('')
    //     setPower('')
    //     setVoltageLL('')
    //     setVoltageLN('')
    //     setCurrent('')
    //     setFreq('')

    // })
    
    setData(name);
  };

  useEffect(() => {
    let zone_id=''
    zone_data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
    zone_data.filter((_each)=>_each.zone_type==='GL_LOCATION_TYPE_FLOOR')
    if(fdata!== null){
      zone_data.filter((_each,i) =>{
        if(_each.zone_type==='GL_LOCATION_TYPE_FLOOR'&& _each.name===fdata){
           return zone_id=_each.uuid
        }
      })
    } else {
      zone_id=zone_data[0].uuid
      setFdata(zone_data[0].name)
    }

    api.floor.devicemap(zone_id, "energyMeter")
      .then((res) => {
        console.log("activeEMS",activeEMS)
      })

    api.floor.EmsGraphData(activeEMS).then(res => {
      if(res.graphData.length){
        if(res.graphData[0]['em_powerFactorTotal']){
            setEnergy(getMyValue(res,['graphData',0,'em_powerFactorTotal']))
        }
        if(res.graphData[0]['em_volatge_LN_phase_1-2']){
            setLNPhase1(getMyValue(res,['graphData',0,'em_volatge_LN_phase_1-2']))  
        }
        if(res.graphData[0]['em_volatge_LN_phase_2-3']){
            setLNPhase2(getMyValue(res,['graphData',0,'em_volatge_LN_phase_2-3']))
        }
        if(res.graphData[0]['em_volatge_LN_phase_3-1']){
            setLNPhase3(getMyValue(res,['graphData',0,'em_volatge_LN_phase_3-1']))  
        }
        if(res.graphData[0]['em_volatge_LL_phase_1-2']){
            setLLPhase1(getMyValue(res,['graphData',0,'em_volatge_LL_phase_1-2']))
        }
        if(res.graphData[0]['em_volatge_LL_phase_2-3']){
            setLLPhase2(getMyValue(res,['graphData',0,'em_volatge_LL_phase_2-3']))
        }
        if(res.graphData[0]['em_volatge_LL_phase_3-1']){
            setLLPhase3(getMyValue(res,['graphData',0,'em_volatge_LL_phase_3-1']))
        }
        if(res.graphData[0]['em_currentPhase1']){
            setCurrentPhase1(getMyValue(res,['graphData',0,'em_currentPhase1']))
        }
        if(res.graphData[0]['em_currentPhase2']){
            setCurrentPhase2(getMyValue(res,['graphData',0,'em_currentPhase2']))
        }
        if(res.graphData[0]['em_currentPhase3']){
            setCurrentPhase3(getMyValue(res,['graphData',0,'em_currentPhase3'])) 
        }
      }
    }).catch((error)=>{
        setEnergy([])
        setLNPhase1([])
        setLNPhase2([])
        setLNPhase3([])
        setLLPhase1([])
        setLLPhase2([])
        setLLPhase3([])
        setCurrentPhase1([])
        setCurrentPhase2([])
        setCurrentPhase3([])
    })

    api.floor.EmsGraph24hrs(activeEMS, "2022-10-13").then(res => {
      if(res.graphData.length){
        setEnergy24hrs(res.graphData[0])
      }
    }).catch((error)=>{
        setEnergy24hrs({})

    })

    api.floor.EmsGraph7days(activeEMS, "2022-10-13").then(res => {
      if(res.graphData.length){
        setEnergy7days(res.graphData[0])
      }
      
    }).catch((error)=>{
        setEnergy7days({})
    })

    api.dashboard.getMetricData(buildingID).then((res) => {
      res.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
      setFloor(res);
    });


    const timer = setInterval(() => {
      api.floor.EmsGraphData(activeEMS).then(res => {
        if(res.graphData.length){
        if(res.graphData[0]['em_powerFactorTotal']){
            setEnergy(getMyValue(res,['graphData',0,'em_powerFactorTotal']))
        }
        if(res.graphData[0]['em_volatge_LN_phase_1-2']){
            setLNPhase1(getMyValue(res,['graphData',0,'em_volatge_LN_phase_1-2']))  
        }
        if(res.graphData[0]['em_volatge_LN_phase_2-3']){
            setLNPhase2(getMyValue(res,['graphData',0,'em_volatge_LN_phase_2-3']))
        }
        if(res.graphData[0]['em_volatge_LN_phase_3-1']){
            setLNPhase3(getMyValue(res,['graphData',0,'em_volatge_LN_phase_3-1']))  
        }
        if(res.graphData[0]['em_volatge_LL_phase_1-2']){
            setLLPhase1(getMyValue(res,['graphData',0,'em_volatge_LL_phase_1-2']))
        }
        if(res.graphData[0]['em_volatge_LL_phase_2-3']){
            setLLPhase2(getMyValue(res,['graphData',0,'em_volatge_LL_phase_2-3']))
        }
        if(res.graphData[0]['em_volatge_LL_phase_3-1']){
            setLLPhase3(getMyValue(res,['graphData',0,'em_volatge_LL_phase_3-1']))
        }
        if(res.graphData[0]['em_currentPhase1']){
            setCurrentPhase1(getMyValue(res,['graphData',0,'em_currentPhase1']))
        }
        if(res.graphData[0]['em_currentPhase2']){
            setCurrentPhase2(getMyValue(res,['graphData',0,'em_currentPhase2']))
        }
        if(res.graphData[0]['em_currentPhase3']){
            setCurrentPhase3(getMyValue(res,['graphData',0,'em_currentPhase3'])) 
        }
        }
      }).catch((error)=>{
        setEnergy([])
        setLNPhase1([])
        setLNPhase2([])
        setLNPhase3([])
        setLLPhase1([])
        setLLPhase2([])
        setLLPhase3([])
        setCurrentPhase1([])
        setCurrentPhase2([])
        setCurrentPhase3([])
    })

      api.floor.EmsGraph24hrs(activeEMS, "2022-10-13").then(res => {
        if(res.graphData.length){
          setEnergy24hrs(res.graphData[0])
        }
      }).catch((error)=>{
        setEnergy24hrs({})
    })

      api.floor.EmsGraph7days(activeEMS, "2022-10-13").then(res => {
        if(res.graphData.length){
          setEnergy7days(res.graphData[0])
        }
      }).catch((error)=>{
        setEnergy7days({})
    })
    }, 5000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, [activeEMS]);

  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Grid container xs={12} spacing={2}>
      <Grid item xs={5}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "max-content", minWidth: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Floor
              </InputLabel>
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Floor"
                value={fdata}
                // onChange={handlefloorchange}
              >
                {floor.map((_item) => (
                  <MenuItem
                    key={_item.id}
                    value={_item.name}
                    onClick={() => handlefloorchange(_item.name, _item.id)}
                  >
                    {_item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "max-content", minWidth: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Energy Meter
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Energy meter"
                value={data}
              >
                {energydevice.map((_item) => (
                  <MenuItem
                    key={_item.ssid}
                    value={_item.name}
                    onClick={() => handleChange(_item.name, _item.ssid)}
                  >
                    {_item.name}
                  </MenuItem>
                ))}
              
              </Select>
            </FormControl>
          </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <Card className={classes.paper} style={{ width: "100%", height: "14vh" }}>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>

                        <Grid container xs={12} direction='column' style={{

                          "justify-content": "center",

                          "align-content": "center"

                        }} >

                          <Grid item xs={12}>

                            <Grid container item xs={12}>

                              <Grid item xs={12} style={{ marginTop: "2vh" }}>Kilo Watt</Grid>

                            </Grid>

                          </Grid>

                          <Grid item xs={12}>

                            <Grid container item xs={12}>

                              <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>

                                <Success>{formatter.format(present)} </Success>
                                {/* {Math.round(present)} */}

                              </Grid>

                            </Grid>

                          </Grid>

                        </Grid>

                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                        <Grid container xs={12} direction='column' style={{
                          "justify-content": "center",
                          "align-content": "center"
                        }} >
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "2vh" }}>Apparent Power</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                <Success>{formatter.format(apparent)}</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                        <Grid container xs={12} direction='column' style={{
                          "justify-content": "center",
                          "align-content": "center"
                        }} >
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "2vh" }}>Active Power</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                <Success>{formatter.format(active)}</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                        <Grid container xs={12} direction='column' style={{
                          "justify-content": "center",
                          "align-content": "center"
                        }} >
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "2vh" }}>Average PF</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                <Success>{formatter.format(power)}</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                        <Grid container xs={12} direction='column' style={{
                          "justify-content": "center",
                          "align-content": "center"
                        }} >
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "2vh" }}>L-L Voltage[Avg]</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                <Success>{formatter.format(voltage_ll)}</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                        <Grid container xs={12} direction='column' style={{
                          "justify-content": "center",
                          "align-content": "center"
                        }} >
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "2vh" }}>L-N Voltage[Avg]</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                <Success>{formatter.format(voltage_ln)}</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                        <Grid container xs={12} direction='column' style={{
                          "justify-content": "center",
                          "align-content": "center"
                        }}>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "2vh" }}>Current</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                <Success>{formatter.format(current)}</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                        <Grid container xs={12} direction='column' style={{
                          "justify-content": "center",
                          "align-content": "center"
                        }}>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "2vh" }}>Frequency</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                <Success>{formatter.format(freq)}</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Card className={classes.paper} style={{ height: "34vh", backgroundColor: 'white', borderRadius: '14px' }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>24 Hour Consumption</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                    {Object.keys(energy24hrs).length==0 ? <>
                        <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4>
                        </>
                        :
                        <>
                          {Object.keys(energy24hrs).map((key) => (
                            <Devicetrend data={energy24hrs[key]} param={key} />
                      ))}
                        </>}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.paper} style={{ height: "34vh", backgroundColor: 'white', borderRadius: '14px' }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>L-L Voltage</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                    {Object.keys(llphase1).length==0 ? <>
                        <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4>
                        </>
                        :
                        <>    
                          <TimeSeries1 data={llphase1} data2={llphase2} data3={llphase3} />
                        </>}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.paper} style={{ height: "34vh", backgroundColor: 'white', borderRadius: '14px' }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>L-N Voltage</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                    {Object.keys(lnphase1).length==0 ? <>
                        <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4>
                        </>
                        :
                        <>    
                        <TimeSeries1 data={lnphase1} data2={lnphase2} data3={lnphase3}/>
                        </>}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Card className={classes.paper} style={{ height: "34vh", backgroundColor: 'white', borderRadius: '14px' }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>Current</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                    {Object.keys(currentphase1).length==0 ? <>
                        <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4>
                        </>
                        :
                        <>
                        <TimeSeries1 data={currentphase1} data2={currentphase2} data3={currentphase3}/>    
                        </>}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={4}>
                 <Card className={classes.paper} style={{ height: "34vh", backgroundColor: 'white', borderRadius: '14px' }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>Power Factor</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                    {Object.keys(energy).length==0 ? <>
                        <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4>
                        </>
                        :
                        <>    
                          <TimeSeries data={energy} param="em_powerFactorTotal" />
                        </>}
                    </Grid>
                  </Grid>
                </Card> 
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.paper} style={{ height: "34vh", backgroundColor: 'white', borderRadius: '14px' }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>7 Days Consumption</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                     {Object.keys(energy7days).length==0 ? <>
                        <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4>
                        </>
                        :
                        <>
                          {Object.keys(energy7days).map((key) => (
                        <Devicetrend data={energy7days[key]} param={key} />
                      ))}
                        </>}

                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default GlEnergyMeter

