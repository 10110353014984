import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import {  makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Card,
  ButtonBase,
} from "@material-ui/core";
import api from "../../api";
import SwitchSelector from "react-switch-selector";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import NotificationLow from "assets/img/Notification";
import ReactSimpleRange from "react-simple-range";
import {
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    autosize: true,
    clearable: false,
  }, CardbodyInsideGrid: {
    "justify-content": "center",
    display: "inline-flex",
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
  },
}));
function GlVav() {
  const classes = useStyles();
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const [ahudevice, setAhudevice] = useState([]);
  const [data, setData] = useState("AHU");

  const options = [
    {
      label: "Card View",
      value: "cardview",
      selectedBackgroundColor: "#0123b4",
    },
    {
      label: "Table View",
      value: "tableview",
      selectedBackgroundColor: "#0123b4",
    },
  ];
  const options1 = [
    {
      label: "Auto",
      value: "auto",
      selectedBackgroundColor: "green",
      fontSize:"12"
    },
    {
      label: "Manual",
      value: "manual",
      selectedBackgroundColor: "green",
      fontSize:"12"

    },
  ];
  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === "hvac"
  );
  const initialSelectedIndex1 = options1.findIndex(
    ({ value }) => value === "manual"
  );

  // const onClickIssue = () => {};
  useEffect(() => {
      api.dashboard.getMetricData(buildingID).then((res) => {
            setFloor(res);
            console.log("res", res);
      api.floor
          .devicemap("0011fc20-64b0-4dac-b3d6-317aa8b2b6f2", "AHU")
          .then((res) => {
            setAhudevice(res);
          });
      });
  }, [buildingID]);
  // const handlefloorchange = (name, id) => {
  //     setFdata(name);
  //     api.floor.devicemap(id, "AHU").then((res) => {
  //     setAhudevice(res);
  //   });
  // };
  const handleChange = (name, id) => {
    setData(name);
  };
  const onChange = (newValue) => {
    // console.log("switched to tableview", newValue);
  };
  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
        <Grid container spacing={2}>
            <Grid container item spacing={1}>
              <Grid item xs={5}>
                  <FormControl variant="outlined" style={{ width: "max-content", minWidth: "100%" }}>
                      <InputLabel id="demo-simple-select-outlined-label">
                          Floor
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Floor"
                        value={fdata}
                        // onChange={handlefloorchange}
                      >
                          {floor.map((_item) => (
                            <MenuItem key={_item.name} value={_item.name}>
                              {_item.name}
                            </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={3}>
                  <FormControl variant="outlined" className={classes.formControl} style={{ width: "max-content", minWidth: "100%" }}>
                      <InputLabel id="demo-simple-select-outlined-label">
                               Device
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Device"
                        value={data}
                      >
                              {ahudevice.map((_item) => (
                                <MenuItem
                                  key={_item.ssid}
                                  value={_item.name}
                                  onClick={() => handleChange(_item.name, _item.ssid)}
                                >
                                  {_item.name}
                                </MenuItem>
                              ))}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={3}>
                  <SwitchSelector
                    style={{ borderRadius: "12px" }}
                    onChange={onChange}
                    options={options}
                    initialSelectedIndex={initialSelectedIndex}
                    // border="1px solid #0123B4"
                    backgroundColor={"#e9e5e5"}
                    fontColor={"rgba(0, 0, 0, 0.87)"}
                    // wrapperBorderRadius={true}
                    //optionBorderRadius={true}
                  />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card className={classes.paper} style={{height:"40vh",backgroundColor:"#ffffff",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                        <Grid container xs={12} spacing={1}>
                          <Grid item xs={6}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} style={{fontSize:"3vh",color:"black"}}>Software</Grid>
                              <Grid item xs={6}  style={{fontSize:"2vh",textDecorationLine:"underline",color:"#0123b4"}}>3F-PH1-VAV1</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container xs={12} >
                                <Grid item xs={12}>
                                    <ButtonBase style={{width:"100%",alignItems:"flex-end"}}  >
                                            <Card style={{height:"35px",marginLeft:"27vh",marginTop:"1vh"}}>
                                              <Grid container xs={12}>
                                                <Grid xs={6}>
                                                    <NotificationLow />
                                                  </Grid>
                                                </Grid>    
                                              </Card>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1}>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                        <Grid container xs={12} spacing={1} direction="column" style={{justifyContent:"right",alignItems:"center"}}>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                              Temperature Set Point                          
                                                        </Grid>
                                              </Grid>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{whiteSpace:"nowrap",fontSize:"3vh",color:"#0123b4",fontWeight:"bold"}}>
                                                            23°C                    
                                                        </Grid>
                                              </Grid>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                              <div style={{width:'100px',marginTop:"-2vh"}}>
                                                                <ReactSimpleRange 
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                                trackColor='blue'
                                                                thumbColor="blue"
                                                                label={true}
                                                                eventWrapperPadding={8}/>
                                                              </div>               
                                                        </Grid>
                                              </Grid>
                                        </Grid>
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                    <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",alignContent:"center",alignItems:"center"}}>
                                          <Grid container xs={12} spacing={1}>                       
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                          Damper Control                          
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                            <div style={{ width: "19vh", height: "3vh"}}>
                                                                <SwitchSelector
                                                                    onChange={onChange}
                                                                    options={options1}
                                                                    initialSelectedIndex={initialSelectedIndex1}
                                                                    // border="1px solid #0123B4"
                                                                    backgroundColor={"#e9e5e5"}
                                                                    fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                  // wrapperBorderRadius={true}
                                                                  //optionBorderRadius={true}
                                                                />
                                                            </div>                         
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                            <div style={{color:"#0123b4",fontWeight:"bold"}}>50%</div>                      
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                            <div style={{width:'100px',marginTop:"-4px",marginLeft:"26px"}}>
                                                                  <ReactSimpleRange 
                                                                  min={0}
                                                                  max={100}
                                                                  step={1}
                                                                  trackColor='blue'
                                                                  thumbColor="blue"
                                                                  label={true}
                                                                  eventWrapperPadding={8}
                                                                  />
                                                            </div>                          
                                                      </Grid>
                                                </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} direction="column" spacing={1} style={{alignContent:"center",alignItems:"center"}}>
                                          <Grid item xs={2} style={{whiteSpace:"nowrap",color:"black",marginLeft:"-6vh"}}>
                                            Design CFM
                                          </Grid> 
                                          <Grid item xs={10}>
                                              <Grid container xs={12} direction="column" spacing={1} style={{color:"#0123b4",whiteSpace:"nowrap",fontSize:"2vh",fontWeight:"bold"}}>
                                                  <Grid item xs={6}>250</Grid>
                                                  <Grid item xs={6}>230</Grid>
                                              </Grid>
                                          </Grid> 
                                      </Grid>
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",backgroundColor:"#fed0c1",color:"#fc6434"}}>
                                    <div style={{fontSize:"5vh",marginTop:"2vh",fontWeight:"bold"}}>
                                        26°C
                                    </div>
                                </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} spacing={1} direction="column" >                        
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                    <div style={{marginTop:"-2vh"}}>
                                                      <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50} showPercentValue />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                     Damper Position      
                                                </Grid>
                                            </Grid>
                                      </Grid>            
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} spacing={1} direction="column">                       
                                          <Grid container item xs={12}>
                                              <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                <div style={{marginTop:"-2vh"}}>
                                                    <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={25} showPercentValue />
                                                </div>
                                              </Grid>
                                          </Grid>
                                          <Grid container item xs={12}>
                                              <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                    CFM
                                              </Grid>
                                          </Grid>
                                      </Grid>     
                                  </Card>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.paper} style={{height:"40vh",backgroundColor:"#ffffff",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                        <Grid container xs={12} spacing={1}>
                          <Grid item xs={6}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} style={{fontSize:"3vh",color:"black"}}>Software</Grid>
                              <Grid item xs={6}  style={{fontSize:"2vh",textDecorationLine:"underline",color:"#0123b4"}}>3F-PH1-VAV1</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container xs={12} >
                                <Grid item xs={12}>
                                    <ButtonBase style={{width:"100%",alignItems:"flex-end"}}  >
                                            <Card style={{height:"35px",marginLeft:"27vh",marginTop:"1vh"}}>
                                              <Grid container xs={12}>
                                                <Grid xs={6}>
                                                    <NotificationLow />
                                                  </Grid>
                                                </Grid>    
                                              </Card>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1}>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                        <Grid container xs={12} spacing={1} direction="column" style={{justifyContent:"right",alignItems:"center"}}>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                              Temperature Set Point                          
                                                        </Grid>
                                              </Grid>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{whiteSpace:"nowrap",fontSize:"3vh",color:"#0123b4",fontWeight:"bold"}}>
                                                            23°C                    
                                                        </Grid>
                                              </Grid>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                              <div style={{width:'100px',marginTop:"-2vh"}}>
                                                                <ReactSimpleRange 
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                                trackColor='blue'
                                                                thumbColor="blue"
                                                                label={true}
                                                                eventWrapperPadding={8}/>
                                                              </div>               
                                                        </Grid>
                                              </Grid>
                                        </Grid>
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                    <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",alignContent:"center",alignItems:"center"}}>
                                          <Grid container xs={12} spacing={1}>                       
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                          Damper Control                          
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                            <div style={{ width: "19vh", height: "3vh"}}>
                                                                <SwitchSelector
                                                                    onChange={onChange}
                                                                    options={options1}
                                                                    initialSelectedIndex={initialSelectedIndex1}
                                                                    // border="1px solid #0123B4"
                                                                    backgroundColor={"#e9e5e5"}
                                                                    fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                  // wrapperBorderRadius={true}
                                                                  //optionBorderRadius={true}
                                                                />
                                                            </div>                         
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                            <div style={{color:"#0123b4",fontWeight:"bold"}}>50%</div>                      
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                            <div style={{width:'100px',marginTop:"-4px",marginLeft:"26px"}}>
                                                                  <ReactSimpleRange 
                                                                  min={0}
                                                                  max={100}
                                                                  step={1}
                                                                  trackColor='blue'
                                                                  thumbColor="blue"
                                                                  label={true}
                                                                  eventWrapperPadding={8}
                                                                  />
                                                            </div>                          
                                                      </Grid>
                                                </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} direction="column" spacing={1} style={{alignContent:"center",alignItems:"center"}}>
                                          <Grid item xs={2} style={{whiteSpace:"nowrap",color:"black",marginLeft:"-6vh"}}>
                                            Design CFM
                                          </Grid> 
                                          <Grid item xs={10}>
                                              <Grid container xs={12} direction="column" spacing={1} style={{color:"#0123b4",whiteSpace:"nowrap",fontSize:"2vh",fontWeight:"bold"}}>
                                                  <Grid item xs={6}>250</Grid>
                                                  <Grid item xs={6}>230</Grid>
                                              </Grid>
                                          </Grid> 
                                      </Grid>
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",backgroundColor:"#fed0c1",color:"#fc6434"}}>
                                    <div style={{fontSize:"5vh",marginTop:"2vh",fontWeight:"bold"}}>
                                        26°C
                                    </div>
                                </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} spacing={1} direction="column" >                        
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                    <div style={{marginTop:"-2vh"}}>
                                                      <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50} showPercentValue />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                     Damper Position      
                                                </Grid>
                                            </Grid>
                                      </Grid>            
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} spacing={1} direction="column">                       
                                          <Grid container item xs={12}>
                                              <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                <div style={{marginTop:"-2vh"}}>
                                                    <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={25} showPercentValue />
                                                </div>
                                              </Grid>
                                          </Grid>
                                          <Grid container item xs={12}>
                                              <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                    CFM
                                              </Grid>
                                          </Grid>
                                      </Grid>     
                                  </Card>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.paper} style={{height:"40vh",backgroundColor:"#ffffff",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                        <Grid container xs={12} spacing={1}>
                          <Grid item xs={6}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} style={{fontSize:"3vh",color:"black"}}>Software</Grid>
                              <Grid item xs={6}  style={{fontSize:"2vh",textDecorationLine:"underline",color:"#0123b4"}}>3F-PH1-VAV1</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container xs={12} >
                                <Grid item xs={12}>
                                    <ButtonBase style={{width:"100%",alignItems:"flex-end"}}  >
                                            <Card style={{height:"35px",marginLeft:"27vh",marginTop:"1vh"}}>
                                              <Grid container xs={12}>
                                                <Grid xs={6}>
                                                    <NotificationLow />
                                                  </Grid>
                                                </Grid>    
                                              </Card>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1}>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                        <Grid container xs={12} spacing={1} direction="column" style={{justifyContent:"right",alignItems:"center"}}>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                              Temperature Set Point                          
                                                        </Grid>
                                              </Grid>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{whiteSpace:"nowrap",fontSize:"3vh",color:"#0123b4",fontWeight:"bold"}}>
                                                            23°C                    
                                                        </Grid>
                                              </Grid>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                              <div style={{width:'100px',marginTop:"-2vh"}}>
                                                                <ReactSimpleRange 
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                                trackColor='blue'
                                                                thumbColor="blue"
                                                                label={true}
                                                                eventWrapperPadding={8}/>
                                                              </div>               
                                                        </Grid>
                                              </Grid>
                                        </Grid>
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                    <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",alignContent:"center",alignItems:"center"}}>
                                          <Grid container xs={12} spacing={1}>                       
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                          Damper Control                          
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                            <div style={{ width: "19vh", height: "3vh"}}>
                                                                <SwitchSelector
                                                                    onChange={onChange}
                                                                    options={options1}
                                                                    initialSelectedIndex={initialSelectedIndex1}
                                                                    // border="1px solid #0123B4"
                                                                    backgroundColor={"#e9e5e5"}
                                                                    fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                  // wrapperBorderRadius={true}
                                                                  //optionBorderRadius={true}
                                                                />
                                                            </div>                         
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                            <div style={{color:"#0123b4",fontWeight:"bold"}}>50%</div>                      
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                            <div style={{width:'100px',marginTop:"-4px",marginLeft:"26px"}}>
                                                                  <ReactSimpleRange 
                                                                  min={0}
                                                                  max={100}
                                                                  step={1}
                                                                  trackColor='blue'
                                                                  thumbColor="blue"
                                                                  label={true}
                                                                  eventWrapperPadding={8}
                                                                  />
                                                            </div>                          
                                                      </Grid>
                                                </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} direction="column" spacing={1} style={{alignContent:"center",alignItems:"center"}}>
                                          <Grid item xs={2} style={{whiteSpace:"nowrap",color:"black",marginLeft:"-6vh"}}>
                                            Design CFM
                                          </Grid> 
                                          <Grid item xs={10}>
                                              <Grid container xs={12} direction="column" spacing={1} style={{color:"#0123b4",whiteSpace:"nowrap",fontSize:"2vh",fontWeight:"bold"}}>
                                                  <Grid item xs={6}>250</Grid>
                                                  <Grid item xs={6}>230</Grid>
                                              </Grid>
                                          </Grid> 
                                      </Grid>
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",backgroundColor:"#fed0c1",color:"#fc6434"}}>
                                    <div style={{fontSize:"5vh",marginTop:"2vh",fontWeight:"bold"}}>
                                        26°C
                                    </div>
                                </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} spacing={1} direction="column" >                        
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                    <div style={{marginTop:"-2vh"}}>
                                                      <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50} showPercentValue />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                     Damper Position      
                                                </Grid>
                                            </Grid>
                                      </Grid>            
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} spacing={1} direction="column">                       
                                          <Grid container item xs={12}>
                                              <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                <div style={{marginTop:"-2vh"}}>
                                                    <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={25} showPercentValue />
                                                </div>
                                              </Grid>
                                          </Grid>
                                          <Grid container item xs={12}>
                                              <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                    CFM
                                              </Grid>
                                          </Grid>
                                      </Grid>     
                                  </Card>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.paper} style={{height:"40vh",backgroundColor:"#ffffff",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                        <Grid container xs={12} spacing={1}>
                          <Grid item xs={6}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} style={{fontSize:"3vh",color:"black"}}>Software</Grid>
                              <Grid item xs={6}  style={{fontSize:"2vh",textDecorationLine:"underline",color:"#0123b4"}}>3F-PH1-VAV1</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container xs={12} >
                                <Grid item xs={12}>
                                    <ButtonBase style={{width:"100%",alignItems:"flex-end"}}  >
                                            <Card style={{height:"35px",marginLeft:"27vh",marginTop:"1vh"}}>
                                              <Grid container xs={12}>
                                                <Grid xs={6}>
                                                    <NotificationLow />
                                                  </Grid>
                                                </Grid>    
                                              </Card>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1}>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                        <Grid container xs={12} spacing={1} direction="column" style={{justifyContent:"right",alignItems:"center"}}>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                              Temperature Set Point                          
                                                        </Grid>
                                              </Grid>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{whiteSpace:"nowrap",fontSize:"3vh",color:"#0123b4",fontWeight:"bold"}}>
                                                            23°C                    
                                                        </Grid>
                                              </Grid>
                                              <Grid container item xs={12}>
                                                        <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                              <div style={{width:'100px',marginTop:"-2vh"}}>
                                                                <ReactSimpleRange 
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                                trackColor='blue'
                                                                thumbColor="blue"
                                                                label={true}
                                                                eventWrapperPadding={8}/>
                                                              </div>               
                                                        </Grid>
                                              </Grid>
                                        </Grid>
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                    <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",alignContent:"center",alignItems:"center"}}>
                                          <Grid container xs={12} spacing={1}>                       
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                          Damper Control                          
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                            <div style={{ width: "19vh", height: "3vh"}}>
                                                                <SwitchSelector
                                                                    onChange={onChange}
                                                                    options={options1}
                                                                    initialSelectedIndex={initialSelectedIndex1}
                                                                    // border="1px solid #0123B4"
                                                                    backgroundColor={"#e9e5e5"}
                                                                    fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                  // wrapperBorderRadius={true}
                                                                  //optionBorderRadius={true}
                                                                />
                                                            </div>                         
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black"}}>
                                                            <div style={{color:"#0123b4",fontWeight:"bold"}}>50%</div>                      
                                                      </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                      <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                            <div style={{width:'100px',marginTop:"-4px",marginLeft:"26px"}}>
                                                                  <ReactSimpleRange 
                                                                  min={0}
                                                                  max={100}
                                                                  step={1}
                                                                  trackColor='blue'
                                                                  thumbColor="blue"
                                                                  label={true}
                                                                  eventWrapperPadding={8}
                                                                  />
                                                            </div>                          
                                                      </Grid>
                                                </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} direction="column" spacing={1} style={{alignContent:"center",alignItems:"center"}}>
                                          <Grid item xs={2} style={{whiteSpace:"nowrap",color:"black",marginLeft:"-6vh"}}>
                                            Design CFM
                                          </Grid> 
                                          <Grid item xs={10}>
                                              <Grid container xs={12} direction="column" spacing={1} style={{color:"#0123b4",whiteSpace:"nowrap",fontSize:"2vh",fontWeight:"bold"}}>
                                                  <Grid item xs={6}>250</Grid>
                                                  <Grid item xs={6}>230</Grid>
                                              </Grid>
                                          </Grid> 
                                      </Grid>
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",backgroundColor:"#fed0c1",color:"#fc6434"}}>
                                    <div style={{fontSize:"5vh",marginTop:"2vh",fontWeight:"bold"}}>
                                        26°C
                                    </div>
                                </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} spacing={1} direction="column" >                        
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                    <div style={{marginTop:"-2vh"}}>
                                                      <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50} showPercentValue />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                     Damper Position      
                                                </Grid>
                                            </Grid>
                                      </Grid>            
                                  </Card>
                              </Grid>
                              <Grid item xs={4}>
                                  <Card className={classes.paper} style={{height:"14vh",boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                      <Grid container xs={12} spacing={1} direction="column">                       
                                          <Grid container item xs={12}>
                                              <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                                                <div style={{marginTop:"-2vh"}}>
                                                    <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={25} showPercentValue />
                                                </div>
                                              </Grid>
                                          </Grid>
                                          <Grid container item xs={12}>
                                              <Grid item xs={12} style={{whiteSpace:"nowrap",color:"black",marginTop:"-1vh"}}>
                                                    CFM
                                              </Grid>
                                          </Grid>
                                      </Grid>     
                                  </Card>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    </div>
  );
}

export default GlVav;
