import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from "react-router-dom";
import { dashboardRoutes, buildingRoutes } from "routes.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Footer from 'components/Footer/Footer';
import Button from "components/CustomButtons/Button.js";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import Sidebar from 'components/Sidebar/Sidebar.js';
import logo from "assets/img/Logo.png";
import bgImage from "assets/img/sidebar-2.jpg";
import Hidden from '@material-ui/core/Hidden';
import NewDashboard from "views/Dashboard/NewDashboard";
import Home from "views/Custom/Home.js";
import City from "views/Custom/City.js";
import RoomBooking from 'views/Booking Flow/RoomBooking.js';
import SeatBooking from 'views/Booking Flow/SeatBooking.js';
import Floors from 'views/Floors/Floors';
import upload from 'views/Custom/upload.js';
import Configuration from 'views/Configuration/Configuration.js';
import Schedule from 'views/Scheduler/Schedule.js';
import NetworkDiagram from 'views/NetworkDiagram/NetworkDiagram.js';
import Controls from 'views/Controls/AhuDevices';
import Table from 'views/Controls/table'
import AlertsMain from 'views/Custom/AlertsMain';
import FloorOccupancyNew from 'views/Occupancy/FloorOccupancyNew';
import hvac from 'views/Heatmap/hvac';
import ConfigureSetpoint from 'views/Heatmap/ConfigureSetpoint';
import Zones from "views/Zones/zones";
import Zonelist from 'views/Zones/Zonelist';
import Area from 'views/Area/Area';
import Arealist from 'views/Area/Arealist';
import Areadetail from 'views/Area/Areadetail';
import Zonesmain from 'views/Zones/Zonesmain';
import GlEventsViewer from "views/Custom/GlEventsViewer";
import GlAhu from 'views/Heatmap/GlAhu';
import Devicemap from 'views/Heatmap/Devicemap';
import Grid from '@material-ui/core/Grid';
import Card from 'components/Card/Card';
import NotificationIcon from 'assets/img/NotificationIcon';
import NotificationLow from 'assets/img/NotificationLow';
import tempIcon from 'assets/img/NavBArTemp.png'
import NavIcon from 'assets/img/navBarHum.png'
import ButtonBase from '@material-ui/core/ButtonBase';
import { useSelector } from 'react-redux';
import GlReports from 'views/Custom/GLReports';
import GlLms from 'views/Heatmap/GlLms';
import GlSchedule from 'views/GlScheduler/Schedule.js';
import GlUps from 'views/Heatmap/GlUps';
import GlUpsLanding from 'views/Heatmap/GlUpsLanding';
import GlVav from 'views/Heatmap/GlVav';
import GlEnergyMeter from 'views/Heatmap/GlEnergyMeter';
import Vdashaboard from 'views/Custom/Vdashaboard'
import Vdevice from 'views/Custom/Vdevice'
import { Blink } from "@bdchauvette/react-blink";
import Selector from 'views/Heatmap/seletor';
import { format, compareAsc } from 'date-fns';
import AddUser from 'views/Custom/AddUser';
import ChangePassword from 'views/Custom/ChangePassword';
import Vmapdeviceuser from 'views/Custom/Vmapdeviceuser'


const drawerWidth = '15%';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: "BwSeidoRound-Regular",
  },
  appBar: {
    boxShadow: "none",
    position: "absolute",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    boxShadow: "none",
    position: "absolute",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    justifyContent: 'end',
    flex: 1,
    "&:hover,&:focus": {
      background: "transparent"
    },
    [theme.breakpoints.down('xs')]:
      { fontSize: '12px' }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    // "min-height":"64px",
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));


function makeBrand(props) {
  var name;
  dashboardRoutes.map(prop => {
    if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
      name = prop.name;
    }
    return null;
  });
  return name;
}

export default function Admin(props, { ...rest }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [image] = React.useState(bgImage);
  const [color] = React.useState("vgreen");
  const [path, setPath] = React.useState("")
  const locale = 'en';
  const [today, setDate] = React.useState(new Date());
  const [blink, setBlink] = React.useState(false);
  const alerts = useSelector(state => state.alarm.alarmData)

  useEffect(() => { 
    let ackActive = alerts.system.filter(each => {
      if (each.acknowledged === 0) {
        return each
      }
    })  
    if (ackActive.length > 0) {
      return setBlink(true)
    } else {
      return setBlink(false)
    }
  }, [alerts.system])

  const handleClick = (val) => {
    if (val === "home") {
      localStorage.removeItem("floorID");
      localStorage.removeItem("floorName");
      localStorage.removeItem("zoneName");
      localStorage.removeItem("zoneID");
      localStorage.removeItem("controlFloorID")
      localStorage.removeItem("contolFloorName")
      props.history.push("/admin/building/" + localStorage.getItem("buildingID") + "/dashboard")
    } else if (val === "floor") {
      localStorage.removeItem("zoneID");
      localStorage.removeItem("zoneName");
      localStorage.removeItem("deviceID");
      localStorage.removeItem("deviceName");
      props.history.push("/admin/zoneMain")
    } else if (val === "zone") {
      localStorage.removeItem("zoneID");
      localStorage.removeItem("zoneName");
      props.history.push("/admin/zone")
    } else if (val === "controlFloor") {
      localStorage.removeItem("deviceID");
      localStorage.removeItem("deviceName");
      props.history.push("/admin/controls")
    }
    else {
      localStorage.removeItem("floorID");
      localStorage.removeItem("floorName");
      localStorage.removeItem("zoneID");
      localStorage.removeItem("zoneName");
      localStorage.removeItem("controlFloorID")
      localStorage.removeItem("contolFloorName")
      props.history.push("/admin/building/" + localStorage.getItem("buildingID") + "/dashboard")
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeContext = (val) => {
  }

  const redirectToAlert = () => {
    props.history.push('/admin/eventsviewer')
  }

  useEffect(() => {
    const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    },  1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  })

  const data = localStorage.getItem("buildingName")
  useEffect(() => {
    switch (props.location.pathname) {
      case "/admin/home": setPath("/admin/home")
        break;
      case "/admin/city": setPath("/admin/city")
        break;
      case `/admin/building/${localStorage.getItem("buildingID")}/dashboard`: setPath(`/admin/building/${localStorage.getItem("buildingID")}/dashboard`)
        break;
      case "/admin/floors": setPath("/admin/floors");
        break;
      case `/admin/floor/${localStorage.getItem("floorID")}/dashboard`: setPath(`/admin/floor/${localStorage.getItem("floorID")}/dashboard`)
        break;
      case `/admin/floor/${localStorage.getItem("floorID")}/zones`: setPath(`/admin/floor/${localStorage.getItem("floorID")}/zones`)
        break;
      case `/admin/zone/${localStorage.getItem("zoneID")}/zones`: setPath(`/admin/zone/${localStorage.getItem("zoneID")}/zones`);
        break;
      case `/admin/zone`: setPath(`/admin/zone`);
        break;
      case `/admin/floor/${localStorage.getItem("floorID")}/heatmap`:
        setPath(`/admin/floor/${localStorage.getItem("floorID")}/heatmap`); break;
      case `/admin/eventsviewer`: setPath("/admin/eventsviewer"); break;
      case `/admin/areas`: setPath(`/admin/areas`); break;
      case `admin/arealist`: setPath(`admin/arealist`); break;
      case `admin/areadetail`: setPath(`/admin/areadetail`); break;
      case `/admin/alerts`: setPath("/admin/alerts"); break;
      case `/admin/hvac`: setPath("/admin/hvac"); break;
      case `/admin/configuresetpoint`: setPath("/admin/configuresetpoint"); break;
      case `/admin/upload`: setPath("/admin/upload"); break;
      case `/admin/configuration`: setPath("/admin/configuration"); break;
      default:
    }
  }, [props.location.pathname])
  const day = today.toLocaleDateString(locale, { weekday: 'short' });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;

  const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ width: '85%' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)} >
            <MenuIcon />
          </IconButton>
          <div className={classes.title}>
            <Grid container xs={12} style={{
              justifyContent: 'end', flexDirection: 'row',
              flex: 1,
            }} spacing={2}>
              <Grid item xs={6}>

              </Grid>
              <Grid xs={6}>
                <Grid container xs={12} direction='row' >
                  <Grid xs={2}>
                   
                  </Grid>

                  <Grid xs={2} style={{ marginLeft: '4px' }}>
                   
                  </Grid>


                  <Grid xs={3} style={{ marginLeft: '4px' }}>
                   
                  </Grid>


                  <Grid xs={4} style={{ marginLeft: '4px' }}>
                    <Card style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#f2f2f7" }}>
                      <Grid container xs={12} style={{ height: "35px" }}>
                        <Grid xs={12}>
                        <div style={{ "marginTop": "3px", "marginLeft": "5vh", whiteSpace: 'nowrap' }}> {format(new Date(),"yyyy-MM-dd HH:mm:ss")}</div>
                        </Grid>
                      </Grid>


                    </Card>
                  </Grid>

                </Grid>

              </Grid>

            </Grid>

          </div>
          <Hidden smDown implementation="css">
            <AdminNavbarLinks />
          </Hidden>
        </Toolbar>
      </AppBar>
      <div style={{ width: '15%' }}>
        <Sidebar
          routes={buildingRoutes}
          logo={logo}
          image={image}
          handleDrawerClose={handleDrawerClose}
          open={open}
          color={color}
          {...rest}
        />
      </div>
      <div style={{ width: '85%' }}>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route path="/admin/home" component={Home} />
            <Route path="/admin/city" render={(props) => <City {...props} changeContext={changeContext} />} />
            <Route path="/admin/:context/:id/dashboard" render={(props) => <NewDashboard {...props} changeContext={changeContext} />} />
            <Route path="/admin/floors" render={(props) => <Floors {...props} changeContext={changeContext} />} />
            <Route path="/admin/floor/:id/:param/zones" render={(props) => <Zones {...props} changeContext={changeContext} />} />
            <Route path="/admin/zone/:id/areas" component={Area} />
            <Route path="/admin/zone" render={(props) => <Zonelist {...props} changeContext={changeContext} />} />
            <Route path="/admin/areas/:param" component={Area} />
            <Route path="/admin/arealist" render={(props) => <Arealist {...props} changeContext={changeContext} />} />
            <Route path="/admin/zoneMain" render={(props) => <Zonesmain {...props} changeContext={changeContext} />} />

            <Route path="/admin/areadetail" component={Areadetail} />
            <Route path="/admin/eventsviewer" component={GlEventsViewer} />
            <Route path="/admin/reportsviewer" component={GlReports} />


            <Route path="/admin/floor/:id/hvac" component={hvac} />
            <Route path="/admin/zone/hvac" component={Area} />
            <Route path="/admin/configuresetpoint/" component={ConfigureSetpoint} />
            <Route path="/admin/room-booking/" component={RoomBooking} />
            <Route path="/admin/seat-booking/" component={SeatBooking} />
            <Route path="/admin/hvac/" component={hvac} />
            <Route path="/admin/flooroccupancy" component={FloorOccupancyNew} />
            <Route path="/admin/upload" component={upload} />
            <Route path="/admin/configuration" component={Configuration} />
            <Route path="/admin/schedule" component={Schedule} />
            <Route path="/admin/device" component={Table} />
            <Route path="/admin/controls" component={Controls} />
            <Route path="/admin/networkDiagram" component={NetworkDiagram} />
            <Route path="/admin/alerts" component={AlertsMain} />
            <Route path="/admin/glAhu" component={GlAhu} />
            <Route path="/admin/glLms" component={GlLms} />
            <Route path="/admin/devicemap" component={Devicemap} />
            <Route path="/admin/selector" component={Selector} />
            <Route path="/admin/Glschedule" component={GlSchedule} />
            <Route path="/admin/glUps" component={GlUps} />
            <Route path="/admin/glVav" component={GlVav} />
            <Route path="/admin/glLandingUps" component={GlUpsLanding} />
            <Route path="/admin/glEnergyMeter" component={GlEnergyMeter} />
            <Route path="/admin/dashboard" component={Vdashaboard} />
            <Route path="/admin/vdevice" component={Vdevice} />
            <Route path="/admin/addUser" component={AddUser}/>
            <Route path="/admin/changepassword" component={ChangePassword}/>
            <Route path="/admin/Vmap" component={Vmapdeviceuser}/>


            <Redirect from="/admin" to="/admin/login" />

          </Switch>
          <Footer />
        </main>
      </div>
    </div>
  );
}
Admin.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "Blue"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
