import React, {  useEffect, useState } from "react";
import ReactApexCharts from 'react-apexcharts'
import { format, compareAsc, toDate,addMinutes } from 'date-fns'
import { propTypes } from "react-progressbar-semicircle";

export default function ApexChart(props)  {
    
   const data=(props.data !== undefined) ? props.data: []
   const data2=(props.data2 !== undefined) ? props.data2: []
   const data3=(props.data3 !== undefined) ? props.data3: []

    let elements = new Map();
    let elements1 = new Map();
    let elements2 = new Map();

    data.map((_elem, i) =>{
      var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
        elements.set(i, {x: myDate, y: parseFloat(_elem.param_value).toFixed(2)})
      
    })
    data2.map((_elem, i) =>{
        var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
        elements1.set(i, {x: myDate, y: parseFloat(_elem.param_value).toFixed(2)})   
    })
    data3.map((_elem, i) =>{
        var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
        elements2.set(i, {x: myDate, y: parseFloat(_elem.param_value).toFixed(2)})   
    })
    var elemArr = [], elemArr1 = [], elemArr2 = [];
    for(let i of elements.values()) {
        elemArr.push(i)
    }
    for(let i of elements1.values()) {
        elemArr1.push(i)
    }
    for(let i of elements2.values()) {
        elemArr2.push(i)
    }
    const dataset = {
          
        series: [{
              name: "Phase-1",
              data: elemArr
            },
            {
              name: "Phase-2",
              data: elemArr1
            },
            {
              name: "Phase-3",
              data: elemArr2
            }
        
        ],
        options: {
          chart: {
            type: 'area',
            stacked: false,
            // height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            
             
            },
            toolbar: {
              autoSelected: 'zoom'
            }
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },           
          title: {
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.5,
              inverseColors: false,
              opacityFrom: 0.3,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          yaxis: {
             tickAmount:2,
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: false,
            // y: {
            //   formatter: function (val) {
            //     return (val / 1000000).toFixed(0)
            //   }
            // }
          }
        },
      
      
      };
    
    return (
    <div id="chart">
        <ReactApexCharts options={dataset.options} series={dataset.series} type="area"   />
    </div>
    );
    }
 




  
