//import {React, useEffect, useState} from "react";
import React, { useEffect,useCallback,useRef} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import TotalValueRenderer from './totalValueRenderer.jsx';
import { useSelector } from 'react-redux';
import value from './value';

// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: '#fff',
//   },
// }));

// function getTableData(myinput = [], param_map = {}) {
   
    
//     var myoutput = [], odata = [];
    
//     var  i = 0, j = 0;
    
//     var params = Object.keys(param_map);
//     for (i=0; i<myinput.length; i++){
//         odata = [];
//         for (j=0; j<params.length; j++){
//             odata[j] = myinput[i][params[j]];
//         }
//         myoutput.push(odata);
//     }
   
//     return myoutput;
    
//     }

function GlEventsViewer(props) {
              // const classes=useStyles();
            const gridRef = useRef();
            // const [eventdata,setEventData]=React.useState([]);
            const alerts = useSelector(state => state.alarm.alarmData)
            
            const[post,setPost]=React.useState(null);
            // const [alarmData,setAlarmData]=React.useState([]);
            // const [selectedRows,setSelectedRows] =useState();
            const [test,setTest]=React.useState(null);
            // const [open, setOpen] = React.useState(false);
            

            // const [counter,setCounter]=useState(0)
            let myheading = {'Device_name':"Device_name", 'date':"date",'time':"time",  'Description': "Message",'Acknowledgement': "Acknowledgement",};
            let mykeys = Object.keys(myheading);
            let columnDefs = [];
            for (let i=0; i<mykeys.length; i++){
                switch (mykeys[i]){
                  case 'Device_name' :
                    columnDefs.push({'field': mykeys[i], 'filter': true,width:'157px','sortable': true,'tooltipField': 'Device_name' ,cellStyle:{'border-right-color':'#e2e2e2'}}); 
                    break;
                    case 'date' :
                      columnDefs.push({'field': mykeys[i], 'filter': true,width:'157px','sortable': true,'tooltipField': 'Device_name' ,cellStyle:{'border-right-color':'#e2e2e2'}}); 
                      break;
                      case 'time' :
                        columnDefs.push({'field': mykeys[i], 'filter': true,width:'157px','sortable': true,'tooltipField': 'Device_name' ,cellStyle:{'border-right-color':'#e2e2e2'}}); 
                        break;
                  case 'Acknowledgement' : 
                  columnDefs.push({'field': mykeys[i], 'filter': true,width:'170px', 'suppressMenuHide':true ,'floatingFilterComponentParams': {suppressFilterButton:false},'sortable': true, 'cellRenderer': TotalValueRenderer,cellStyle:{'border-right-color':'#e2e2e2'}});
                  break;
               
                  default :
                   columnDefs.push({'field': mykeys[i], 'filter': true,flex:'1',  'sortable': true,cellStyle:{'border-right-color':'#e2e2e2'}});  
                }
                
            }

            let heading = {'Device_name':"Device_name", 'date':"date",'time':"time",'Description': "Message",'Ignore alarm': "Ignore alarm",};
            let keys = Object.keys(heading);
            let columns = [];
            for (let i=0; i<keys.length; i++){
                switch (keys[i]){ 
                  case 'Device_name' :
                    columns.push({'field': keys[i], 'filter': true,width:'70px', 'sortable': true,'tooltipField': 'Device_name' ,cellStyle:{'border-right-color':'#e2e2e2'}}); 
                    break;
                    case 'date' :
                    columns.push({'field': keys[i], 'filter': true,width:'70px', 'sortable': true,'tooltipField': 'Device_name' ,cellStyle:{'border-right-color':'#e2e2e2'}}); 
                    break;
                     case 'time' :
                    columns.push({'field': keys[i], 'filter': true,width:'70px', 'sortable': true,'tooltipField': 'Device_name' ,cellStyle:{'border-right-color':'#e2e2e2'}}); 
                    break;
                   
                  case 'Ignore alarm' : 
                  columns.push({'field': keys[i], 'filter': true,width:'80px', 'sortable': true, 'cellRenderer': value,cellStyle:{'border-right-color':'#e2e2e2'}});
                  break;
                  
                  default :
                   columns.push({'field': keys[i], 'filter': true,flex:'2' ,'sortable': true,cellStyle:{'border-right-color':'#e2e2e2'}});  
                }
                
            }

          

            useEffect(()=>{
              alerts.system.map((res) =>{
                let mes_time=res.Measured_time.split(' ')
                res.date=mes_time[0]
                res.time=mes_time[1]
               })
              setPost(alerts.system);
              alerts.solution.map((res) =>{
                let mes_time=res.Measured_time.split(' ')
                res.date=mes_time[0]
                res.time=mes_time[1]         
               })
              
            setTest(alerts.solution); 
            //   const interval = setInterval(() => {
            //           // setPost(alerts.system)
            //           // setTest(alerts.solution)   
            //         alerts.system.map((res) =>{
            //           let mes_time=res.Measured_time.split(' ')
            //           res.date=mes_time[0]
            //           res.time=mes_time[1]
            //          })
            //         setPost(alerts.system);
            //         alerts.solution.map((res) =>{
            //           let mes_time=res.Measured_time.split(' ')
            //           res.date=mes_time[0]
            //           res.time=mes_time[1]         
            //          })
            //       setTest(alerts.solution); 
            //   return () => clearInterval(interval);
            // }, 5000); 
            },[alerts]);

            // const handleHideEvents =() => {
            //   let selectedIds = [];
            //   selectedRows.map((row) => selectedIds.push(row['Id']));
            //   api.events.hide_event(encodeURIComponent(JSON.stringify(selectedIds))).then(res => {
            //     setCounter(counter+1)
            //    // alert('event hidden');
            //     return 0;
            // })
            // }
            // 'floatingFilterComponentParams': {suppressFilterButton:false},
        //     const handleDelete =() => {
        //       let deleteid = [];
        //       let payload = [selectedRows[0].Alarm_Id];
        //       //  selectedRows.map((list) =>{
        //       // payload['id'] = list['Alarm_Id']
        //       //  deleteid.push(list['Alarm Id']));

        //        let req={'id':payload}
        //       api.alarms.delete_alarm(payload).then((res)=>{
        //       })
             
           
        // }

        // const handleDelete=() => {
        //   let payload =[];
        //   selectedRows.map((list) =>{
        //      payload['id'] = list['Alarm_Id']
        //       // deleteid.push(list['Alarm Id']);
    
                   
        //           //  let req={'id': payload}
        //               //  let req={payload}
                      
        //            api.alarms.delete_alarm(payload.id).then((res)=>{
        //            })
                 

        // }
    //     const Acknowledge =() => {
    //       // let deleteid = [];
    //       let payload = [selectedRows[0].Alarm_Id];
    //       // selectedRows.map((list) =>{
    //       // payload['id'] = list['Alarm_Id']
    //       //  deleteid.push(list['Alarm Id']));

           
    //       // let req={'id':payload}
    //       api.alarms.acknowledge_alarm(payload).then((res)=>{
    //       })
         
       
    // }

  
  //       api.alarms.delete_alarm(deleteid).then(res => {

          //   const Acknowledge =() => {
          //     api.alarms.acknowledge_alarm(selectedRows[0]['Id']).then(res => {
          //       // setCounter(counter+1)
          //       alert("acknowledge")
               
          //       // return 0;
          //   })
          // }
            // const handleClick =() => {
            //   api.alarms.restore_alarm(selectedRows[0]['Id']).then(res => {
            //     // setCounter(counter+1)
            //     alert("restored")
               
            //     // return 0;
            // })
            // }

            
            // const onFirstDataRendered = useCallback((params)=>{
            //   gridRef.current.api.sizeColumnsToFit();
            // },[]);

            const onFirstDataRendered1 = useCallback((params)=>{
              gridRef.current.api.sizeColumnsToFit();
            },[]);

            // const onBtExport = useCallback(() => {
            //     gridRef.current.api.exportDataAsCsv();
            //   }, []);  

              // const onBtExport1 = useCallback(() => {
              //   gridRef.current.api.exportDataAsCsv();
              // }, []);  
              
              const onSelectionChanged1= useCallback((alarms) => {
      
              }, []);
              // const onSelectionChanged = useCallback((alarms) => {
              //    setSelectedRows(alarms.api.getSelectedRows()   );
              //    let a=alarms.api.getSelectedRows();
              //    a.map((res1)=>{
              //     localStorage.removeItem("deviceNamee")
              //     props.history.push({
              //       pathname: '/admin/glAhu',
              //       state: { data: res1.ss_id ,name:res1.Device_name}
              //   });
                  
              //    })

              // }, []);
              
  return (
     
    // <div>
     
         <div className="ag-theme-alpine" style={{height:255, width: '100%'}}>
          <h4 style={{marginTop:'-41px',marginBottom:'3px'}}>Critical Alarms</h4>
          <div>
         
         
    
     
          </div>

          
         <AgGridReact
          ref={gridRef} 
          rowData={post} 
          columnDefs={columnDefs} 
           rowSelection={'single'} 
           onSelectionChanged={onSelectionChanged1}
          pagination={true}
          paginationPageSize={10}
          suppressMenuHide="true"
          suppressFilterButton="true"
                     onFirstDataRendered = {onFirstDataRendered1}
          
          
          >
          </AgGridReact>
          
          <h4 style={{marginTop:'7px',marginBottom:'3px'}}>NonCritical Alarms</h4>
          <div>
           
          </div>

          
         <AgGridReact
          ref={gridRef} 
          rowData={test} 
          columnDefs={columns} 
           rowSelection={'single'} 
           onSelectionChanged={onSelectionChanged1}
          pagination={true}
          paginationPageSize={10}
          suppressMenuHide="true"
          suppressFilterButton="true"
                     onFirstDataRendered = {onFirstDataRendered1}
          
          
          >
          </AgGridReact>
         
         </div>
  
)

  
  
  }



export default GlEventsViewer
