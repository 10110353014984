import React from 'react'
import Login from './Login'
import Bluebck from './Bluebck'
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>({
    splitScreen: {
        display: 'flex',
        flexDirection: 'row',
        width:'100%'
    },
    topPane: {
        width: '50%',
        backgroundColor:'#06b7c1'
    },
    bottomPane: {
        width: '50%',
        backgroundColor:'#ffffff' 
    },
}));

function Whitebck() {
    const styles= useStyles();

  return (
    <div className={styles.splitScreen} style={{height:'100vh'}}>
        <div className={styles.topPane}  ><Bluebck /></div> 
        <div className={styles.bottomPane} ><Login /></div> 
    </div>
  )
}

export default Whitebck