import React, { useEffect, useState,useMemo,useRef,useCallback } from "react";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TextField, InputAdornment } from "@material-ui/core";
import api from "../../api";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";


const useStyles =makeStyles((theme)=>({
    root: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
        width: "100%",
      },
    text: {
        marginTop: "9vh",
        marginLeft: "45vh",
        fontWeight: "bold",
        fontFamily: "Arial",
        fontSize: "14px"
    },
    input: {
        textalign: "center",
        fontWeight: "bold",
        marginTop: "8.5vh",
        width: "50vh",
        marginLeft: "13vh",
    },
    err: {
        margin: "4px 0 0 0",
        fontSize: "13px",
        color: "#e64646",
        marginLeft:"12vh"
    }
}))

function ChangePassword(){
    const classes = useStyles()

    const[username,setUsers]=React.useState([]);
    const [username1, setUsername] = React.useState([]);
    const [key, setSecret] = React.useState("");
    const [city, setLocname] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [new_password, setPassword] = React.useState("");
    const [confirm_password, setConfirmPassword] = React.useState("");
    const [reset, setResetPassword] = React.useState(false);
    const[zone_id,setUserid]=React.useState([]);
    const [msg, setMsg] = React.useState("");
    const [response, setResponse] = useState(false);
    const [id, setId] = useState("");
    const[event,setEvent]=React.useState([]);
    const [isValid, setIsValid] = React.useState(false);

    useEffect(() => {
        api.floor.getuserdata().then(res=>{
          console.log("gtuser",res)
             setUsername(res)
            let username=res.map(data=>data.username)
             setUsers(username)
            let id=res.map(data=>data.id)
            setEvent(id)
        })
    },[])

    const handleChangeForUsername = (event) => {
        setUsername(event.target.value);
    };

    const handleChangeForSecret =(event) =>{
        setSecret(event.target.value);
    }

    const handleChangeForLocname = (event) => {
        setLocname(event.target.value);
    };
      const onInputChange = (event) => {
        setPassword(event.target.value);
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,15}$/;
        const isValidPassword = pattern.test(event.target.value);
        setIsValid(isValidPassword);
      }

      const handleClickShowPassword = () => setShowPassword((show) => !show);
      const handleClickShowPassword1 = () => setShowPassword1((show) => !show);


    //   const onInputChange1 = (event) => {
    //     setConfirmPassword(event.target.value);
    //     if(event.target.value != password ){
    //         const msg="Password and Confirm Password doesn't match"
    //         setMsg(msg) 
    //     }else{
    //         const msg=" "
    //         setMsg(msg)         
    //     }
    // }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      const handleMouseDownPassword1 = (event) => {
          event.preventDefault();
        };

        const handleChange = (id,name,building_id) => {
            setUsers(name);
            setUserid(building_id)
          };
      const handleSubmit = async (event) => {
        event.preventDefault();
        if(response == false){
            const payload = {
                username,
                key
            };
            api.users.editUsername(payload).then((res)=>{   
                // data.push(res.user_id)        
                if(res!=undefined || res.error !="Invalid Secret key"){
                    setResponse(true)
                    setId(res.user_id)
                }
            }).catch(err => {
                setResponse(false)
                toast({
                    type: "error",
                    icon: "exclamation triangle",
                    title: "Error",
                    description: "Secret key is not correct",
                    time: 3000,
                  });
              })
        }else{
       
          const payload1 ={
            id,
            new_password,
            reset
          }
        api.users.addNewpassword(payload1).then((res)=>{
            console.log("res",Object.keys(res))

            if(Object.keys(res)=="message"){
                toast({
                    type: "success",
                    icon: "check circle",
                    title: "Success",
                    description: "New Password added successfully ",
                    time: 3000,
                  });
            }else{
                toast({
                    type: "error",
                    icon: "exclamation triangle",
                    title: "Error",
                    description: "Password already exits",
                    time: 3000,
                  });
            }
        })
    }
    }

    return(
        <div className={classes.root} style={{ marginTop: "0%" }}>
            <Grid container spacing={1}>
                <ValidatorForm
                    style={{ width: "100%", marginTop: "55" }}
                    onSubmit={handleSubmit}
                    instantValidate={true}
                >
                    <Grid container item xs={12} spacing={1} direction="column">
                    <Grid item xs={2} >
                        <Grid container item xs={12} spacing={1}>
                            <div 
                                style={{fontWeight: "bold",
                                        fontFamily: "Arial",
                                        fontSize: "20px",
                                        marginLeft:"66vh",whiteSpace:"nowrap"}}>
                                     Change Password
                            </div>
                        </Grid>
                    </Grid>
                    {response === false ?
                     <Grid item xs={8} >
                     <Grid container item xs={12} spacing={1}>
                     <Grid item xs={6}>
                         <Grid container item xs={12} spacing={2} direction="column">
                             <Grid item xs={4}>
                                 <div className={classes.text}>UserName</div>
                             </Grid>
                             <Grid item xs={4}>
                                 <div className={classes.text}>Security Question</div>
                             </Grid>
                             <Grid item xs={4}>
                                 <div className={classes.text}>Secret</div>
                             </Grid>
                             {/* <Grid item xs={4}>
                                 <div className={classes.text}>Action</div>
                             </Grid>                         */}
                         </Grid> 
                     </Grid>
                     <Grid item xs={6}>
                         <Grid container item xs={12} spacing={0} direction="column">
                             <Grid item xs={3}>
                             <FormControl   variant="outlined" onSubmit = {handleSubmit}  style={{ width: "max-content", minWidth: "260%",marginLeft:"15vh",marginTop:"8vh" }}>
                <InputLabel id="search-select-label">Users</InputLabel>
                <Select
                        
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="user"
                        value={username}
                        // onChange={handleChange}
                >
                          {username1.map((_item) => (
                            <MenuItem key={_item.id} value={_item.username}  
                            onClick={() => handleChange(_item.id, _item.username,_item.building_id)}
                            >
                              {_item.username}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                             </Grid>
                             <Grid item xs={3}>
                            <h5 style={{marginTop:"11vh",width:"86vh",marginLeft:"14vh"}}>Which place were you born in ?</h5>
                             </Grid>
                             <Grid item xs={3}>
                             <TextField
                                                 required
                                                 size="small"
                                                 margin="normal"
                                                 // fullWidth
                                                 // placeholder="Device name"
                                                 label="Enter your Birth Place"
                                                 textalign={"center"}
                                                 value={key}
                                                 onChange={handleChangeForSecret}
                                                 // autoComplete="email"
                                                 className={classes.input}
                                                 variant="outlined"
                                                 validators={["required"]}
                             />
                             </Grid>
                            
                         </Grid> 
                     </Grid>
                 </Grid>
                     </Grid>
                     :
                     <Grid item xs={8} >
                     <Grid container item xs={12} spacing={1}>
                     <Grid item xs={6}>
                         <Grid container item xs={12} spacing={2} direction="column">
                             <Grid item xs={4}>
                                 <div className={classes.text}>UserName</div>
                             </Grid>
                             <Grid item xs={4}>
                                 <div className={classes.text}>Security Question</div>
                             </Grid>
                             <Grid item xs={4}>
                                 <div className={classes.text}>Secret</div>
                             </Grid>
                             <Grid item xs={4}>
                                 <div className={classes.text}>New Password</div>
                             </Grid>                        
                         </Grid> 
                     </Grid>
                     <Grid item xs={6}>
                         <Grid container item xs={12} spacing={0} direction="column">
                             <Grid item xs={3}>
                             <FormControl   variant="outlined" onSubmit = {handleSubmit}  style={{ width: "max-content", minWidth: "260%",marginLeft:"15vh",marginTop:"8vh" }}>
                <InputLabel id="search-select-label">Users</InputLabel>
                <Select     
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="user"
                        value={username}
                        // onChange={handleChange}
                >
                          {username1.map((_item) => (
                            <MenuItem key={_item.id} value={_item.username}  
                            onClick={() => handleChange(_item.id, _item.username,_item.building_id)}
                            >
                              {_item.username}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                             </Grid>
                             <Grid item xs={3}>
                             <h5 style={{marginTop:"11vh",width:"86vh",marginLeft:"14vh"}}>Which place were you born in ?</h5>
                             </Grid>
                             <Grid item xs={3}>
                             <TextField
                                                 required
                                                 size="small"
                                                 margin="normal"
                                                 // fullWidth
                                                 // placeholder="Device name"
                                                 label="Enter your Birth Place"
                                                 textalign={"center"}
                                                 value={key}
                                                 onChange={handleChangeForSecret}
                                                 // autoComplete="email"
                                                 className={classes.input}
                                                 variant="outlined"
                                                 validators={["required"]}
                             />
                             </Grid>
                             <Grid item xs={3}>
                            <TextField
                                                required
                                                size="small"
                                                margin="normal"
                                                // fullWidth
                                                // placeholder="Device name"
                                                label="Enter Password"
                                                textalign={"center"}
                                                name="Enter Password"
                                                value={new_password}
                                                onChange={onInputChange}
                                                // autoComplete="email"
                                                type={showPassword ? 'text' : 'password'}
                                                className={classes.input}
                                                inputProps={{ maxLength: 15 }}
                                                variant="outlined"
                                                validators={["required"]}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                           <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                            />
                             {new_password && !isValid && (
                                <div style={{ color: "red" ,width:"150vh"}}>
                                    Password must contain at least 8 characters including one uppercase letter, one lowercase letter, and one digit.
                                 </div>
                                )}
                            </Grid>
                            
                         </Grid> 
                     </Grid>
                 </Grid>
                     </Grid>

                    }
                   
                    <Grid item xs={2} >
                        <Grid container item xs={12} spacing={1}>
                            <Button
                                type="submit"
                                fullWidth
                                color="Vgreen"
                                style={{width:"40vh",marginLeft:"66vh",marginTop:"5vh"}}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
            <SemanticToastContainer position="top-center" />
        </div>
    )
}

export default ChangePassword