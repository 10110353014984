import React from 'react'

function SuccessIcon() {
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg"  width="37.31" height="37.295" viewBox="0 0 37.31 37.295">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_272" data-name="Rectangle 272" width="37.31" height="37.695" fill="#34c759"/>
    </clipPath>
  </defs>
  <g id="Group_439" data-name="Group 439" clip-path="url(#clip-path)">
    <path id="Path_361" data-name="Path 361" d="M12.792,36.349a18.8,18.8,0,0,1-9.17-6.718A18.978,18.978,0,0,1,.952,24.5,18.549,18.549,0,0,1,.946,12.791,18.713,18.713,0,0,1,3.614,7.669a19.354,19.354,0,0,1,4.05-4.057A18.614,18.614,0,0,1,12.778.944,18.655,18.655,0,0,1,24.5.944a18.732,18.732,0,0,1,5.128,2.669,19.25,19.25,0,0,1,4.055,4.057,18.812,18.812,0,0,1,2.677,5.121,18.486,18.486,0,0,1,0,11.714A18.915,18.915,0,0,1,24.5,36.349a18.609,18.609,0,0,1-11.712,0M23.4,32.9a15.345,15.345,0,0,0,4.126-2.136,15.121,15.121,0,0,0,3.257-3.251,14.825,14.825,0,0,0,2.129-4.128,14.618,14.618,0,0,0,.736-4.743A14.834,14.834,0,0,0,32.9,13.9a15.012,15.012,0,0,0-9.506-9.5,15.309,15.309,0,0,0-9.5,0A15,15,0,0,0,4.4,13.9,14.992,14.992,0,0,0,6.537,27.517,15.1,15.1,0,0,0,13.905,32.9a15.149,15.149,0,0,0,9.5,0m-7.552-5.864a2.342,2.342,0,0,1-.721-.644L10.76,21.128a1.847,1.847,0,0,1-.462-1.164,1.466,1.466,0,0,1,.448-1.086,1.486,1.486,0,0,1,1.078-.44,1.6,1.6,0,0,1,.7.153,1.781,1.781,0,0,1,.616.533l3.517,4.357,7.257-11.587a1.59,1.59,0,0,1,1.36-.869,1.665,1.665,0,0,1,1.1.407,1.307,1.307,0,0,1,.484,1.051,1.571,1.571,0,0,1-.119.575,3.739,3.739,0,0,1-.288.56L18.242,26.382a1.807,1.807,0,0,1-2.39.657" transform="translate(0 0)" fill="#34c759"/>
  </g>
</svg>

    </div>
  )
}

export default SuccessIcon



