import React from "react";
import ReactApexCharts from 'react-apexcharts'
// import moment from 'moment';
import { format, compareAsc, toDate,addMinutes } from 'date-fns'


export default function ApexChart(props) {
  const { data, param } = props;
  // let a=[];
  // a= (param==="ahu_chilled_valve")? <>AHU</>:(param==="static_pressure")? <>Static Pressure</>:(param==="ahu_supply_air_temperature")?<>SAT</>:<></>}
  // let element = new Map()
  let elements = new Map(), elementsMin = new Map(), elementsMax = new Map();
  let lcl = 15, ucl = 30;
  switch (param) {
    case "Supply Air Temperature": lcl = 18; ucl = 25; break;
    case "Return Air Temperature": lcl = 22; ucl = 27; break;
    case "Chilled Water Valve Feedback": lcl = 0; ucl = 100; break;
    default: lcl = 15; ucl = 30; break;
  }
  data.map((_elem, i) => {
    // var myDate = new Date(_elem.measured_time);
    // moment(oldDateObj).add(30, 'm').toDate();
    var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
    // var myDate = moment(_elem.measured_time).add(330, 'm').toDate();
    // var myDate =_elem.measured_time
    // var myEpoch = myDate.getTime()/1000.0;
    elements.set(i, { x: myDate, y: parseFloat(_elem.param_value).toFixed(2) })
    elementsMin.set(i, { x: myDate, y: lcl })
    elementsMax.set(i, { x: myDate, y: ucl })
    return (<></>);
  })
  var elemArr = [], elemArrMin = [], elemArrMax = [];;
  for (let i of elements.values()) {
    elemArr.push(i)
  }
  for (let j of elementsMin.values()) {
    elemArrMin.push(j)
  }
  for (let k of elementsMax.values()) {
    elemArrMax.push(k)
  }

  let dataSet = {}
  if (param !== 'Chilled Water Valve Feedback') {
    dataSet = {
      series: [{
        name: param,
        data: elemArr
      },
      {
        name: "Upper Limit",
        data: elemArrMax
      }, {
        name: "Lower Limit",
        data: elemArrMin
      }
      ],
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: false,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        colors: ['#2E93fA', '#FF0000', '#FF0000'],
        stroke: {
          width: [4, 2, 2]
        },
        title: {
          text: param,
          align: 'left'
        },
        legend: {
          show: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          show: true,
          showAlways: true,
          min: 0,
          tickAmount: 6,
          max: 30,
          decimalsInFloat: false,
          formatter: (val) => { return val },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },
    }
  } else {
    dataSet = {
      series: [{
        name: param,
        data: elemArr
      }
      ],
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: false,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        colors: ['#2E93fA'],
        stroke: {
          width: [4]
        },
        title: {
          text: param,
          align: 'left'
        },
        legend: {
          show: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          show: true,
          showAlways: true,
          min: 0,
          tickAmount: 6,
          max: 30,
          decimalsInFloat: true,
          formatter: (val) => { return val },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },
    }
  }
  return (
    <div id="chart">
      <ReactApexCharts options={dataSet.options} series={dataSet.series} type="area" />
    </div>
  );
}
