import React from 'react';

function NewAlerts(){
    return(
        <div>
           <h1>Alert</h1> 
        </div>
    )
}

export default NewAlerts