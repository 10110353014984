import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import api from "../../api";
import { Box } from "@material-ui/core";
import TimeSeriesUps from "../TimeSeriesUps.js";
import TimeSeriesUpsStatic from "../TimeSeriesUpsStatic";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Card,
} from "@material-ui/core";
import Ups from "../../assets/img/ups.png";
import Success from "components/Typography/Success";
import Danger from "components/Typography/Danger";
import SemiCircleProgressBar from "react-progressbar-semicircle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  CardbodyInsideGrid: {
    "justify-content": "center",
    display: "inline-flex",
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
  },
}));
export default function GlAhu() {
  const classes = useStyles();
  const [inputVoltagePhaseA, setInputVoltagePhaseA] = useState("");
  const [inputVoltagePhaseB, setInputVoltagePhaseB] = useState("");
  const [inputVoltagePhaseC, setInputVoltagePhaseC] = useState("");
  const [outputVoltagePhaseA, setOutputVoltagePhaseA] = useState("");
  const [outputVoltagePhaseB, setOutputVoltagePhaseB] = useState("");
  const [outputVoltagePhaseC, setOutputVoltagePhaseC] = useState("");
  const [outputCurrentPhaseA, setOutputCurrentPhaseA] = useState("");
  const [outputCurrentPhaseB, setOutputCurrentPhaseB] = useState("");
  const [outputCurrentPhaseC, setOutputCurrentPhaseC] = useState("");
  const [upsTemperature, setUpsTemperature] = useState("");
  const [upsInputFrequency, setUpsInputFrequency] = useState("");
  const [upsOutputFrequency, setUpsOutputFrequency] = useState("");
  const [UpsOutputVoltageGraph, setUpsOutputVoltageGraph] = useState({});
  const [UpsOutputCurrentGraph, setUpsOutputCurrentGraph] = useState({});
  // const [dcInputBatteryVoltage, setDCInputBatteryVoltage] = useState("");

  const dataSeries = [
    [
      {
        date: "2014-04-29",
        value: 79470783,
      },
      {
        date: "2014-04-30",
        value: 80170783,
      },
    ],
    [
      {
        date: "2014-01-01",
        value: 150000000,
      },
      {
        date: "2014-01-02",
        value: 160379978,
      },
      {
        date: "2014-01-03",
        value: 170493749,
      },
      {
        date: "2014-01-04",
        value: 160785250,
      },
      {
        date: "2014-01-05",
        value: 167391904,
      },
      {
        date: "2014-01-06",
        value: 161576838,
      },
      {
        date: "2014-01-07",
        value: 161413854,
      },
      {
        date: "2014-01-08",
        value: 152177211,
      },
      {
        date: "2014-01-09",
        value: 140762210,
      },
      {
        date: "2014-01-10",
        value: 144381072,
      },
      {
        date: "2014-01-11",
        value: 154352310,
      },
      {
        date: "2014-01-12",
        value: 165531790,
      },
      {
        date: "2014-01-13",
        value: 175748881,
      },
      {
        date: "2014-01-14",
        value: 187064037,
      },
      {
        date: "2014-01-15",
        value: 197520685,
      },
      {
        date: "2014-01-16",
        value: 210176418,
      },
      {
        date: "2014-01-17",
        value: 196122924,
      },
      {
        date: "2014-01-18",
        value: 207337480,
      },
      {
        date: "2014-01-19",
        value: 200258882,
      },
      {
        date: "2014-01-20",
        value: 186829538,
      },
      {
        date: "2014-01-21",
        value: 192456897,
      },
      {
        date: "2014-01-22",
        value: 204299711,
      },
      {
        date: "2014-01-23",
        value: 192759017,
      },
      {
        date: "2014-01-24",
        value: 203596183,
      },
      {
        date: "2014-01-25",
        value: 208107346,
      },
      {
        date: "2014-01-26",
        value: 196359852,
      },
      {
        date: "2014-01-27",
        value: 192570783,
      },
      {
        date: "2014-01-28",
        value: 177967768,
      },
      {
        date: "2014-01-29",
        value: 190632803,
      },
      {
        date: "2014-01-30",
        value: 203725316,
      },
      {
        date: "2014-01-31",
        value: 218226177,
      },
      {
        date: "2014-02-01",
        value: 210698669,
      },
      {
        date: "2014-02-02",
        value: 217640656,
      },
      {
        date: "2014-02-03",
        value: 216142362,
      },
      {
        date: "2014-02-04",
        value: 201410971,
      },
      {
        date: "2014-02-05",
        value: 196704289,
      },
      {
        date: "2014-02-06",
        value: 190436945,
      },
      {
        date: "2014-02-07",
        value: 178891686,
      },
      {
        date: "2014-02-08",
        value: 171613962,
      },
      {
        date: "2014-02-09",
        value: 157579773,
      },
      {
        date: "2014-02-10",
        value: 158677098,
      },
      {
        date: "2014-02-11",
        value: 147129977,
      },
      {
        date: "2014-02-12",
        value: 151561876,
      },
      {
        date: "2014-02-13",
        value: 151627421,
      },
      {
        date: "2014-02-14",
        value: 143543872,
      },
      {
        date: "2014-02-15",
        value: 136581057,
      },
      {
        date: "2014-02-16",
        value: 135560715,
      },
      {
        date: "2014-02-17",
        value: 122625263,
      },
      {
        date: "2014-02-18",
        value: 112091484,
      },
      {
        date: "2014-02-19",
        value: 98810329,
      },
      {
        date: "2014-02-20",
        value: 99882912,
      },
      {
        date: "2014-02-21",
        value: 94943095,
      },
      {
        date: "2014-02-22",
        value: 104875743,
      },
      {
        date: "2014-02-23",
        value: 116383678,
      },
      {
        date: "2014-02-24",
        value: 125028841,
      },
      {
        date: "2014-02-25",
        value: 123967310,
      },
      {
        date: "2014-02-26",
        value: 133167029,
      },
      {
        date: "2014-02-27",
        value: 128577263,
      },
      {
        date: "2014-02-28",
        value: 115836969,
      },
      {
        date: "2014-03-01",
        value: 119264529,
      },
      {
        date: "2014-03-02",
        value: 109363374,
      },
      {
        date: "2014-03-03",
        value: 113985628,
      },
      {
        date: "2014-03-04",
        value: 114650999,
      },
      {
        date: "2014-03-05",
        value: 110866108,
      },
      {
        date: "2014-03-06",
        value: 96473454,
      },
      {
        date: "2014-03-07",
        value: 104075886,
      },
      {
        date: "2014-03-08",
        value: 103568384,
      },
      {
        date: "2014-03-09",
        value: 101534883,
      },
      {
        date: "2014-03-10",
        value: 115825447,
      },
      {
        date: "2014-03-11",
        value: 126133916,
      },
      {
        date: "2014-03-12",
        value: 116502109,
      },
      {
        date: "2014-03-13",
        value: 130169411,
      },
      {
        date: "2014-03-14",
        value: 124296886,
      },
      {
        date: "2014-03-15",
        value: 126347399,
      },
      {
        date: "2014-03-16",
        value: 131483669,
      },
      {
        date: "2014-03-17",
        value: 142811333,
      },
      {
        date: "2014-03-18",
        value: 129675396,
      },
      {
        date: "2014-03-19",
        value: 115514483,
      },
      {
        date: "2014-03-20",
        value: 117630630,
      },
      {
        date: "2014-03-21",
        value: 122340239,
      },
      {
        date: "2014-03-22",
        value: 132349091,
      },
      {
        date: "2014-03-23",
        value: 125613305,
      },
      {
        date: "2014-03-24",
        value: 135592466,
      },
      {
        date: "2014-03-25",
        value: 123408762,
      },
      {
        date: "2014-03-26",
        value: 111991454,
      },
      {
        date: "2014-03-27",
        value: 116123955,
      },
      {
        date: "2014-03-28",
        value: 112817214,
      },
      {
        date: "2014-03-29",
        value: 113029590,
      },
      {
        date: "2014-03-30",
        value: 108753398,
      },
      {
        date: "2014-03-31",
        value: 99383763,
      },
      {
        date: "2014-04-01",
        value: 100151737,
      },
      {
        date: "2014-04-02",
        value: 94985209,
      },
      {
        date: "2014-04-03",
        value: 82913669,
      },
      {
        date: "2014-04-04",
        value: 78748268,
      },
      {
        date: "2014-04-05",
        value: 63829135,
      },
      {
        date: "2014-04-06",
        value: 78694727,
      },
      {
        date: "2014-04-07",
        value: 80868994,
      },
      {
        date: "2014-04-08",
        value: 93799013,
      },
      {
        date: "2014-04-09",
        value: 99042416,
      },
      {
        date: "2014-04-10",
        value: 97298692,
      },
      {
        date: "2014-04-11",
        value: 83353499,
      },
      {
        date: "2014-04-12",
        value: 71248129,
      },
      {
        date: "2014-04-13",
        value: 75253744,
      },
      {
        date: "2014-04-14",
        value: 68976648,
      },
      {
        date: "2014-04-15",
        value: 71002284,
      },
      {
        date: "2014-04-16",
        value: 75052401,
      },
      {
        date: "2014-04-17",
        value: 83894030,
      },
      {
        date: "2014-04-18",
        value: 90236528,
      },
      {
        date: "2014-04-19",
        value: 99739114,
      },
      {
        date: "2014-04-20",
        value: 96407136,
      },
      {
        date: "2014-04-21",
        value: 108323177,
      },
      {
        date: "2014-04-22",
        value: 101578914,
      },
      {
        date: "2014-04-23",
        value: 115877608,
      },
      {
        date: "2014-04-24",
        value: 112088857,
      },
      {
        date: "2014-04-25",
        value: 112071353,
      },
      {
        date: "2014-04-26",
        value: 101790062,
      },
      {
        date: "2014-04-27",
        value: 115003761,
      },
      {
        date: "2014-04-28",
        value: 120457727,
      },
      {
        date: "2014-04-29",
        value: 118253926,
      },
      {
        date: "2014-04-30",
        value: 117956992,
      },
    ],
  ];
  // const onClickIssue = () => { };
  useEffect(() => {
    api.floor.UpsData("8ee50faf-4f7e-11ed-9ab5-9829a659bba7").then((res) => {
      res.map((ele) => {
        if (ele.param_id === "ups_InputVoltagePhaseA") {
          setInputVoltagePhaseA(ele.param_value);
        } else if (ele.param_id === "ups_InputVoltagePhaseB") {
          setInputVoltagePhaseB(ele.param_value);
        } else if (ele.param_id === "ups_InputVoltagePhaseC") {
          setInputVoltagePhaseC(ele.param_value);
        } 
        // else if (ele.param_id === "ups_DCInputBatteryVoltage") {
        //   setDCInputBatteryVoltage(ele.param_value);
        // }
         else if (ele.param_id === "ups_OutputVoltagePhaseA") {
          setOutputVoltagePhaseA(ele.param_value);
        } else if (ele.param_id === "ups_OutputVoltagePhaseB") {
          setOutputVoltagePhaseB(ele.param_value);
        } else if (ele.param_id === "ups_OutputVoltagePhaseC") {
          setOutputVoltagePhaseC(ele.param_value);
        } else if (ele.param_id === "ups_OutputCurrentPhaseA") {
          setOutputCurrentPhaseA(ele.param_value);
        } else if (ele.param_id === "ups_OutputCurrentPhaseB") {
          setOutputCurrentPhaseB(ele.param_value);
        } else if (ele.param_id === "ups_OutputCurrentPhaseC") {
          setOutputCurrentPhaseC(ele.param_value);
        } else if (ele.param_id === "ups_Temperature") {
          setUpsTemperature(ele.param_value);
        } else if (ele.param_id === "ups_InputFrequency") {
          setUpsInputFrequency(ele.param_value);
        } else if (ele.param_id === "ups_OutputFrequency") {
          setUpsOutputFrequency(ele.param_value);
        }
      });
    });

    api.floor
      .UpsGraphData("8ee50faf-4f7e-11ed-9ab5-9829a659bba7")
      .then((res) => {

        // {Object.keys(res.graphData[0]).map((key) => (
        //   // setUpsGraphData(res.graphData[0])
        // setUpsOutputVoltageGraph(res.graphData[0].ups_OutputVoltagePhaseA)
        // setUpsOutputCurrentGraph(res.graphData[0].ups_OutputCurrentPhaseA)

        setUpsOutputVoltageGraph(res.graphData[0]);
        setUpsOutputCurrentGraph(res.graphData[0]);
        //  <TimeSeriesUps data={upsGraphData[key]} param={key} />

        // ))}
    });
    const timer = setInterval(() => {
    api.floor.UpsData("8ee50faf-4f7e-11ed-9ab5-9829a659bba7").then((res) => {
        res.map((ele) => {
          if (ele.param_id === "ups_InputVoltagePhaseA") {
            setInputVoltagePhaseA(ele.param_value);
          } else if (ele.param_id === "ups_InputVoltagePhaseB") {
            setInputVoltagePhaseB(ele.param_value);
          } else if (ele.param_id === "ups_InputVoltagePhaseC") {
            setInputVoltagePhaseC(ele.param_value);
          }
          //  else if (ele.param_id === "ups_DCInputBatteryVoltage") {
          //   setDCInputBatteryVoltage(ele.param_value);
          // }
           else if (ele.param_id === "ups_OutputVoltagePhaseA") {
            setOutputVoltagePhaseA(ele.param_value);
          } else if (ele.param_id === "ups_OutputVoltagePhaseB") {
            setOutputVoltagePhaseB(ele.param_value);
          } else if (ele.param_id === "ups_OutputVoltagePhaseC") {
            setOutputVoltagePhaseC(ele.param_value);
          } else if (ele.param_id === "ups_OutputCurrentPhaseA") {
            setOutputCurrentPhaseA(ele.param_value);
          } else if (ele.param_id === "ups_OutputCurrentPhaseB") {
            setOutputCurrentPhaseB(ele.param_value);
          } else if (ele.param_id === "ups_OutputCurrentPhaseC") {
            setOutputCurrentPhaseC(ele.param_value);
          } else if (ele.param_id === "ups_Temperature") {
            setUpsTemperature(ele.param_value);
          } else if (ele.param_id === "ups_InputFrequency") {
            setUpsInputFrequency(ele.param_value);
          } else if (ele.param_id === "ups_OutputFrequency") {
            setUpsOutputFrequency(ele.param_value);
          }
        });
    });

    api.floor
        .UpsGraphData("8ee50faf-4f7e-11ed-9ab5-9829a659bba7")
        .then((res) => {
         
          // {Object.keys(res.graphData[0]).map((key) => (
          //   // setUpsGraphData(res.graphData[0])
          // setUpsOutputVoltageGraph(res.graphData[0].ups_OutputVoltagePhaseA)
          // setUpsOutputCurrentGraph(res.graphData[0].ups_OutputCurrentPhaseA)

          setUpsOutputVoltageGraph(res.graphData[0]);
          setUpsOutputCurrentGraph(res.graphData[0]);
          //  <TimeSeriesUps data={upsGraphData[key]} param={key} />

          // ))}
    });
    }, 5000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);
  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
        <Grid container spacing={2}>
              <Grid container item xs={12} spacing={1}>
                    <Grid item xs={6}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            style={{ width: "max-content", minWidth: "100%" }}
                          >
                              <InputLabel id="demo-simple-select-outlined-label">
                                UPS
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="UPS"
                                value="UPS1"
                              // onChange={handlefloorchange}
                              >
                                <MenuItem value="UPS1">UPS1</MenuItem>
                                {/* {floor.map(_item => (
                                <MenuItem key={_item.name} value={_item.name}>{_item.name}</MenuItem>
                                ))} */}
                              </Select>
                          </FormControl>
                    </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                    <Grid item xs={9}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={8}>
                                  <Grid container item xs={12} spacing={1}>
                                      <Grid item xs={3}>
                                          <Card
                                            style={{
                                              width: "100%",
                                              height: "14vh",
                                              "border-radius": "10px",
                                            }}
                                            className={classes.paper}
                                            >
                                                  <Grid item xs={12}>
                                                        <Grid container item xs={12}>
                                                            <Grid item xs={12}>
                                                                <div style={{ fontSize: "6vh", marginTop: "1vh" }}>
                                                                     <Danger>17</Danger>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container item xs={12}>
                                                                <Grid item xs={12}>
                                                                       Total Alarms
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                  </Grid>
                                            </Card>
                                      </Grid>
                                      <Grid item xs={3}>
                                          <Card
                                            style={{
                                              width: "100%",
                                              height: "14vh",
                                              "border-radius": "10px",
                                            }}
                                            className={classes.paper}
                                          >
                                              <Grid item xs={12}>
                                                  <Grid item xs={12}>
                                                      <Grid container item xs={12}>
                                                          <Grid item xs={12}>
                                                                Status
                                                          </Grid>
                                                      </Grid>
                                                  </Grid>
                                                  <Grid container item xs={12}>
                                                    <Grid item xs={12}>
                                                        <div
                                                          style={{
                                                            fontSize: "4vh",
                                                            color: "blue",
                                                            marginTop: "2vh",
                                                          }}
                                                        >
                                                             Line
                                                        </div>
                                                    </Grid>
                                                  </Grid>
                                              </Grid>
                                          </Card>
                                      </Grid>
                                      <Grid item xs={6}>
                                          <Card
                                            style={{
                                              width: "100%",
                                              height: "14vh",
                                              "border-radius": "10px",
                                            }}
                                            className={classes.paper}
                                          >
                                              <Grid container item xs={12}>
                                                  <Grid item xs={6}>
                                                      <Grid item xs={12}>
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            className={classes.CardbodyInsideGrid}
                                                          >
                                                              <SemiCircleProgressBar
                                                                strokeWidth={20}
                                                                stroke="#0123B4"
                                                                diameter={130}
                                                                percentage={50}
                                                                showPercentValue
                                                              />
                                                          </Grid>
                                                      </Grid>
                                                      <Grid container item xs={12}>
                                                          <Grid item xs={12}>
                                                               Battery Charge
                                                          </Grid>
                                                      </Grid>
                                                  </Grid>
                                                  <Grid item xs={6}>
                                                      <Card
                                                        style={{
                                                          "box-shadow":
                                                            "inset 0px 0px 0px 2px rgb(76 175 80)",
                                                          "border-radius": "10px",
                                                          height: "12vh",
                                                        }}
                                                      >
                                                            <Grid
                                                              container
                                                              xs={12}
                                                              direction="column"
                                                              style={{
                                                                "justify-content": "center",
                                                                "align-content": "center",
                                                              }}
                                                            >
                                                              <Grid item xs={12}>
                                                                <Grid container item xs={12}>
                                                                  <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ marginTop: "2vh" }}
                                                                  >
                                                                    Battery Voltage
                                                                  </Grid>
                                                                </Grid>
                                                              </Grid>
                                                              <Grid item xs={12}>
                                                                <Grid container item xs={12}>
                                                                  <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{
                                                                      marginTop: "1vh",
                                                                      fontSize: "4vh",
                                                                    }}
                                                                  >
                                                                    <Success>230V</Success>
                                                                  </Grid>
                                                                </Grid>
                                                              </Grid>
                                                            </Grid>
                                                      </Card>
                                                  </Grid>
                                              </Grid>
                                          </Card>
                                      </Grid>
                                  </Grid>
                                  <Grid container item xs={12} spacing={1}>
                                      <Grid item xs={12}>
                                          <Card
                                            style={{
                                              width: "100%",
                                              height: "14vh",
                                              "border-radius": "10px",
                                            }}
                                            className={classes.paper}
                                          >
                                            <Grid container item xs={12} spacing={1}>
                                              <Grid item xs={3}>
                                                <Card
                                                  style={{
                                                    "box-shadow":
                                                      "inset 0px 0px 0px 2px rgb(76 175 80)",
                                                    "border-radius": "10px",
                                                    height: "12vh",
                                                  }}
                                                >
                                                  <Grid
                                                    container
                                                    xs={12}
                                                    direction="column"
                                                    style={{
                                                      "justify-content": "center",
                                                      "align-content": "center",
                                                    }}
                                                  >
                                                    <Grid item xs={12}>
                                                      <Grid container item xs={12}>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          style={{ marginTop: "2vh" }}
                                                        >
                                                          Runtime
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <Grid container item xs={12}>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          style={{
                                                            marginTop: "1vh",
                                                            fontSize: "4vh",
                                                          }}
                                                        >
                                                          <Success>120</Success>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Card>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Grid item xs={12}>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.CardbodyInsideGrid}
                                                  >
                                                    <SemiCircleProgressBar
                                                      strokeWidth={20}
                                                      diameter={130}
                                                      stroke="#0123B4"
                                                      percentage={50}
                                                      showPercentValue
                                                    />
                                                  </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                  <Grid item xs={12} style={{ fontWeight: "500" }}>
                                                    Load
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Card
                                                  style={{
                                                    "box-shadow":
                                                      "inset 0px 0px 0px 2px rgb(76 175 80)",
                                                    "border-radius": "10px",
                                                    height: "12vh",
                                                  }}
                                                >
                                                  <Grid
                                                    container
                                                    xs={12}
                                                    direction="column"
                                                    style={{
                                                      "justify-content": "center",
                                                      "align-content": "center",
                                                    }}
                                                  >
                                                    <Grid item xs={12}>
                                                      <Grid container item xs={12}>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          style={{ marginTop: "2vh" }}
                                                        >
                                                          Power Factor
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <Grid container item xs={12}>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          style={{
                                                            marginTop: "1vh",
                                                            fontSize: "4vh",
                                                          }}
                                                        >
                                                          <Success>0.68</Success>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Card>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Card
                                                  style={{
                                                    "box-shadow":
                                                      "inset 0px 0px 0px 2px rgb(76 175 80)",
                                                    "border-radius": "10px",
                                                    height: "12vh",
                                                  }}
                                                >
                                                  <Grid
                                                    container
                                                    xs={12}
                                                    direction="column"
                                                    style={{
                                                      "justify-content": "center",
                                                      "align-content": "center",
                                                    }}
                                                  >
                                                    <Grid item xs={12}>
                                                      <Grid container item xs={12}>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          style={{ marginTop: "2vh" }}
                                                        >
                                                          Temperature
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <Grid container item xs={12}>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          style={{
                                                            marginTop: "1vh",
                                                            fontSize: "4vh",
                                                          }}
                                                        >
                                                          <Success>{upsTemperature}</Success>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </Card>
                                      </Grid>
                                  </Grid>
                            </Grid>
                            <Grid item xs={4} spacing={1}>
                              <Card
                                style={{
                                  width: "100%",
                                  height: "29vh",
                                  "border-radius": "10px",
                                }}
                                spacing={1}
                                className={classes.paper}
                              >
                                <Grid container item xs={12} spacing={1}>
                                  <Grid item xs={6} style={{ marginTop: "1vh" }}>
                                    <Card
                                      style={{
                                        "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)",
                                        "border-radius": "10px",
                                        height: "12vh",
                                      }}
                                    >
                                      <Grid
                                        container
                                        xs={12}
                                        direction="column"
                                        style={{
                                          "justify-content": "center",
                                          "align-content": "center",
                                        }}
                                      >
                                        <Grid item xs={12}>
                                          <Grid container item xs={12}>
                                            <Grid item xs={12} style={{ marginTop: "2vh" }}>
                                              Input Voltage L1
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Grid container item xs={12}>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ marginTop: "1vh", fontSize: "4vh" }}
                                            >
                                              <Success>{inputVoltagePhaseA}</Success>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                                  <Grid item xs={6} style={{ marginTop: "1vh" }}>
                                    <Card
                                      style={{
                                        "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)",
                                        "border-radius": "10px",
                                        height: "12vh",
                                      }}
                                    >
                                      <Grid
                                        container
                                        xs={12}
                                        direction="column"
                                        style={{
                                          "justify-content": "center",
                                          "align-content": "center",
                                        }}
                                      >
                                        <Grid item xs={12}>
                                          <Grid container item xs={12}>
                                            <Grid item xs={12} style={{ marginTop: "2vh" }}>
                                              Input Voltage L2
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Grid container item xs={12}>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ marginTop: "1vh", fontSize: "4vh" }}
                                            >
                                              <Success>{inputVoltagePhaseB}</Success>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={1}>
                                  <Grid item xs={6} style={{ marginTop: "1vh" }}>
                                    <Card
                                      style={{
                                        "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)",
                                        "border-radius": "10px",
                                        height: "12vh",
                                      }}
                                    >
                                      <Grid
                                        container
                                        xs={12}
                                        direction="column"
                                        style={{
                                          "justify-content": "center",
                                          "align-content": "center",
                                        }}
                                      >
                                        <Grid item xs={12}>
                                          <Grid container item xs={12}>
                                            <Grid item xs={12} style={{ marginTop: "2vh" }}>
                                              Input Voltage L3
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Grid container item xs={12}>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ marginTop: "1vh", fontSize: "4vh" }}
                                            >
                                              <Success>{inputVoltagePhaseC}</Success>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                                  <Grid item xs={6} style={{ marginTop: "1vh" }}>
                                    <Card
                                      style={{
                                        "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)",
                                        "border-radius": "10px",
                                        height: "12vh",
                                      }}
                                    >
                                      <Grid
                                        container
                                        xs={12}
                                        direction="column"
                                        style={{
                                          "justify-content": "center",
                                          "align-content": "center",
                                        }}
                                      >
                                        <Grid item xs={12}>
                                          <Grid container item xs={12}>
                                            <Grid item xs={12} style={{ marginTop: "2vh" }}>
                                              Input Frequency
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Grid container item xs={12}>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ marginTop: "1vh", fontSize: "4vh" }}
                                            >
                                              <Success>{upsInputFrequency}Hz</Success>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={4}>
                            <Card
                              style={{
                                width: "100%",
                                height: "50vh",
                                "border-radius": "10px",
                              }}
                              className={classes.paper}
                            >
                              <img
                                src={Ups}
                                alt="Ups"
                                style={{
                                  width: "36vh",
                                  height: "49vh",
                                }}
                              />
                            </Card>
                          </Grid>
                          <Grid item xs={8}>
                            <Card
                              style={{
                                width: "100%",
                                height: "50vh",
                                "border-radius": "10px",
                              }}
                              className={classes.paper}
                            >
                              <Grid item xs={12}>
                                <Grid container item xs={12} spacing={1}>
                                  <Grid item xs={1.5} style={{ fontWeight: "600" }}>
                                    Ups Output
                                  </Grid>
                                  <Grid
                                    item
                                    xs
                                    style={{ marginLeft: "6vh", color: "#0123b4" }}
                                  >
                                    Load
                                  </Grid>
                                  <Grid
                                    item
                                    xs
                                    style={{ marginLeft: "-2vh", color: "#0123b4" }}
                                  >
                                    Voltage[V]
                                  </Grid>
                                  <Grid
                                    item
                                    xs
                                    style={{ marginLeft: "-3vh", color: "#0123b4" }}
                                  >
                                    Current[A]
                                  </Grid>
                                  <Grid item xs style={{ color: "#0123b4" }}>
                                    Frequency[Hz]
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={2}>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={3}
                                    direction="column"
                                  >
                                    <Grid
                                      item
                                      xs
                                      style={{
                                        marginTop: "11vh",
                                        whiteSpace: "nowrap",
                                        color: "#0123b4",
                                      }}
                                    >
                                      Phase-1
                                    </Grid>
                                    <Grid
                                      item
                                      xs
                                      style={{
                                        marginTop: "8vh",
                                        whiteSpace: "nowrap",
                                        color: "#0123b4",
                                      }}
                                    >
                                      Phase-2
                                    </Grid>
                                    <Grid
                                      item
                                      xs
                                      style={{
                                        marginTop: "7vh",
                                        whiteSpace: "nowrap",
                                        color: "#0123b4",
                                      }}
                                    >
                                      Phase-3
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={10} spacing={3} style={{ marginTop: "3vh" }}>
                                  <Grid container item xs={12} spacing={1}>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid item xs={12}>
                                          <Grid
                                            item
                                            xs={12}
                                            className={classes.CardbodyInsideGrid}
                                          >
                                            <SemiCircleProgressBar
                                              strokeWidth={20}
                                              stroke="#0123B4"
                                              diameter={100}
                                              percentage={50}
                                              showPercentValue
                                            />
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{outputVoltagePhaseA}</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{outputCurrentPhaseA}</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{upsOutputFrequency}Hz</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                  <Grid container item xs={12} spacing={1}>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid item xs={12}>
                                          <Grid
                                            item
                                            xs={12}
                                            className={classes.CardbodyInsideGrid}
                                          >
                                            <SemiCircleProgressBar
                                              strokeWidth={20}
                                              diameter={100}
                                              stroke="#0123B4"
                                              percentage={75}
                                              showPercentValue
                                            />
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{outputVoltagePhaseB}</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{outputCurrentPhaseB}</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{upsOutputFrequency}Hz</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                  <Grid container item xs={12} spacing={1}>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid item xs={12}>
                                          <Grid
                                            item
                                            xs={12}
                                            className={classes.CardbodyInsideGrid}
                                          >
                                            <SemiCircleProgressBar
                                              strokeWidth={20}
                                              diameter={100}
                                              stroke="#0123B4"
                                              percentage={75}
                                              showPercentValue
                                            />
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{outputVoltagePhaseC}</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{outputCurrentPhaseC}</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Paper
                                        className={classes.paper}
                                        style={{ height: "13vh" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={classes.CardbodyInsideGrid}
                                        >
                                          <div
                                            style={{ fontSize: "4vh", marginTop: "1vh" }}
                                          >
                                            <Success>{upsOutputFrequency}Hz</Success>
                                          </div>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container item spacing={2} direction="column">
                        <Grid item xs={4}>
                          <Box
                            boxShadow={3}
                            className={classes.paper}
                            style={{
                              backgroundColor: "white",
                              borderRadius: "20px",
                              height: "25vh",
                              alignContent: "center",
                              width: "34vh",
                            }}
                          >
                            {/* <CardHeader>Load</CardHeader>
                                  <TimeSeriesUpsStatic data={dataSeries}></TimeSeriesUpsStatic> */}
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={12}>
                                Load
                              </Grid>
                              <Grid item xs={12}>
                                <TimeSeriesUpsStatic
                                  data={dataSeries}
                                  style={{ width: "100%", height: "100%" }}
                                ></TimeSeriesUpsStatic>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            boxShadow={3}
                            className={classes.paper}
                            style={{
                              backgroundColor: "white",
                              borderRadius: "20px",
                              height: "25vh",
                              alignContent: "center",
                              width: "34vh",
                            }}
                          >
                            {/* <CardHeader>Output Voltage</CardHeader>
                      {Object.keys(UpsOutputVoltageGraph).map((key) => (
                        <>{key==='ups_OutputVoltagePhaseA'?
                        <TimeSeriesUps data={UpsOutputVoltageGraph[key]} param={key} />
                        :<></>
                      }
                        </>
                          ))} */}
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={12} style={{ fontWeight: "500" }}>
                                Output Voltage
                              </Grid>
                              <Grid item xs={12}>
                                {Object.keys(UpsOutputVoltageGraph).map((key) => (
                                  <>
                                    {key === "ups_OutputVoltagePhaseA" ? (
                                      <TimeSeriesUps
                                        style={{ width: "100%", height: "100%" }}
                                        data={UpsOutputVoltageGraph[key]}
                                        param={key}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            boxShadow={3}
                            className={classes.paper}
                            style={{
                              backgroundColor: "white",
                              borderRadius: "20px",
                              height: "25vh",
                              alignContent: "center",
                              width: "34vh",
                            }}
                          >
                            {/* <CardHeader>Output Current</CardHeader>
                      {Object.keys(UpsOutputCurrentGraph).map((key) => (
                        <>{key==='ups_OutputCurrentPhaseA'?
                        <TimeSeriesUps data={UpsOutputCurrentGraph[key]} param={key} />
                        :<></>
                      }
                        </>                 ))} */}
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={12} style={{ fontWeight: "500" }}>
                                Output Current
                              </Grid>
                              <Grid item xs={12}>
                                {Object.keys(UpsOutputCurrentGraph).map((key) => (
                                  <>
                                    {key === "ups_OutputCurrentPhaseA" ? (
                                      <TimeSeriesUps
                                        style={{ width: "100%", height: "100%" }}
                                        data={UpsOutputCurrentGraph[key]}
                                        param={key}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
              </Grid>
        </Grid>
    </div>
  );
}
