import React from "react";
// @material-ui/core
// import { makeStyles } from "@material-ui/core/styles";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Alerts from './Alerts';
import {useHistory} from 'react-router-dom';
// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

// const useStyles = makeStyles(styles);
export default function AlertsMain() {
    let history = useHistory();
    return (<CustomTabs
        title="Alerts:"
        headerColor="info"
        defaultVal={history.location.pathname.includes("/alerts") ? 0 : 1}
        tabs={[
            {
                tabName: "System Alerts",
                tabIcon: NotificationsIcon, 
                tabContent: (
                    <Alerts type="system" />
                )
            },
            {
                tabName: "Solution Alerts",
                tabIcon: NotificationImportantIcon,
                tabContent: (
                    <Alerts type="solution" />
                )
            }
        ]}
    />);
}
