import React, { Component } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import { Container, Header, Confirm } from "semantic-ui-react";
import ScheduleForm from "./ScheduleForm";
import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/header.min.css';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
// import "./workaround.css";

import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import '../../assets/css/react-big-calendar.sass';

import api from "../../api";
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { Form, Button, Input, Message, Dropdown, Icon } from "semantic-ui-react";
import InlineError from "../../components/Messages/InlineError";
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

const cookies = new Cookies();
let date = require('date-and-time');
const tree = require('../../assets/Data/org-data.json')
// const options = [
//   { key: 'sun', text: 'Sunday', value: 0 },
//   { key: 'mon', text: 'Monday', value: 1 },
//   { key: 'tue', text: 'Tuesday', value: 2 },
//   { key: 'wed', text: 'Wednesday', value: 3 },
//   { key: 'thu', text: 'Thursday', value: 4 },
//   { key: 'fri', text: 'Friday', value: 5 },
//   { key: 'sat', text: 'Saturday', value: 6 },
// ]
// let rweek=[]



class Schedule extends Component {

  state = { 
    modalCreate: false, 
    modalView: false, 
    contextdata: {
      campus: {},
      building: {},
      floors: []
    },
    running:false,
    editrunning:false,
    events: [],
    details: {},
    tree: {},
    openmodal:false,
    errors: {},
    loading:false,
    weeks:[],
    rstart:moment().format('YYYY-MM-DD hh:mm A'),
    rend:moment().format('YYYY-MM-DD hh:mm A'),
    click:true,
    schID:''
  }

  componentDidMount = () => {
    // if(!Object.keys(this.props.tree).length) {
    //   const campusID = "989cf525-10bf-474f-abed-e109ff7af68f";
    //   // this.props.dispatch(execute(campusID, this.processData));
    // } else {
      api.campus.getTreeList(localStorage.getItem("campusID")).then(res => {
        this.setState({
          tree: res
        })
        this.processData()
      })
    // }
  }
  componentWillMount() {
    api.schedule.recurringfetch().then(res => {
     // console.log("res from will mount==========",res)
      let data=JSON.parse(res[0].data)
      let rweek=data.weekData
      // this.setState({selected:rweek})
      this.setState({
        options: [
          { key: 'sun', text: 'Sunday', value: 0 },
          { key: 'mon', text: 'Monday', value: 1 },
          { key: 'tue', text: 'Tuesday', value: 2 },
          { key: 'wed', text: 'Wednesday', value: 3 },
          { key: 'thu', text: 'Thursday', value: 4 },
          { key: 'fri', text: 'Friday', value: 5 },
          { key: 'sat', text: 'Saturday', value: 6 },
        ],
        selected: rweek,
    })
    });
  }

  handleSelect = (event) => {
    if(event.start < new Date()) {
      toast({
          type: 'error',
          icon: 'exclamation triangle',
          title: 'Error',
          description: 'You can create schedule for future time only.',
          time: 3000
      });
      return false;
    }
    if(event.start && event.end)
      this.setState({ modalCreate: true, start: event.start, end: event.end })
    if(new Date(event.end).getHours() === 0 && new Date(event.end).getMinutes() === 0 && new Date(event.end).getSeconds() === 0 ) {
      let eDate = new Date(event.end);
      eDate.setHours(eDate.getHours()+23)
      eDate.setMinutes(eDate.getMinutes()+59)
      event.end = eDate
      this.setState({ modalCreate: true, start: event.start, end: eDate})
    }
    return true
  }

  view = (event) => { 
    this.setState({ modalView: true, details: event });
  }
    confirmOpen= () => this.setState({confirmOpen:true,modalView:false})
  // confirmOpen = () => this.setState({ confirmOpen: false})
  confirmCancel = () => this.setState({ confirmCancel: true })

  handleConfirm = () => {
    const token = jwt_decode(cookies.get('token'))
    const user = {
      id: token.userId,
      name: token.username
    }
    let newDate = new Date(this.state.details.end);
    let startDate = new Date(this.state.details.start)
    let currDate = new Date()
    let diffInMilliSeconds = newDate - currDate;
    let difference = currDate-startDate
    if(difference >= 0){
      this.setState({running:true})
      this.state.running = true
    } else {
      this.setState({running:false})
    }
    if(diffInMilliSeconds <= 180000) {
      this.close()
      toast({
        type: 'error',
        icon: 'exclamation triangle',
        title: 'Error',
        description: "Schedule is about to start / running / already over",
        time: 3000
    });
    } else {
    // remove schedule
    console.log("this.state.details.id",this.state.details.id)
    api.hvac_schedule.delete(this.state.details.id, user,this.state.running).then(() => {
      this.setState({ confirmOpen: false, modalView: false });

      // delete object from event
      let events = this.state.events.filter(res => res.id !== this.state.details.id)
      this.setState({events});
      toast({
          type: 'success',
          icon: 'check circle',
          title: 'Success',
          description: 'Schedule details successfully deleted.',
          time: 3000
      });
    })
    }
  }

  handleCancelConfirm = () => {
    this.setState({ confirmCancel: false, modalView: false});
    let millisecs = new Date(this.state.details.start) - new Date();
    if((millisecs/(1000*60*60)) < 1){
      toast({
        type: 'error',
        icon: 'exclamation triangle',
        title: 'Error',
        description: 'Cancellation should be done 1 hour ahead.',
        time: 2000
    });
    } else {
      console.log("Successfully cancelled")
    }
  }

  handleCancel = () => this.setState({ confirmOpen: false })

  close = () => this.setState({ modalCreate: false, modalView: false })

  processSchedules = (data, sd, ed, mode, callback) => {
    const token = jwt_decode(cookies.get('token'))
    const user = {
      id: token.userId,
      name: token.username
    }
    if(data.action === 0)
      data.intensity = 0;
    if(data.intensityFlag === true)
      data.action = 2;
    data.start = sd;
    data.end = ed;
    if(mode === "create") {
      api.hvac_schedule.create(data, user).then((res) => {
        console.log("create api",res)
        if(typeof callback === 'function')
          callback();
        this.close();
        toast({
            type: 'success',
            icon: 'check circle',
            title: 'Success',
            description: 'Schedule successfully created.',
            time: 3000
        });
  
        // reload map data
        let events = [...this.state.events];
        data.id = res.id;
        data.start = new Date(sd)
        data.end = new Date(ed)
        data.hexColor = this.getColor(data.intensity)
        events.push(data);
        this.setState({events})
      })
      .catch((e) => {
        this.close();
        if(e.response.data.code === 500) {
          toast({
            type: 'error',
            icon: 'exclamation triangle',
            title: 'Error',
            description: e.response.data.message,
            time: 3000
        });
        }
        if (e.response.data.message.includes("ER_DUP_ENTRY")) {
          toast({
              type: 'error',
              icon: 'exclamation triangle',
              title: 'Error',
              description: 'Schedule with this name already exists!!',
              time: 3000
          });
        }
      });
    } else {
      let startDate = new Date(this.state.details.start)
      let currDate = new Date()
      let difference = currDate-startDate
      if(difference >= 0){
        this.setState({editrunning:true})
        this.state.editrunning = true
      } else {
        this.setState({editrunning:false})
      }
      console.log("edit mode", data)
      api.hvac_schedule.edit(this.state.details.id, data, user,this.state.editrunning).then((res) => {
        console.log("edit api",res)
        if(typeof callback === 'function')
          callback();
        this.close();
        toast({
            type: 'success',
            icon: 'check circle',
            title: 'Success',
            description: 'Schedule successfully updated.',
            time: 3000
        });
  
        // reload map data
        let events = [...this.state.events], index;
        events.map((_event,i) => {
          if(_event.id === this.state.details.id) {
            index = i;
          }
          return null;
        });
        events.splice(index,1)
        data.id = res.id;
        data.start = new Date(sd)
        data.end = new Date(ed)
        data.hexColor = this.getColor(data.intensity)
        events.push(data);
        this.setState({events})
      })
      .catch((e) => {
        this.close();
        if(e.response.data.code === 500) {
          toast({
            type: 'error',
            icon: 'exclamation triangle',
            title: 'Error',
            description: e.response.data.message,
            time: 3000
        });
        }
        if (e.response.data.message.includes("ER_DUP_ENTRY")) {
          toast({
              type: 'error',
              icon: 'exclamation triangle',
              title: 'Error',
              description: 'Schedule with this name already exists!!',
              time: 3000
          });
        }
      });
    }
  }
  
  submit = (data, sd, ed, mode, callback) => {
    let newDate = new Date(sd);
    let currDate = new Date()
    let diffInMilliSeconds = newDate - currDate;
    if(diffInMilliSeconds <= 180000) {
      this.close()
      toast({
        type: 'error',
        icon: 'exclamation triangle',
        title: 'Error',
        description: "Schedule is about to start / running / already over",
        time: 3000
    });
    } else {
      this.processSchedules(data, sd, ed, mode, callback)
    }
  }

  processData = () => {
    const data = {
      campus: {},
      building: {},
      floors: []
    };
    let temp = this.state.tree;
    while (!!temp.expanded) {
      if (temp.type === "campus") {
        let campus = {
          name: temp.title,
          id: temp.id
        };
        data.campus = campus;
      } else if (temp.type === "building") {
        let building = {
          name: temp.title,
          id: temp.id
        };
        data.building = building;
        let n = temp.children.length;
        for (let i = 0; i < n; i++) {
          let temp1 = temp.children[i];
          let zones = [];
          temp1.children.map(v => zones.push(
            {
              text: v.title,
              value: v.id,
              key: v.id, 
            }
          ))
          let floor = {
            text: temp1.title,
            value: temp1.id,
            key: temp1.id,
            zones: zones
          };
          data.floors.push(floor);
        }
      }
      temp = temp.children[0];
    }
    this.setState({
      contextdata: data,
    }); 

    this.fetch();
  }

  // get all schedules
  fetch = () => {
    api.hvac_schedule.hvac_fetch().then(res => {
      console.log("fetch api",res)
      let data = res.schedules
      for(let i in data){
        data[i].start = new Date(data[i].start)
        data[i].end = new Date(data[i].end)
        data[i].hexColor = this.getColor(data[i].intensity)
      }
      this.setState({
        events: data
      });
    });
  }

  getColor = (intensity) => {
    if(intensity >= 0 && intensity < 25)
      return '#e9967a';
    if(intensity >= 25 && intensity < 50)
      return '#e4c915';
    if(intensity >= 50 && intensity < 75)
      return '#bacc42';
    if(intensity >= 75 && intensity <= 100)
      return '#3cb14a';
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var style = {
      backgroundColor: event.hexColor,
      borderRadius: '0px',
      opacity: 1,
      color: '#fff',
      display: 'block',
      border: '1px solid #ffffff91'
    };
    return {
      style: style
    };
  }

  onChange=(e,{name,value})=>{
    console.log("eyyyyyyyyyyy",e)
    console.log("name",name)
    console.log("value",value)
    this.setState({weeks:value})
    this.setState({selected:value})
  }

  handledefaultclick=()=>{
    this.setState({openmodal:true})
    api.schedule.recurringfetch().then(res => {
        let data=JSON.parse(res[0].data)
        let d_date='2022-09-14'
        let start = data.timeData[0].ON
        let end = data.timeData[0].OFF
        let r_start=d_date.concat(" ",start)
        let r_end=d_date.concat(" ",end)
        this.setState({rstart:moment(r_start).format('YYYY-MM-DD hh:mm a')})
        this.setState({rend:moment(r_end).format('YYYY-MM-DD hh:mm a')})
        this.setState({schID:res[0].id})
    })
    .catch(err =>{
        console.log("err",err)
    })
  }

  handlecancelclick=()=>{
    this.setState({openmodal:false})
  }

  handlesaveclick=()=>{
    this.setState({openmodal:false})
    this.setState({click:false})
    let start=moment(this.state.rstart).format('HH:mm')
    let end=moment(this.state.rend).format('HH:mm')
    // console.log("weeks choosen",this.state.weeks)
    // console.log("start formatted",start)
    // console.log("end formatted",end)
    let data = {
      "timeData": [{ "ON": start,"OFF": end}],
      "weekData": this.state.weeks
    }
    api.schedule.reccuringupdate(this.state.schID,data).then(res=>{
        console.log("res",res)
        if(res=='Accepted'){
          toast({
            type: 'success',
            icon: 'check circle',
            title: 'Success',
            description: 'Default Schedule successfully updated.',
            time: 3000
        }); 
          setTimeout(() => {
            this.setState({click:true})
          }, 4000);
        }
    }).catch(err =>{
       console.log("err",err)
       toast({
        type: 'error',
        icon: 'exclamation triangle',
        title: 'Error',
        description: err,
        time: 3000
      });
      setTimeout(() => {
        this.setState({click:true})
      }, 4000);
    })
  }

  render() {
    const localizer = momentLocalizer(moment); // or globalizeLocalizer
    const { modalCreate, modalView,errors,start,end,loading,rstart,rend } = this.state
    
    if(localStorage.getItem("roleID") !== "3"){
    return (
      <div>
      <Container>
        {/* <Header
          as='h2'
          content="Schedule"
        /> */}

        {/* {this.state.events.length > 0 ?  */}
        <Button onClick={this.handledefaultclick} positive floated="left" style={{"marginBottom":"5px"}} disabled={this.state.click==false?true:false}>Change Default</Button>
          <Calendar
            selectable
            defaultView={Views.WEEK}
            localizer={localizer}
            views={['month', 'week', 'day']}
            timeslots={2}
            step={15}
            style={{ height: 500,width: '95%' }}
            showMultiDayTimes
            events={this.state.events}
            scrollToTime={new Date(1970, 1, 1, 6)}
            onSelectEvent={this.view}
            onSelectSlot={this.handleSelect}
            eventPropGetter={(this.eventStyleGetter)}
          />
        
        <SemanticToastContainer position="bottom-right" />
        <Dialog open={modalCreate} onClose={this.close} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">AHU Scheduler</DialogTitle>
          <DialogContent>
              <ScheduleForm mode="create" dateFormat={date} start={this.state.start} end={this.state.end} submit={this.submit} close={this.close} contextdata={this.state.contextdata} />
          </DialogContent>
        </Dialog>
        <Dialog open={modalView} onClose={this.close} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">AHU Scheduler</DialogTitle>
          <DialogContent>
            <ScheduleForm mode="edit" delete={this.handleConfirm} dateFormat={date} start={this.state.details.start} end={this.state.details.end} submit={this.submit} close={this.close} details={this.state.details} contextdata={this.state.contextdata}  confirmOpen={this.confirmOpen}/>
          </DialogContent>
        </Dialog>
        {/* create schedule modal */}
        {/* Cancel task */}
        {/* <Confirm
          open={this.state.confirmCancel}
          content='Are you sure you want to cancel the schedule?'
          onCancel={this.handleCancel}
          onConfirm={this.handleCancelConfirm}
        /> */}

        {/* Delete schedule modal */}
        <Confirm
          open={this.state.confirmOpen}
          content='Are you sure you want to delete schedule?'
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
      </Container>
      {this.state.openmodal===true?
        <Dialog open={this.state.openmodal} onClose={this.close} aria-labelledby="form-dialog-title">
          <Form loading={loading} style={{margin:"25px"}}>
            <Form.Group>
                  <Form.Field error={!!errors.date}>
                    <label>From</label>
                    <DateTimePicker
                      date={false}
                      name="start"
                      value={new Date(rstart)}  
                      step={15}
                      onChange={value => this.setState({ rstart: value })}
                    />
                  </Form.Field>
                  <Form.Field error={!!errors.date}>
                    <label>To</label>
                      <DateTimePicker
                        date={false}
                        name="end"
                        value={new Date(rend)}  
                        step={15}
                        onChange={value => this.setState({ rend: value })}
                      />
                  </Form.Field>
            </Form.Group>
                  <Form.Field error={!!errors.floor}>
                    <label>Weeks</label>
                    <Dropdown 
                      name="weeks" 
                      placeholder='Weeks' 
                      fluid 
                      multiple 
                      selection 
                      options={this.state.options} 
                      onChange={this.onChange} 
                      onMouseDown={(e)=>e.preventDefault()}
                      defaultValue={this.state.selected}
                    />
                  </Form.Field>  
            <div>
              <Button positive floated="right" onClick={this.handlesaveclick} style={{"marginBottom":"5px"}}>Save</Button>
              <Button floated="right" onClick={this.handlecancelclick} style={{"marginBottom":"5px"}}>Cancel</Button>
            </div>
          </Form>
        </Dialog>
      :
        <div></div>
      }
      </div>
    );
      } 
  }
}

export default Schedule;