import React, { useEffect, useState,useMemo,useRef,useCallback } from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";
import api from "../../api";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import Typography from "@material-ui/core/Typography";
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';


const device_list = [
    {
      value: 'NONGL_SS_SOLARPUMP',
      label: 'SOLARPUMP',
    }
  ];
const useStyles =makeStyles((theme)=>({
    root: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
        width: "100%",
      },
    text: {
        marginTop: "10vh",
        marginLeft: "34vh",
        fontWeight: "bold",
        fontFamily: "Arial",
        fontSize: "14px",
        whiteSpace:"nowrap"
    },
    input: {
        textalign: "center",
        fontWeight: "bold",
        marginTop: "8.5vh",
        width: "50vh",
        marginLeft: "9vh",
    },
    input1: {
        textalign: "center",
        fontWeight: "bold",
        marginTop: "9.2vh",
        width: "50vh",
        marginLeft: "9vh",
        height:"6vh",
        backgroundColor: "white",
        
       
    },
}))
export default function Vdevice(){
    const classes = useStyles()
    const [name, setDevicename] = React.useState("");
    const [ss_address_value, setTopicid] = React.useState("ASPump");
    const [ss_type, setDeviceType] = React.useState("");
    const [serial_number, setserialnumber] = React.useState("");
    const [model_number, setmodelnumber] = React.useState("");
    const [manufacturing_site, setmanufacturing] = React.useState(""); 
    const [manufacturing_date, setmanufacturing_date] = React.useState('');
    const [formattedDate, setFormattedDate] = React.useState(null);
    const [digital_board_serial_number, setDigitalBoard] = React.useState("");
    const [isFieldValid, setIsFieldValid] = useState(true);
    const [DR_DC_board_serial_number, setDRDCBoard] = React.useState("");
    const [power_board_serial_number, setPowerBoard] = React.useState("");
    const [error, setError] = useState('');
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') };
    const building_id = localStorage.getItem('buildingID')
    const handleChange = (event) => {
        setDeviceType(event.target.value);
      };
      const handleChangeForDevicename = (event) => {
        setDevicename(event.target.value);
    };
    const handleChangeForSerialNumber = (event) => {

        const { value } = event.target;
        if (/^\d*$/.test(value)) {
            setserialnumber(value);
            setError('');
          } else {
            setError('Only numbers are allowed');
          }
    };

   

    const  handleChangeForModelNumber = (event) => {
        setmodelnumber(event.target.value);
    };
    const handleChangeForManufacturing = (event) => {
        setmanufacturing(event.target.value);
    };
    const handleDateChange = (date) => {
        setFormattedDate(date);
        if(date == null){
            console.log("date")
        }else{
            const newFormattedDate = date.toLocaleDateString("en-GB");
            setmanufacturing_date(newFormattedDate);
            setIsFieldValid(true);
        }
       
    };
    
    const handleChangeForDigitalBoard = (event) => {
        setDigitalBoard(event.target.value);
    };
    const handleChangeForDRDC = (event) => {
        setDRDCBoard(event.target.value);
    };
    const handleChangeForPowerlBoard = (event) => {
        setPowerBoard(event.target.value);
    };
    const handleChangeForDeviceTypeId = (event) => {
        setTopicid(event.target.value);
    };

    const validateField = () => {
        if (!formattedDate) {
          setIsFieldValid(false);
        } else {
          setIsFieldValid(true);
        }
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formattedDate) {
            setIsFieldValid(false);
          } else {
        if((ss_address_value.substring(0,6)=="ASPump")){
            const payload = {
                name,
                ss_type,
                ss_address_value,
                building_id,
                serial_number,
                model_number,
                manufacturing_site,
                manufacturing_date,
                digital_board_serial_number,
                DR_DC_board_serial_number,
                power_board_serial_number
            };
            console.log("event",payload,"ss_type",ss_type.length)
            if(ss_type.length == 0){
                toast({
                    type: "error",
                    icon: "exclamation triangle",
                    title: "Error in adding device",
                    description: "Please select Device Type",
                    time: 3000,
                });
            }
            else if(ss_address_value.length!="30")
            {
                toast({
                    type: "error",
                    icon: "exclamation triangle",
                    title: "Error in adding device",
                    description: "Topic Id must be of length 30",
                    time: 3000,
                });
            }
            else{
                api.floor.createDevice(payload).then((res)=>{
                    console.log("api response",res)  
                    if(res == 'ACCEPTED'){
                        toast({
                            type: "success",
                            icon: "check circle",
                            title: "Success",
                            description: "Device added successfully ",
                            time: 3000,
                        });
                    }  else {
                        toast({
                            type: "error",
                            icon: "exclamation triangle",
                            title: "Error in adding device",
                            description: res,
                            time: 3000,
                        });
                    }
                })
            }
            }
            else
            {
                toast({
                    type: "error",
                    icon: "exclamation triangle",
                    title: "Error in adding device",
                    description: "Topic Id must start from 'ASPump' followed by numbers",
                    time: 3000,
                });
            }
        }
     }

    return (
        <div className={classes.root} style={{ marginTop: "0%" }}>
            <Grid container spacing={1}>
                    <ValidatorForm
                        style={{ width: "100%", marginTop: "55" }}
                        onSubmit={handleSubmit}
                        instantValidate={true}
                    >
                        <Grid container item xs={12} spacing={1} direction="column">
                            <Grid item xs={2} >
                                <Grid container item xs={12} spacing={1}>
                                <div 
                                    style={{fontWeight: "bold",
                                            fontFamily: "Arial",
                                            fontSize: "20px",
                                            marginLeft:"66vh",whiteSpace:"nowrap"}}>
                                                    ADD DEVICE
                                </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} >
                                <Grid container item xs={12} spacing={1}>
                                    <Grid item xs={6}>
                                        <Grid container item xs={12} spacing={2} direction="column">
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Device Name</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Device Topic Id</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Device Type</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Serial Number</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Model Number</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Manufacturing Site</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Manufacturing Date</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Digital Board Serial Number</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>DR/DC Board Serial Number</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.text}>Power Board Serial Number</div>
                                        </Grid>
                                    </Grid> 
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Grid container item xs={12} spacing={0} direction="column">
                                        <Grid item xs={3}>
                                            <TextField
                                            required
                                            size="small"
                                            margin="normal"
                                            // fullWidth
                                            // placeholder="Device name"
                                            label="Device Name"
                                            textalign={"center"}
                                            name="Device Name"
                                            value={name}
                                            onChange={handleChangeForDevicename}
                                            // autoComplete="email"
                                            autoFocus
                                            className={classes.input}
                                            variant="outlined"
                                            validators={["required"]}
                                            // errormessages={["field cannot be empty"]}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment>
                                            //             <UsernameIcon />
                                            //         </InputAdornment>
                                            //     ),
                                            // }}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title={<Typography fontSize={15}>Please prefix topic id with 'ASPump'</Typography>} placement="right" style={{height:"6vh"}}arrow>
                                            <TextField
                                            required
                                            size="small"
                                            margin="normal"
                                            // fullWidth
                                            // placeholder="Device name"
                                            // style={{marginTop:"6vh"}}
                                            label="Device Topic Id"
                                            textalign={"center"}
                                            name="Device Topic Id"
                                            value={ss_address_value}
                                            onChange={handleChangeForDeviceTypeId}
                                            // autoComplete="email"
                                            // autoFocus
                                            
                                            className={classes.input}
                                            variant="outlined"
                                            // validators={["required"]}
                                            // errormessages={["field cannot be empty"]}
                                            inputProps={{ maxLength: 30,minLength: 5 }}
                                            // onInput={(e) => onlyNumbers(e) }
                                                    />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                            id="outlined-select-currency"
                                            required
                                            select
                                            label="Device Type"
                                            size="small"
                                            value={ss_type}
                                            className={classes.input}
                                            onChange={handleChange}
                                            // helperText="Device type"
                                            variant="outlined"
                                            // errormessages={["Please input only integer"]}
                                            >
                                            {device_list.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                            required
                                            size="small"
                                            margin="normal"
                                            // fullWidth
                                            // placeholder="Device name"
                                            label="Serial Number"
                                            textalign={"center"}
                                            name="Serial Number"
                                            value={serial_number}
                                            error={!!error}
                                            helperText={error}
                                            onChange={handleChangeForSerialNumber}
                                            // autoComplete="email"
                                            className={classes.input}
                                            variant="outlined"
                                            validators={["required"]}
                                                    />
                                        {/* {serial_number && !isValid && <p style={{ color: "red" ,width:"150vh",marginLeft:"8vh"}} className="error-message">Serial Number should be only numbers</p>} */}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                            required
                                            size="small"
                                            margin="normal"
                                            // fullWidth
                                            // placeholder="Device name"
                                            label="Model Number"
                                            textalign={"center"}
                                            name="Model Number"
                                            value={model_number}
                                            onChange={handleChangeForModelNumber}
                                            // autoComplete="email"
                                            className={classes.input}
                                            variant="outlined"
                                            validators={["required"]}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                            required
                                            size="small"
                                            margin="normal"
                                            // fullWidth
                                            // placeholder="Device name"
                                            label="Manufacturing Site"
                                            textalign={"center"}
                                            name="Manufacturing Site"
                                            value={manufacturing_site}
                                            onChange={handleChangeForManufacturing}
                                            // autoComplete="email"
                                            className={classes.input}
                                            variant="outlined"
                                            validators={["required"]}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <DateTimePicker
                                            required
                                            showLeadingZeros
                                            value={formattedDate}
                                            onChange={handleDateChange}
                                            onBlur={validateField}
                                            format="dd/MM/yyyy"
                                            placeholder="Select a date and time"
                                            className={classes.input1}
                                         />
                                        {!isFieldValid && <p style={{ color: "red" ,width:"150vh",marginLeft:"8vh"}} className="error-message">Please select a date.</p>}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                            required
                                            size="small"
                                            margin="normal"
                                            // fullWidth
                                            // placeholder="Device name"
                                            label="Digital Board Serial Number"
                                            textalign={"center"}
                                            name="Digital Board Serial Number"
                                            value={digital_board_serial_number}
                                            onChange={handleChangeForDigitalBoard}
                                            // autoComplete="email"
                                            className={classes.input1}
                                            variant="outlined"
                                            validators={["required"]}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                            required
                                            size="small"
                                            margin="normal"
                                            // fullWidth
                                            // placeholder="Device name"
                                            label="DR/DC Board Serial Number"
                                            textalign={"center"}
                                            name="DR/DC Board Serial Number"
                                            value={DR_DC_board_serial_number}
                                            onChange={handleChangeForDRDC} 
                                            className={classes.input1}
                                            variant="outlined"
                                            validators={["required"]}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                            required
                                            size="small"
                                            margin="normal"
                                            // fullWidth
                                            // placeholder="Device name"
                                            label="Power Board Serial Number"
                                            textalign={"center"}
                                            name="Power Board Serial Numberr"
                                            value={power_board_serial_number}
                                            onChange={handleChangeForPowerlBoard}
                                            // autoComplete="email"
                                            className={classes.input1}
                                            variant="outlined"
                                            validators={["required"]}
                                                    />
                                        </Grid>
                                    </Grid> 
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} >
                                <Grid container item xs={12} spacing={1}>
                                    <Button
                                            type="submit"
                                            fullWidth
                                            //variant="contained"
                                            color="Vgreen"
                                            style={{width:"40vh",marginLeft:"60vh",marginTop:"5vh"}}
                                            >
                                                Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
            </Grid> 
            <SemanticToastContainer position="top-center" />   
        </div>
    )
}