import React from 'react'

function WarningIcon1() {
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg"  width="20" height="30.354" viewBox="0 0 40 36.354">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_270" data-name="Rectangle 270" width="40" height="36.354" fill="#f2aa1a"/>
    </clipPath>
  </defs>
  <g id="Group_432" data-name="Group 432" clip-path="url(#clip-path)">
    <path id="Path_359" data-name="Path 359" d="M2.55,35.626A5.046,5.046,0,0,1,.67,33.665,5.576,5.576,0,0,1,0,30.959a5.245,5.245,0,0,1,.705-2.645L15.361,2.72A5.089,5.089,0,0,1,17.376.691,5.353,5.353,0,0,1,22.624.684,5.224,5.224,0,0,1,24.653,2.72L39.295,28.3a5.826,5.826,0,0,1,.529,1.323A5.277,5.277,0,0,1,40,30.959a5.607,5.607,0,0,1-.668,2.705,5.07,5.07,0,0,1-1.882,1.961,5.387,5.387,0,0,1-2.83.728H5.395a5.417,5.417,0,0,1-2.844-.728m31.613-2.814a1.9,1.9,0,0,0,1.058-.3,2.063,2.063,0,0,0,.714-.771,2.155,2.155,0,0,0,.257-1.035,2.195,2.195,0,0,0-.249-1.015L21.757,4.778a1.829,1.829,0,0,0-.764-.75,2.184,2.184,0,0,0-.985-.235,2.277,2.277,0,0,0-1,.235,1.746,1.746,0,0,0-.764.734l-14.2,24.9a1.962,1.962,0,0,0-.184.543,2.782,2.782,0,0,0-.052.5,2.151,2.151,0,0,0,.559,1.469,1.864,1.864,0,0,0,1.469.632Zm-15.7-3.984a1.84,1.84,0,0,1-.647-1.441,1.867,1.867,0,0,1,.639-1.455,2.384,2.384,0,0,1,3.094-.007,1.872,1.872,0,0,1,.632,1.462,1.852,1.852,0,0,1-.639,1.441,2.216,2.216,0,0,1-1.535.573,2.248,2.248,0,0,1-1.544-.573m-.118-7.276-.265-8.983A1.742,1.742,0,0,1,18.6,11.24a2.1,2.1,0,0,1,2.786.007,1.7,1.7,0,0,1,.508,1.336l-.264,8.953q-.06,1.647-1.617,1.647a1.488,1.488,0,0,1-1.662-1.631" transform="translate(0 -0.001)" fill="#f2aa1a"/>
  </g>
</svg>
    </div>
  )
}

export default WarningIcon1


