import api from '../../api';
import React from 'react';
// import { id } from 'date-fns/locale';
import Button from "../../components/CustomButtons/Button";
// import { dangerColor } from 'assets/jss/material-dashboard-react';

export default (props) => {
  // const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [flag, setFlag] = React.useState(false);
  console.log("outout",flag)

  const buttonClicked = () => {
  setFlag(true)
    console.log("123456",props.data)
      //  let abc= [props.data.Alarm_Id]
       console.log("props",props.data.Alarm_Id)
       let req ={'id':props.data.Alarm_Id, 'userid':localStorage.userID}
    api.alarms.acknowledge_alarm(req).then(res=>{
      
      console.log("res",res)
      console.log("acknowledged",props.data.Acknowledge)
       
    })
  };

  

  return (
 
<>
{props.data.Acknowledge===0 ?
<>
        
        <Button variant="contained" color={"danger"}  onClick={() => buttonClicked()}>Ack</Button>
</>
      :
      <>
        
        <Button variant="contained" color={"transparent"} onClick={() => buttonClicked()}>Acknowledged</Button>
</>
       }
{/* <Button variant="contained" color={flag ? "transparent" :"danger"} onClick={() => buttonClicked()}>Ack</Button> */}
{console.log("prps.data.ack======================",props.data.Acknowledge)}
</>
    
  );
};
