import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api'
import { Grid, Select, FormControl, MenuItem, InputLabel, Card,ButtonBase } from '@material-ui/core';
import Danger from "components/Typography/Danger";
import Success from 'components/Typography/Success';
import {
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";
import SemiCircleProgressBar from "react-progressbar-semicircle";

const useStyles = makeStyles(theme => ({
  CardHeaderGrid: {
    "display": "flex",
    "align-items": "center",
    "justify-content": "center",
    marginLeft: "-15px"
  },
  CardBodyGrid: {
    "display": "flex",
    "align-items": "center",
    "justify-content": "center",
    "border-radius": "5px",
    padding: "0.6rem 78px",
    flex: "1 1 auto",
    fontSize: "17px",
    WebkitBoxFlex: "1",
    position: "center",
    boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)",
    marginLeft: "10px"
  },
  CardbodyInsideGrid: {
    'justify-content': 'center',
    display: "inline-flex",
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  CardHeader2: {
    "display": "flex",
    "align-items": "center",
    "justify-content": "center",
    marginTop: "-37px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    autosize: true,
    clearable: false
  }


}));

function GlUpsLanding() {
  const classes = useStyles();
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem('floorName'));
  const buildingID = useSelector(state => state.isLogged.data.building.id);

  // const onClickIssue = () => {

  // }

  useEffect(() => {
    api.dashboard.getMetricData(buildingID).then(res => {
      setFloor(res)
    })

  }, [buildingID]);
  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={5}>
            <FormControl variant="outlined" className={classes.formControl} style={{
              width: "max-content",
              minWidth: "100%", marginTop: "-3vh"
            }}>
              <InputLabel id="demo-simple-select-outlined-label">Floor</InputLabel>
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Floor"
                value={fdata}
              // onChange={handlefloorchange}
              >
                {floor.map(_item => (
                  <MenuItem key={_item.name} value={_item.name}>{_item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid container xs={12} spacing={1}>
            <Grid item xs={2}>
              <Card className={classes.paper} style={{ width: "100%", height: "14vh" }}>
                <Grid container item xs={12} direction="column">
                  <Grid item xs={6}>
                    <div style={{ fontSize: "6vh", marginLeft: "7vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>

                      <Danger>17</Danger>
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "5vh", marginTop: "-1vh", fontSize: "2vh" }}>
                    Total Alarm
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={10}>
              <Card className={classes.paper} style={{ width: "100%", height: "14vh" }}>
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <Grid container xs={12} spacing={1}>
                      <Grid item xs={3}>
                        <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                          <Grid container xs={12} direction='column' style={{
                            "justify-content": "center",
                            "align-content": "center"
                          }} >
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "2vh" }}>Battery Voltage</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                  230V
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={3}>
                        <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                          <Grid container xs={12} direction='column' style={{
                            "justify-content": "center",
                            "align-content": "center"
                          }} >
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "2vh" }}>Battery Voltage</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                  230V
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={3}>
                        <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                          <Grid container xs={12} direction='column' style={{
                            "justify-content": "center",
                            "align-content": "center"
                          }} >
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "2vh" }}>Battery Voltage</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                  230V
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={3}>
                        <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                          <Grid container xs={12} direction='column' style={{
                            "justify-content": "center",
                            "align-content": "center"
                          }} >
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "2vh" }}>Battery Voltage</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                  230V
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container xs={12} spacing={1}>
                      <Grid item xs={3}>
                        <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                          <Grid container xs={12} direction='column' style={{
                            "justify-content": "center",
                            "align-content": "center"
                          }} >
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "2vh" }}>Battery Voltage</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                  230V
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={3}>
                        <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                          <Grid container xs={12} direction='column' style={{
                            "justify-content": "center",
                            "align-content": "center"
                          }} >
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "2vh" }}>Battery Voltage</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                  230V
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={3}>
                        <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                          <Grid container xs={12} direction='column' style={{
                            "justify-content": "center",
                            "align-content": "center"
                          }} >
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "2vh" }}>Battery Voltage</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                  230V
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={3}>
                        <Card style={{ "box-shadow": "inset 0px 0px 0px 2px rgb(76 175 80)", "border-radius": "10px", height: "12vh" }}>
                          <Grid container xs={12} direction='column' style={{
                            "justify-content": "center",
                            "align-content": "center"
                          }} >
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "2vh" }}>Battery Voltage</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} style={{ marginTop: "1vh", fontSize: "4vh" }}>
                                  230V
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Card className={classes.paper} style={{ height: "38vh", borderRadius: "12px", backgroundColor: "#ffffff", marginTop: "1vh" }}>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={6}>
                    <Grid container xs={12} direction="column">
                      <Grid item xs={6} style={{ fontSize: "3vh", color: "black" }}>UPS1</Grid>
                      <Grid item xs={6} style={{ fontSize: "2vh", textDecorationLine: "underline", color: "#0123b4" }}>3F-PH1-UPS1</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container xs={12} >
                      <Grid item xs={12}>
                        <ButtonBase style={{ width: "100%", alignItems: "flex-end" }}  >
                          <Card style={{ height: "35px", marginLeft: "27vh", marginTop: "1vh" }}>
                            <Grid container xs={12}>
                              <Grid xs={6}>
                              </Grid>
                            </Grid>
                          </Card>
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6} style={{ fontSize: "2vh", marginLeft: "6vh", color: "black" }}>
                          Alarm
                        </Grid>
                        <Grid item xs={6} style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "-1vh" }}>
                          <Danger>1</Danger>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6} style={{ fontSize: "2vh", marginLeft: "6vh", color: "black" }}>
                          Status
                        </Grid>
                        <Grid item xs={6} style={{ fontSize: "4vh", marginLeft: "5vh", marginTop: "-1vh", color: "blue" }}>
                          Line
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container xs={12} spacing={1} direction="column" >
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{ whiteSpace: "nowrap", color: "black", marginTop: "0vh" }}>
                            Load
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{ marginTop: "-3vh" }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#0123b4" diameter={100} orientation="up" percentage={50} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>
                            45°C
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "3vh", marginTop: "-1vh" }}>
                          UPS Temperature
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container xs={12} spacing={1} direction="column" >
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{ marginTop: "-3vh" }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#f2aa1a" diameter={100} orientation="up" percentage={50} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{ whiteSpace: "nowrap", color: "black", marginTop: "-2vh" }}>
                            Battery Charge
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>
                            23.5
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "6vh", marginTop: "-1vh" }}>
                          Kilo Watt
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card className={classes.paper} style={{ height: "38vh", borderRadius: "12px", backgroundColor: "#ffffff", marginTop: "1vh" }}>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={6}>
                    <Grid container xs={12} direction="column">
                      <Grid item xs={6} style={{ fontSize: "3vh", color: "black" }}>UPS2</Grid>
                      <Grid item xs={6} style={{ fontSize: "2vh", textDecorationLine: "underline", color: "#0123b4" }}>3F-PH1-UPS2</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container xs={12} >
                      <Grid item xs={12}>
                        <ButtonBase style={{ width: "100%", alignItems: "flex-end" }}  >
                          <Card style={{ height: "35px", marginLeft: "27vh", marginTop: "1vh" }}>
                            <Grid container xs={12}>
                              <Grid xs={6}>
                              </Grid>
                            </Grid>
                          </Card>
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6} style={{ fontSize: "2vh", marginLeft: "6vh", color: "black" }}>
                          Alarm
                        </Grid>
                        <Grid item xs={6} style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "-1vh" }}>
                          <Danger>3</Danger>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6} style={{ fontSize: "2vh", marginLeft: "6vh", color: "black" }}>
                          Status
                        </Grid>
                        <Grid item xs={6} style={{ fontSize: "4vh", marginLeft: "5vh", marginTop: "-1vh", color: "blue" }}>
                          Line
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container xs={12} spacing={1} direction="column" >
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{ whiteSpace: "nowrap", color: "black", marginTop: "0vh" }}>
                            Load
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{ marginTop: "-3vh" }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#0123b4" diameter={100} orientation="up" percentage={50} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>
                            45°C
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "3vh", marginTop: "-1vh" }}>
                          UPS Temperature
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container xs={12} spacing={1} direction="column" >
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{ marginTop: "-3vh" }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#FF0000" diameter={100} orientation="up" percentage={25} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{ whiteSpace: "nowrap", color: "black", marginTop: "-2vh" }}>
                            Battery Charge
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>
                            23.5
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "6vh", marginTop: "-1vh" }}>
                          Kilo Watt
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card className={classes.paper} style={{ height: "38vh", borderRadius: "12px", backgroundColor: "#ffffff", marginTop: "1vh" }}>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={6}>
                    <Grid container xs={12} direction="column">
                      <Grid item xs={6} style={{ fontSize: "3vh", color: "black" }}>UPS3</Grid>
                      <Grid item xs={6} style={{ fontSize: "2vh", textDecorationLine: "underline", color: "#0123b4" }}>3F-PH1-UPS3</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container xs={12} >
                      <Grid item xs={12}>
                        <ButtonBase style={{ width: "100%", alignItems: "flex-end" }}  >
                          <Card style={{ height: "35px", marginLeft: "27vh", marginTop: "1vh" }}>
                            <Grid container xs={12}>
                              <Grid xs={6}>
                              </Grid>
                            </Grid>
                          </Card>
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6} style={{ fontSize: "2vh", marginLeft: "6vh", color: "black" }}>
                          Alarm
                        </Grid>
                        <Grid item xs={6} style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "-1vh" }}>
                          <Danger>1</Danger>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6} style={{ fontSize: "2vh", marginLeft: "6vh", color: "black" }}>
                          Status
                        </Grid>
                        <Grid item xs={6} style={{ fontSize: "4vh", marginLeft: "5vh", marginTop: "-1vh", color: "blue" }}>
                          Line
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container xs={12} spacing={1} direction="column" >
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{ whiteSpace: "nowrap", color: "black", marginTop: "0vh" }}>
                            Load
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{ marginTop: "-3vh" }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#0123b4" diameter={100} orientation="up" percentage={50} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>
                            45°C
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "3vh", marginTop: "-1vh" }}>
                          UPS Temperature
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container xs={12} spacing={1} direction="column" >
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{ marginTop: "-3vh" }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#f2aa1a" diameter={100} orientation="up" percentage={50} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{ whiteSpace: "nowrap", color: "black", marginTop: "-2vh" }}>
                            Battery Charge
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>
                            23.5
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "6vh", marginTop: "-1vh" }}>
                          Kilo Watt
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card className={classes.paper} style={{ height: "38vh", borderRadius: "12px", backgroundColor: "#ffffff", marginTop: "1vh" }}>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={6}>
                    <Grid container xs={12} direction="column">
                      <Grid item xs={6} style={{ fontSize: "3vh", color: "black" }}>UPS4</Grid>
                      <Grid item xs={6} style={{ fontSize: "2vh", textDecorationLine: "underline", color: "#0123b4" }}>3F-PH1-UPS4</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container xs={12} >
                      <Grid item xs={12}>
                        <ButtonBase style={{ width: "100%", alignItems: "flex-end" }}  >
                          <Card style={{ height: "35px", marginLeft: "27vh", marginTop: "1vh" }}>
                            <Grid container xs={12}>
                              <Grid xs={6}>
                              </Grid>
                            </Grid>

                          </Card>
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6} style={{ fontSize: "2vh", marginLeft: "6vh", color: "black" }}>
                          Alarm
                        </Grid>
                        <Grid item xs={6} style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "-1vh" }}>
                          <Success>1</Success>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6} style={{ fontSize: "2vh", marginLeft: "6vh", color: "black" }}>
                          Status
                        </Grid>
                        <Grid item xs={6} style={{ fontSize: "4vh", marginLeft: "5vh", marginTop: "-1vh", color: "blue" }}>
                          Line
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container xs={12} spacing={1} direction="column" >
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{ whiteSpace: "nowrap", color: "black", marginTop: "0vh" }}>
                            Load
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{ marginTop: "-3vh" }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#0123b4" diameter={100} orientation="up" percentage={50} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>
                            45°C
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "3vh", marginTop: "-1vh" }}>
                          UPS Temperature
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container xs={12} spacing={1} direction="column" >
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.CardbodyInsideGrid}>
                            <div style={{ marginTop: "-3vh" }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#f2aa1a" diameter={100} orientation="up" percentage={50} showPercentValue />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} style={{ whiteSpace: "nowrap", color: "black", marginTop: "-2vh" }}>
                            Battery Charge
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card className={classes.paper} style={{ height: "13vh" }}>
                      <Grid container item xs={12} direction="column" spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "4vh", marginLeft: "6vh", marginTop: "2vh", color: "#0123b4", fontWeight: "bold" }}>
                            23.5
                          </div>
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: "nowrap", marginLeft: "6vh", marginTop: "-1vh" }}>
                          Kilo Watt
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default GlUpsLanding




