import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import TimeSeriesChart from "../TimeSeriesChart.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Chart from "react-apexcharts";
import SwitchSelector from "react-switch-selector";
import { CalendarToday } from "@material-ui/icons";
import CardFooter from "components/Card/CardFooter";
import { ButtonBase } from "@material-ui/core";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import { TextField, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody";
import { Map, ImageOverlay, Marker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../assets/css/leaflet.css";
import WarningIcon1 from "../../assets/img/Warning1";
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
const Leaflet = require("leaflet");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  setptbutton: {
    minWidth: "8vh",
    borderRadius: "8px",
    height: "3vh",
    marginTop: "1vh",
    marginLeft: "-4vh",
  },
  control1: {
    width: "6vh",
    marginTop: "0vh",
    marginLeft: "-11vh",
  },
  text: {
    fontSize: "14px",
    color: " #292929",
  },
}));

export default function NestedGrid(props) {
  const classes = useStyles()
  const [setpt, set_setpt] = React.useState("");
  const [ahudevice, setAhudevice] = useState([]);
  const [floor, setFloor] = useState([]);
  const initialState = props.location.state != null ? props.location.state.name : "";
  const [data, setData] = useState(initialState);
  const initialState1 = props.location.state != null ? props.location.state.data : '';
  const [deviceid, setDeviceid] = useState(initialState1);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const zone_data = useSelector((state) => state.inDashboard.locationData);
  const [graph, setGraph] = useState([]);
  const [v1com, setv1Com] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);
  const [value5, setValue5] = useState([]);
  const [value7, setValue7] = useState(0);
  const [value8, setValue8] = useState(0);
  const [v8com, setv8Com] = useState(0);
  const [value9, setValue9] = useState(0);
  const [v9com, setv9Com] = useState(0);
  const [setSat, setSAtvalue] = useState("");
  const [onOff, setOnOff] = useState('');
  const [ahu1, setAhu1] = useState({});
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');
  const iconDevice1 = new Leaflet.Icon({
    iconUrl: require("../../assets/img/sensor-icon.png"),
    iconSize: new Leaflet.Point(0, 0),
    className: "leaflet-div-icon-2",
  });
  const iconDevice5 = new Leaflet.Icon({
    iconUrl: require("../../assets/img/fan_still.gif"),
    iconSize: [50, 57],
    className: "leaflet-div-icon-2",
  });

  const iconDevice2 = new Leaflet.Icon({
    iconUrl: require("../../assets/img/fan_rotating.gif"),
    iconSize: new Leaflet.Point(50, 43),
    className: "leaflet-div-icon-2",
  });

  const iconDevice3 = new Leaflet.Icon({
    iconUrl: require("../../assets/img/arrow.gif"),
    iconSize: new Leaflet.Point(25, 25),
    className: "leaflet-div-icon-2",
  });

  const iconDevice4 = new Leaflet.Icon({
    iconUrl: require("../../assets/img/down_arrow.gif"),
    iconSize: new Leaflet.Point(25, 25),
    className: "leaflet-div-icon-2",
  });

  useEffect(() => {
    console.log("useeffect calledddddddd")
    let zone_id=''
    zone_data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
    zone_data.filter((_each)=>_each.zone_type==='GL_LOCATION_TYPE_FLOOR')
    if(fdata!== null){
      zone_data.filter((_each,i) =>{
        if(_each.zone_type==='GL_LOCATION_TYPE_FLOOR'&& _each.name===fdata){
           return zone_id=_each.uuid
        }
      })
    } else {
      zone_id=zone_data[0].uuid
      setFdata(zone_data[0].name)
    }
    api.floor.devicemap(zone_id, "AHU")
      .then((res) => {
        setAhudevice(res);
        if(deviceid=='' && data==''){
          res.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
          setDeviceid(res[0].ssid)
          setData(res[0].name)
          api.floor.getAhuLastHr(res[0].ssid).then((res) => {
            console.log("deviceidddd get ahulasthr",deviceid)
            setAhu1(res.graphData[0]);
          }).catch((error)=>{
              console.log("deviceidddd get ahulasthr catch block",deviceid)
              setOpenerr(true)
              setErrmsg(error.response.data.message)
          })
          api.floor.getAhu(res[0].ssid).then((res) => {
            console.log("deviceidddd getahu",deviceid)
            res.current_sp.map((ele) => {
              if (ele.param_id === "ahu_in_air_temperature_sp") {
                setValue8(parseFloat(ele.param_value));
                setv1Com(ele.param_value);
              }
              if (ele.param_id === "ahu_chilled_valve_sp") {
                setValue8(parseFloat(ele.param_value));
                setv8Com(ele.param_value);
              }
              if (ele.param_id === "ahu_suply_air_temperature_sp") {
                setValue9(parseFloat(ele.param_value));
                setv9Com(ele.param_value);
              }
              return ele;
            });
            res.current.map((ele) => {
              if (ele.param_id === "Supply Fan Status") {
                setOnOff(ele.param_value);
                setValue4(ele.param_value);
              }
              if (ele.param_id === "VFD Bypass Status") {
                setValue5(ele.param_value);
              }
              if (ele.param_id === "Chilled Water Valve Feedback") {
                setValue3(ele.param_value);
              }
              if (ele.param_id === "Return Air Temperature") {
                setValue2(ele.param_value);
              }
              if (ele.param_id === "Supply Air Temperature") {
                setValue7(ele.param_value);
              }
              return ele;
            });
            setGraph(res.current);
          }).catch((error)=>{
            console.log("deviceidddd getahu catch block",deviceid)
            setOpenerr(true)
            setErrmsg(error.response.data.message)
          })
        } else{
          api.floor.getAhuLastHr(deviceid).then((res) => {
            console.log("deviceidddd get ahulasthr",deviceid)
            setAhu1(res.graphData[0]);
          }).catch((error)=>{
              console.log("deviceidddd get ahulasthr catch block",deviceid)
              setOpenerr(true)
              setErrmsg(error.response.data.message)
          })
          api.floor.getAhu(deviceid).then((res) => {
            console.log("deviceidddd getahu",deviceid)
            res.current_sp.map((ele) => {
              if (ele.param_id === "ahu_in_air_temperature_sp") {
                setValue8(parseFloat(ele.param_value));
                setv1Com(ele.param_value);
              }
              if (ele.param_id === "ahu_chilled_valve_sp") {
                setValue8(parseFloat(ele.param_value));
                setv8Com(ele.param_value);
              }
              if (ele.param_id === "ahu_suply_air_temperature_sp") {
                setValue9(parseFloat(ele.param_value));
                setv9Com(ele.param_value);
              }
              return ele;
            });
            res.current.map((ele) => {
              if (ele.param_id === "Supply Fan Status") {
                setOnOff(ele.param_value);
                setValue4(ele.param_value);
              }
              if (ele.param_id === "VFD Bypass Status") {
                setValue5(ele.param_value);
              }
              if (ele.param_id === "Chilled Water Valve Feedback") {
                setValue3(ele.param_value);
              }
              if (ele.param_id === "Return Air Temperature") {
                setValue2(ele.param_value);
              }
              if (ele.param_id === "Supply Air Temperature") {
                setValue7(ele.param_value);
              }
              return ele;
            });
            setGraph(res.current);
          }).catch((error)=>{
            console.log("deviceidddd getahu catch block",deviceid)
            setOpenerr(true)
            setErrmsg(error.response.data.message)
          })
        }
      }).catch((error) =>{
        setOpenerr(true)
        setErrmsg(error.response.data.message)
      })
      api.dashboard.getMetricData(buildingID).then((res) => {
        res.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        setFloor(res);
      }).catch((error)=>{
        setOpenerr(true)
        setErrmsg(error.response.data.message)
      })
    // api.floor.getAhuLastHr(deviceid).then((res) => {
    //   console.log("deviceidddd get ahulasthr",deviceid)
    //   setAhu1(res.graphData[0]);
    // }).catch((error)=>{
    //     console.log("deviceidddd get ahulasthr catch block",deviceid)
    //     setOpenerr(true)
    //     setErrmsg(error.response.data.message)
    //   })
      
    //   api.floor.getAhu(deviceid).then((res) => {
    //     console.log("deviceidddd getahu",deviceid)
    //     res.current_sp.map((ele) => {
    //       if (ele.param_id === "ahu_in_air_temperature_sp") {
    //         setValue8(parseFloat(ele.param_value));
    //         setv1Com(ele.param_value);
    //       }
    //       if (ele.param_id === "ahu_chilled_valve_sp") {
    //         setValue8(parseFloat(ele.param_value));
    //         setv8Com(ele.param_value);
    //       }
    //       if (ele.param_id === "ahu_suply_air_temperature_sp") {
    //         setValue9(parseFloat(ele.param_value));
    //         setv9Com(ele.param_value);
    //       }
    //       return ele;
    //     });
    //     res.current.map((ele) => {
    //       if (ele.param_id === "Supply Fan Status") {
    //         setOnOff(ele.param_value);
    //         setValue4(ele.param_value);
    //       }
    //       if (ele.param_id === "VFD Bypass Status") {
    //         setValue5(ele.param_value);
    //       }
    //       if (ele.param_id === "Chilled Water Valve Feedback") {
    //         setValue3(ele.param_value);
    //       }
    //       if (ele.param_id === "Return Air Temperature") {
    //         setValue2(ele.param_value);
    //       }
    //       if (ele.param_id === "Supply Air Temperature") {
    //         setValue7(ele.param_value);
    //       }
    //       return ele;
    //     });
    //     setGraph(res.current);
    //   }).catch((error)=>{
    //     console.log("deviceidddd getahu catch block",deviceid)
    //     setOpenerr(true)
    //     setErrmsg(error.response.data.message)
    //   })
    
    const timer = setInterval(() => {
      api.floor.getAhuLastHr(deviceid).then((res) => {
        setAhu1(res.graphData[0]);
      }).catch((error)=>{
        setOpenerr(true)
        setErrmsg(error.response.data.message)
      })

      api.floor.getAhu(deviceid).then((res) => {
        res.current_sp.map((ele) => {
          if (ele.param_id === "ahu_in_air_temperature_sp") {
            setValue8(parseFloat(ele.param_value));
            setv1Com(ele.param_value);
          }
          if (ele.param_id === "ahu_chilled_valve_sp") {
            setv8Com(ele.param_value);
          }
          if (ele.param_id === "ahu_suply_air_temperature_sp") {
            setValue9(parseFloat(ele.param_value));
            setv9Com(ele.param_value);
          }
          return ele;
        });
        res.current.map((ele) => {
          if (ele.param_id === "Supply Fan Status") {
            setValue4(ele.param_value);
          }
          if (ele.param_id === "VFD Bypass Status") {
            setValue5(ele.param_value);
          }
          if (ele.param_id === "Chilled Water Valve Feedback") {
            setValue3(ele.param_value);
          }
          if (ele.param_id === "Return Air Temperature") {
            setValue2(ele.param_value);
          }
          if (ele.param_id === "Supply Air Temperature") {
            setValue7(ele.param_value);
          }
          return ele;
        });
        setGraph(res.current);
      }).catch((error)=>{
        setOpenerr(true)
        setErrmsg(error.response.data.message)
      })
    }, 10000);
    return () => clearInterval(timer);
    //eslint-disable-next-line
  }, [buildingID, initialState1,deviceid]);

  const onclickchart = () => {
    props.history.push({
      pathname: `/admin/glVav`,
    });
  };
  const handleerrorclose = () => {
    setOpenerr(false);
    setErrmsg('');
  };
  const state1 = {
    options: {
      chart: {
        id: "basic-bar",
        color: "green",
        events: {
          dataPointSelection: function (event, chartContext, config) {
            onclickchart();
          },
        },
      },
      xaxis: {
        categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      stroke: {
        show: true,
        strokeWidth: 2,
        strokeDashArray: 2,
        strokeColor: "green",
      },
    },

    series: [
      {
        name: "series-1",
        data: [20, 30, 25, 30, 55, 40, 12, 22, 34, 32],
      },
    ],
  };
  const handleChangeForsetpoint = (event) => {
    set_setpt(event.target.value);
  };
  const handleClick = (event) => {
    const req = {
      param_id: "ahu_in_air_temperature_sp",
      param_value: setpt,
      user_id: localStorage.userID,
    };

    if (setpt >= 15 && setpt <= 35) {
      api.floor
        .UpdateConfigureSetpoints(deviceid, req)
        .then((res) => {
          set_setpt("");
          if (res.message === "please connect to network") {
            toast({
              type: "error",
              icon: "exclamation triangle",
              title: "Error",
              description: "connect to network",
              time: 3000,
            });
          } else if (res.message === "ACCEPTED") {
            toast({
              type: "success",
              icon: "check circle",
              title: "Success",
              description: "Rat successfully setted" + setpt,
              time: 3000,
            });
          }
        })
        .catch((error) => {
          setOpenerr(true)
          setErrmsg(error.response.data.message)
        });
    } else {
      set_setpt("");
      toast({
        type: "error",
        icon: "exclamation triangle",
        title: "Error",
        description: "RAT sp should be 15-35 ",
        time: 0,
      });
    }
  };

  const handleChangeForsetpointSAT = (event) => {
    setSAtvalue(event.target.value);
  };
  const handleClickSat = (event) => {
    const req = {
      param_id: "ahu_suply_air_temperature_sp",
      param_value: setSat,
      user_id: localStorage.userID,
    };
    if (setSat >= 15 && setSat <= 35) {
      api.floor
        .UpdateConfigureSetpoints(deviceid, req)
        .then((res) => {
          setSAtvalue("");
          if (res.message === "please connect to network") {
            toast({
              type: "error",
              icon: "exclamation triangle",
              title: "Error",
              description: "connect to network",
              time: 0,
            });
          } else if (res.message === "ACCEPTED") {
            toast({
              type: "success",
              icon: "check circle",
              title: "Success",
              description: "Sat successfully setted" + setSat,
              time: 3000,
            });
          }
        })
        .catch((error) => {
          setOpenerr(true)
          setErrmsg(error.response.data.message)
        });
    } else {
      setSAtvalue("");
      toast({
        type: "error",
        icon: "exclamation triangle",
        title: "Error",
        description: "SAT sp should be 15-35 ",
        time: 0,
      });
    }
  };

  const options = [
    {
      selectedFontColor: "white",
      label: "OFF",
      value: 0,
      selectedBackgroundColor: "red",
    },
    {
      selectedFontColor: "white",
      label: "ON",
      value: 1,
      selectedBackgroundColor: "green",
    },
  ];
  const onChange = (newValue) => {
    const msg = newValue === 1 ? "On" : "Off";
    const va = newValue === 1 ? 1 : 0;
    setOnOff(va);
    const req = {
      param_id: "ahu_on_off_sp",
      param_value: newValue === 1 ? 10 : 0,
      user_id: localStorage.userID,
    };
    api.floor.UpdateConfigureSetpoints(deviceid, req).then((res) => {
      if (res.message === "please connect to network") {
        setOnOff(value4);
        toast({
          type: "error",
          icon: "exclamation triangle",
          title: "Error",
          description: "connect to network",
          time: 3000,
        });
      } else if (res.message === "ACCEPTED") {
        setOnOff(va);
        toast({
          type: "success",
          icon: "check circle",
          title: "Success",
          description: "successfully turned" + msg,
          time: 3000,
        });
      }
    }).catch((error)=>{
      setOpenerr(true)
      setErrmsg(error.response.data.message)
    })
  };
  const handlefloorchange = (name, id) => {
    setFdata(name);
    api.floor.devicemap(id, "AHU").then((res) => {
      setAhudevice(res);
    }).catch((error)=>{
      setOpenerr(true)
      setErrmsg(error.response.data.message)
    })
  };
  const handleChange = (name, id) => {
    setDeviceid(id);
    setData(name);
    api.floor.getAhuLastHr(id).then((res) => {
      setAhu1(res.graphData[0]);
    }).catch((error)=>{
      setOpenerr(true)
      setErrmsg(error.response.data.message)
    })
    api.floor.getAhu(id).then((res) => {
      setGraph(res.current);
    }).catch((error)=>{
      setOpenerr(true)
      setErrmsg(error.response.data.message)
    })
  };
  let device_data = [];
  graph.filter((el) => {
    if (
      el.param_id === "ahu_on_off" ||
      el.param_id === "ahu_supply_air_temperature" ||
      el.param_id === "out_air_valve" ||
      el.param_id === "ahu_chilled_valve" ||
      el.param_id === "static_pressure" ||
      el.param_id === "fan_motor_speed" ||
      el.param_id === "ahu_supply_air_temperature"
    ) {
      device_data.push(el);
    }
    return el;
  });
 
  const handleLocationClick = (name) => {
    props.history.push(`/admin/Glschedule`);
  };
  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Snackbar open={openerr} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert style={{ cursor: "pointer" }} severity="error" variant="filled" onClose={handleerrorclose}>
          {errmsg}
        </Alert>
      </Snackbar>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={5}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "max-content", minWidth: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Floor
              </InputLabel>
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Floor"
                value={fdata || ''}
                // onChange={handlefloorchange}
              >
                {floor.map((_item) => (
                  <MenuItem
                    key={_item.id}
                    value={_item.name}
                    onClick={() => handlefloorchange(_item.name, _item.id)}
                  >
                    {_item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "max-content", minWidth: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Device
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Device"
                value={data}
              >
                {ahudevice.map((_item) => (
                  <MenuItem
                    key={_item.ssid}
                    value={_item.name}
                    onClick={() => handleChange(_item.name, _item.ssid)}
                  >
                    {_item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Paper
              className={classes.paper}
              style={{
                backgroundColor: " #0123b4",
                color: "white",
                borderRadius: "12px",
                height: "100%",
                textAlign: "left",
                fontWeight: "500",
              }}
            >
              <div
                style={{
                  marginTop: "12px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#ffffff",
                }}
              >
                AHU Controls
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={9}>
            <Box
              boxShadow={3}
              className={classes.paper}
              style={{
                backgroundColor: "white",
                borderRadius: "14px",
                height: "56vh",
              }}
            >
              <Map
                //  ref={mapRef}
                attributionControl={false}
                doubleClickZoom={false}
                zoomControl={false}
                dragging={false}
                scrollWheelZoom={false}
                // className={"floor-map"}
                crs={Leaflet.CRS.Simple}
                center={[0, 0]}
                // bounds={[[0, 0], [950, 800]]}
                bounds={[
                  [0, 0],
                  [414, 843],
                ]}
                className={classes.bounds}
                style={{
                  width: "max-content",
                  minWidth: "100%",
                  height: "54vh",
                  backgroundColor: "white",
                }}
                onClick={(e) => {
                  // console.log(e)
                  console.log({ x: e.latlng.lat, y: e.latlng.lng });
                }}
              >
                <h3 style={{textAlign:'end',textDecorationLine:"underline",marginTop:"0%"}}>{data}</h3>
                <ImageOverlay
                  interactive
                  url={"https://localhost/AHU_Graphic.png"}
                  // url={'/AHU_Graphic.png'}
                  // bounds={[[0, 0], [414, 670]]}
                  // bounds={[[0, 70], [405, 790]]}
                  bounds={[
                    [-10, 60],
                    [405, 760],
                  ]}
                />
                {onOff == 0 ? (
                  <Marker position={[120.921875, 589]} icon={iconDevice5} />
                ) : (
                  <>
                    <Marker position={[120.921875, 589]} icon={iconDevice2} />

                    <Marker position={[285.89, 558]} icon={iconDevice3} />

                    <Marker position={[323.89, 338]} icon={iconDevice3} />
                    <Marker position={[341.89, 210]} icon={iconDevice3} />
                    <Marker position={[308.89, 208]} icon={iconDevice4} />
                    <Marker position={[205.89, 214]} icon={iconDevice4} />
                  </>
                )}

                <Marker position={[310.921, 516]} icon={iconDevice1}>
                  {/* fms>=0&&fms<=100 */}
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        RAT
                      </span>
                      <br />

                      {value2 >= parseFloat(v1com) - 1 &&
                      value2 <= parseFloat(v1com) + 1 ? (
                        <div
                          style={{
                            backgroundColor: "#1fd655",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value2 * 1) / 1 + "℃"}
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#b90e0a",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value2 * 1) / 1 + "℃"}
                        </div>
                      )}
                    </div>{" "}
                  </Tooltip>
                </Marker>

                <Marker position={[136, 700]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        SAT
                      </span>
                      <br />
                      {value7 >= parseFloat(v9com) - 1 &&
                      value7 <= parseFloat(v9com) + 1 ? (
                        <div
                          style={{
                            backgroundColor: "#1fd655",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value7 * 1) / 1 + "℃"}
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#b90e0a",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value7 * 1) / 1 + "℃"}
                        </div>
                      )}
                    </div>{" "}
                  </Tooltip>
                </Marker>

                <Marker position={[160.921, 203]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        Chw valve{" "}
                      </span>
                      <br />
                      {value3 >= parseFloat(v8com) - 1 &&
                      value3 <= parseFloat(v8com) + 1 ? (
                        <div
                          style={{
                            backgroundColor: "#1fd655",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value3 * 1) / 1 + "%"}
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#b90e0a",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value3 * 1) / 1 + "%"}
                        </div>
                      )}
                    </div>{" "}
                  </Tooltip>
                </Marker>
                <Marker
                  position={[114.9, 393]}
                  // onclick={() =>
                  //   handleDeviceClick(value8)}
                  icon={iconDevice1}
                >
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        supply fan Status
                      </span>
                      <br />
                      {value4 >= 0 && value4 <= 100 ? (
                        <div
                          style={{
                            backgroundColor: "#1fd655",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {value4 === 1 ? "ON" : "OFF"}
                        </div>
                      ) : value4 < 0 ? (
                        <div
                          style={{
                            backgroundColor: "yellow",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value4 * 1) / 1}
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "18px",
                            width: "59px",
                            backgroundColor: "#b90e0a",
                          }}
                        >
                          <WarningIcon1 />
                        </div>
                      )}
                    </div>{" "}
                  </Tooltip>
                </Marker>

                <Marker position={[114.9, 543]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        VFD Bypass Status
                      </span>
                      <br />
                      {value5 >= 0 && value5 <= 10 ? (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value5 * 1) / 1 + "V"}
                        </div>
                      ) : value5 < 0 ? (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          {Math.round(value5 * 1) / 1 + "V"}
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                          }}
                        >
                          <WarningIcon1 />
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </Marker>

                <Marker position={[280.75, 672]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        Fire Alarm
                      </span>
                      <br />
                      {value5 >= 0 && value5 <= 10 ? (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          NO
                        </div>
                      ) : value5 < 0 ? (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          NO
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                          }}
                        >
                          NO
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[216.75, 125]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        CO2
                      </span>
                      <br />
                      {value5 >= 0 && value5 <= 10 ? (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          800ppm
                        </div>
                      ) : value5 < 0 ? (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          800ppm
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                          }}
                        >
                          800ppm
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[198.3, 317]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        DPS(Filter)
                      </span>
                      <br />
                      {value5 >= 0 && value5 <= 10 ? (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          20pa
                        </div>
                      ) : value5 < 0 ? (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                            color: " #ffffff",
                            fontWeight: "500",
                            fontSize: "15px",
                            "letter-spacing": "9px",
                          }}
                        >
                          20pa
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "grey",
                            height: "18px",
                            width: "59px",
                          }}
                        >
                          20pa
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </Marker>

                <Marker position={[204.921, 140]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Fresh air damper
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        50%
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[308.921, 448]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        RA damper
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        50%
                      </div>
                    </div>
                  </Tooltip>
                </Marker>

                <Marker position={[99.9, 642]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        SA damper
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        70%
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[192.9, 651]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        AHU on/off
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        ON
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[143.9, 731]} icon={iconDevice1}>
                  <Tooltip direction="right" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Air flow status
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        -
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[104.9, 616]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Trip status
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        OFF
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[115.92, 517]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Auto/Manual status
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        Auto
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[137.921, 283]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Filter status
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        Clean
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[111.9, 685]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Duct static pressure
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        300Pa
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                {/* <Marker
                    position={[220.921,430]}
                    icon={iconDevice1}
                  >

                    <Tooltip direction="top" opacity={1} permanent>
                      <div>
                        <span style={{ fontWeight: "500",whiteSpace:"normal" }}>Chilled water Valve</span><br />
                        {((value5 >= 0) && (value5 <= 10)) ? <div style={{ backgroundColor: "grey", height: "18px", width: "59px", color: " #ffffff", fontWeight: "500", fontSize: "15px","letter-spacing":"9px" }}>30%</div> : (value5 < 0) ? <div style={{ backgroundColor: "grey", height: "18px", width: "59px", color: " #ffffff", fontWeight: "500", fontSize: "15px","letter-spacing":"9px" }}>30%</div> :
                          <div style={{ backgroundColor: "grey", height: "18px", width: "59px" }}>30%</div>

                        }
                      </div>

                    </Tooltip>
                  </Marker> */}
                <Marker position={[186.9, 765]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Fire Status
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        OFF
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[287.9, 719]} icon={iconDevice1}>
                  <Tooltip direction="right" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        RA CO2
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        900ppm
                      </div>
                    </div>
                  </Tooltip>
                </Marker>

                <Marker position={[102.91, 772.284]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Supply Air RH
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        70%
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[286.921, 216]} icon={iconDevice1}>
                  <Tooltip direction="right" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Mixed Air Damper
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        80%
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[340.921, 97]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        Exhaust
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        ON
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
              </Map>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Grid container item spacing={1} direction="column">
              <Grid item xs={12}>
                <Box
                  boxShadow={3}
                  className={classes.paper}
                  style={{
                    backgroundColor: " #ffffff",
                    borderRadius: "20px",
                    height: "49vh",
                  }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={6}>
                        <div
                          style={{
                            marginTop: "2vh",
                            marginLeft: "-5vh",
                            fontWeight: "300",
                            // marginBottom: "3px",
                            textDecoration: "none",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Typography className={classes.text}>
                            AHU On/Off
                          </Typography>
                          <Typography
                            className={classes.text}
                            style={{ marginTop: "1vh", marginLeft: "-3vh" }}
                          >
                            RAT sp
                          </Typography>
                          <Typography
                            className={classes.text}
                            style={{ marginTop: "1vh", marginLeft: "-3vh" }}
                          >
                            SAT sp
                          </Typography>
                        </div>
                      </GridItem>
                      <GridItem xs={6}>
                        <div
                          style={{
                            marginTop: "2vh",
                            marginLeft: "5vh",
                            fontWeight: "300",
                            textDecoration: "none",
                            justifyContent: "space-evenly",
                            autosize: true,
                          }}
                        >
                          <div
                            className="your-required-wrapper"
                            style={{ minWidth: "14vh", marginLeft: "-7vh" }}
                          >
                            <SwitchSelector
                              onChange={onChange}
                              options={options}
                              // initialSelectedIndex={initialSelectedIndex}
                              forcedSelectedIndex={onOff}
                              backgroundColor={"rgba(0, 0, 0, 0.04)"}
                              fontColor={"#000"}
                              selectedFontColor={"#000"}
                              border="5"
                              optionBorderRadius={0}
                              wrapperBorderRadius={0}
                              fontSize={12}
                            />
                          </div>
                          <Grid
                            container
                            item
                            direction="row"
                            justify="flex-start"
                          >
                            <Grid item xs={6}>
                              <TextField
                                // label="%"
                                placeholder={value8 + "℃"}
                                // style={{marginTop:'3px',marginLeft:'18px',"letter-spacing":"9px",width:'45px'}}
                                name="set_point"
                                autoComplete="off"
                                value={setpt}
                                onChange={handleChangeForsetpoint}
                                className={classes.control1}
                                // variant="outlined"
                                // style={{ marginTop: '-1px' }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="Blue"
                                onClick={handleClick}
                                className={classes.setptbutton}
                              >
                                set
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid container item direction="row">
                            <Grid item xs={6}>
                              <TextField
                                // label="%"
                                placeholder={value9 + "℃"}
                                // style={{marginTop:'3px',marginLeft:'15px',width:'45px'}}
                                name="Sat_set_point"
                                autoComplete="off"
                                value={setSat}
                                onChange={handleChangeForsetpointSAT}
                                className={classes.control1}
                                // variant="outlined"
                                // style={{ marginTop: '-1px' }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="Blue"
                                onClick={handleClickSat}
                                className={classes.setptbutton}
                              >
                                set
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{ marginTop: "22vh", marginLeft: "-10vh" }}
                    >
                      <CalendarToday color="primary" />
                      <ButtonBase
                        style={{ color: "#3f51b5" }}
                        onClick={() => handleLocationClick()}
                      >
                        Scheduler
                      </ButtonBase>
                    </Grid>
                  </CardFooter>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  className={classes.paper}
                  style={{
                    backgroundColor: " #0123b4",
                    borderRadius: "12px",
                    width: "max-content",
                    minWidth: "100%",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    VAV
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={9}>
            <Grid container item spacing={1} direction="row">
              {Object.keys(ahu1).map((key) => (
                <Grid item xs={4}>
                  <Box
                    boxShadow={3}
                    className={classes.paper}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                      height: "25vh",
                      alignContent: "center",
                    }}
                  >
                    <TimeSeriesChart
                      style={{ width: "100%", height: "100%" }}
                      data={ahu1[key]}
                      param={key}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container xs={12} spacing={1} direction="row">
              <Grid item xs={12}>
                {/* <Box boxShadow={3} className={classes.paper} style={{height:"25vh"}}>1</Box> */}
                <Box
                  boxShadow={3}
                  className={classes.paper}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "25vh",
                    alignContent: "center",
                  }}
                >
                  <Chart
                    options={state1.options}
                    series={state1.series}
                    type="bar"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </React.Fragment>  */}
      </Grid>
      <SemanticToastContainer position="top-center" />
    </div>
  );
}
