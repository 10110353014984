/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from "@material-ui/core/Icon";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import Collapse from "@material-ui/core/Collapse";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import BusinessIcon from '@material-ui/icons/Business';
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { TrainRounded } from "@material-ui/icons";
import StarBorder from '@material-ui/icons/StarBorder';
import Notifications from "@material-ui/icons/Notifications";
import AssessmentIcon from '@material-ui/icons/Assessment';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';


const drawerWidth = '15%';
const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }
}));


export default function Sidebar(props) {
  const classes = useStyles();
  const classes1 = useStyles1();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [AlOpen, setAlOpen] = React.useState(false);
  const [rou, setRou] = React.useState([]);
  const [controlOpen,setControlOpen]=React.useState(false);
  const [spaceOpen,setSpaceOpen]=React.useState(false);
  const [reportOpen,setReportOpen]=React.useState(false);
  const role_id = localStorage.getItem('roleID')


  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickAl = () => {
    setAlOpen(!AlOpen);
  };
  const handleControl = () =>{
    setControlOpen(!controlOpen)
  };
  const handleSpace = () =>{
    setSpaceOpen(!spaceOpen)
  };
  const handleReport = () =>{
    setReportOpen(!reportOpen)
  };

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        let urlPath = prop.path;
        if(urlPath.indexOf("/:id/") > -1) {
          let splitPaths = urlPath.split("/:id/")
          let assetId = splitPaths[0].includes("building") ? localStorage.getItem("buildingID") : splitPaths[0].includes("floor") ? localStorage.getItem("floorID") : splitPaths[0].includes("zone") ? localStorage.getItem("zoneID"):  localStorage.getItem("deviceID")
          urlPath = splitPaths[0] + "/" + assetId + "/" +splitPaths[1];
        }
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + urlPath)
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + urlPath)
        });
        return (
          <NavLink
            to={prop.layout + urlPath}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="http://www.vyoda.com/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </a>
      {/* <IconButton onClick={props.handleDrawerClose} style={{zIndex: "4", float: "right", marginTop: "-15%"}}>
          <ChevronLeftIcon    className={classes.itemIcon}/>
      </IconButton> */}
    </div>
  );
  return (
   <div> 
        <Hidden  mdUp>
        <Drawer
        className={classes1.drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
        classes={{
          paper: classes1.drawerPaper,
        }}
      >
        {brand}
        <div className={classes.sidebarWrapper}>
        <AdminNavbarLinks/>
           {/* {links} */}
        </div>
        {image !== undefined ? (
          <div
          className={classes.background}
          style={{ backgroundImage: "url(" + image + ")" }}
          />
        ) : null}
      </Drawer>
        </Hidden>

      <Hidden smDown implementation="css">
     <Drawer
        className={classes1.drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
        classes={{
          paper: classes1.drawerPaper,
        }}
      >
        <div
          className={classes.background}
         // style={{ backgroundColor:'#0122B4'}}
          />
        {/* {image !== undefined ? (
          <div
          className={classes.background}
          style={{ backgroundImage: "url(" + image + ")" }}
          />
        ) : null} */}
        {brand}
        <Divider />
        <div className={classes.sidebarWrapper}>
            {/* {links} */}
            <List >
              <NavLink
                to={'/admin/dashboard'}
                className={" " + classes.item}
                activeClassName="active"
              >
                <ListItem button className={classes.itemLink + classNames({
                  [" " + classes[color]]: activeRoute('/admin/dashboard')
                })}>

                  <BusinessIcon
                    className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/dashboard') })}
                  />

                  <ListItemText
                    primary={'Dashboard'}
                    className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/dashboard') })}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
              { role_id == 2 ?
              <div className={classNames({[" " + classes[color]]: controlOpen})}  style={{borderRadius: '14px',margin:'10px,5px'}}>
              <ListItem button className={classes.itemLink}  onClick={handleControl} >
                  <BusinessIcon   className={classes.itemIcon}/>   
                  <ListItemText
                    primary={'User Management'}
                    className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
                    disableTypography={true}
                  />
                   <ListItemSecondaryAction className={classes.expandIcon} >
                   {controlOpen ? <ExpandMore
                      onClick={handleControl} /> : <ArrowForward className={classes.arrow} onClick={handleControl} />}
                    </ListItemSecondaryAction>
                 </ListItem>
                 <div className={classes.item} >
                 <Collapse in={controlOpen} timeout="auto" unmountOnExit >
              <NavLink
                to={'/admin/vdevice'}
                className={" " + classes.item}
                activeClassName="active"
              >
                <ListItem button className={classes.itemLink + classNames({
                  [" " + classes[color]]: activeRoute('/admin/vdevice')
                })}>

                  {/* <BusinessIcon
                    className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/dashboard') })}
                    style={{marginLeft:"15px"}}
                  /> */}

                  <ListItemText
                    primary={'Add device'}
                    className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/vdevice') })}
                    disableTypography={true}
                    style={{padding:'0px 2em'}}
                  />
                </ListItem>
              </NavLink>
              <NavLink
                to={'/admin/addUser'}
                className={" " + classes.item}
                activeClassName="active"
              >
                <ListItem button className={classes.itemLink + classNames({
                  [" " + classes[color]]: activeRoute('/admin/addUser')
                })}>

                  {/* <BusinessIcon
                    className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/dashboard') })}
                    style={{marginLeft:"15px"}}

                  /> */}

                  <ListItemText
                    primary={'Add User'}
                    className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/addUser') })}
                    disableTypography={true}
                    style={{padding:'0px 2em'}}
                  />
                </ListItem>
              </NavLink>
              <NavLink
                to={'/admin/Vmap'}
                className={" " + classes.item}
                activeClassName="active"
              >
                <ListItem button className={classes.itemLink + classNames({
                  [" " + classes[color]]: activeRoute('/admin/Vmap')
                })}>

                  {/* <BusinessIcon
                    className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/dashboard') })}
                    style={{marginLeft:"15px"}}

                  /> */}

                  <ListItemText
                    primary={'Mapping'}
                    className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/Vmap') })}
                    disableTypography={true}
                    style={{padding:'0px 2em'}}

                  />
                </ListItem>
              </NavLink>
              <NavLink
                to={'/admin/changepassword'}
                className={" " + classes.item}
                activeClassName="active"
              >
                <ListItem button className={classes.itemLink + classNames({
                  [" " + classes[color]]: activeRoute('/admin/changepassword')
                })}>

                  {/* <BusinessIcon
                    className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/dashboard') })}
                    style={{marginLeft:"15px"}}

                  /> */}

                  <ListItemText
                    primary={'Change Password'}
                    className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/changepassword') })}
                    disableTypography={true}
                    style={{padding:'0px 2em'}}

                  />
                </ListItem>
              </NavLink>
            
              </Collapse>
              </div>
              </div>
              :<div></div> }
            </List>
          </div>
        </Drawer>
      </Hidden>
   </div>
       
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red","info"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};

