//import {React, useEffect, useState} from "react";
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import api from '../../api';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { format } from 'date-fns';
import moment from 'moment';
const styles = theme => ({
  loader: {
    size: "40px",
    color: "#26c6da"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});
const useStyles = makeStyles(styles);
// function getTableData(myinput = [], param_map = {}) {
//   var myoutput = [], odata = [];
//   var param_ids = {}, output_ids = {}, i = 0, j = 0;
//   var params = Object.keys(param_map);
//   for (i = 0; i < myinput.length; i++) {
//     odata = [];
//     for (j = 0; j < params.length; j++) {
//       odata[j] = myinput[i][params[j]];
//     }
//     myoutput.push(odata);
//   }

//   return myoutput;

// }
function getColumns(myheading = null) {
  // myheading =(myheading==null)? {'DeviceName':"DeviceName",'date':"date",'time':"time", 'Category':" Category", 'Key':"Key",'Value': "Value",'Unit': "Unit",'Set by': "Set by",'Criticality': "Criticality"}:myheading;
  myheading = (myheading === null) ? { 'DeviceName': "DeviceName", 'date': "date", 'time': "time", 'Category': " Category", 'Key': "Key", 'Value': "Value", 'Unit': "Unit" } : myheading;
  let mykeys = Object.keys(myheading);
  let columnDefs = [];
  for (let i = 0; i < mykeys.length; i++) {
    switch (mykeys[i]) {
      case 'DeviceName':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '150px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'Timestamp':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agDateColumnFilter', width: '200px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'Date':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agDateColumnFilter', width: '100px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'Time':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agDateColumnFilter', width: '100px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'measured_time': case "Acknowledged": case "Ignore_alarm": case 'Category': case 'Criticality': case 'triggered_time': case 'Ignore': case 'Modified_at': case 'ActiveTime_in_sec':
        // columnDefs.push({'field': mykeys[i], 'filter': 'agDateColumnFilter',width:'200px', 'sortable': true,cellStyle:{'border-right-color':'#e2e2e2'}});
        break;
      case 'Category':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '200px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'username':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '150px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'Value':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '100px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'LoginDate':
          columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '100px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'ActiveTime':
            columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '100px', 'sortable': true, cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;

      default:
        columnDefs.push({ 'field': mykeys[i], 'filter': true, 'sortable': true, flex: '1', cellStyle: { 'border-right-color': '#e2e2e2' } });
    }

  }
  return columnDefs;
}
function prepareReportData(rData) {
  rData.map((r_d) => {
    let mes_time = r_d["measured_time"].split(' ');
    r_d.date = mes_time[0]
    r_d.time = mes_time[1]
  })

  return { "Headings": getColumns(rData[0]), "RowData": rData }
}
function prepareUserReportData(rData) {
  rData.map((r_d) => {
    let mes_time = r_d["triggered_time"].split(' ');
    r_d.date = mes_time[0]
    r_d.time = mes_time[1]
  })

  return { "Headings": getColumns(rData[0]), "RowData": rData }
}
function prepareUserLoginReportData(rData) {

rData.map((r_d)=>{
  const time = new Date(0,0,0)
    time.setSeconds(r_d["ActiveTime_in_sec"])
     r_d.ActiveTime = ((time.getHours())+':'+(time.getMinutes())+':'+(time.getSeconds()))
  })
  return { "Headings": getColumns(rData[0]), "RowData": rData }
}
function GlEventsViewer() {
  let reportOptions = [
    { "option": "ibmsevents", "title": "IBMS LOGS", "api": 'ibmsevents_table' },
    { "option": "DeviceCritical", "title": "Device Critical Alarms", "api": 'alarmDeviceCritical' },
    // {"option":"DeviceTypeCritical","title":"DeviceType Critical","api":'alarmDeviceTypeCritical'},
    { "option": "mostActiveAlarmCritical", "title": "Most Active Critical Alarms", "api": 'mostActiveAlarmCritical' },
    { "option": "restorecriticalalarms", "title": "Critical Alarms Restored", "api": 'criticalAlarmRestored' },
    { "option": "alarmDevice", "title": "Device NonCritical Alarms", "api": 'alarmDeviceNonCritical' },
    // {"option":"alarmDeviceType","title":"DeviceType Alarms","api":'alarmDeviceTypeNonCritical'},
    { "option": "mostActiveAlarmNonCritical", "title": "Most Active NonCritical Alarms", "api": 'mostActiveAlarmNonCritical' },
    { "option": "restorenoncriticalalarms", "title": "Non-Critical Alarms Restored", "api": 'nonCriticalAlarmRestored' },
    { "option": "UserLoginReport", "title": "User Login Report", "api": 'loginLogoutDetails' },
    { "option": "TrendLogComparisionReport", "title": "Trend Log Comparision Report", "api": 'TrendLogComparisionReport' },
    { "option": "scheduleActivityReport", "title": "AHU Schedule Activity Report", "api": 'scheduleActivityReport' },
    { "option": "ActivitesperuserReport", "title": "Activites Per User Report", "api": 'ActivitesperuserReport' },
    { "option": "ActivitesperserverReport", "title": "Activites Per Server Report", "api": 'ActivitesperserverReport' },
    { "option": "UserControlDetails", "title": "User Contol Details", "api": 'userDetails' },
    { "option": "AcknowledgedUser", "title": "Alarms Acknowledged By User", "api": 'acknowledgedUser' },
    { "option": "IgnoredUser", "title": "Alarms Ignored By User", "api": 'ignoredUser' }
  ]
  // const[reportOptions,setReportOptions]=useState(reports);
  // const [headings,setHeadings]=useState(getColumns());

  const classes = useStyles();
  const gridRef = useRef();
  // const [eventdata,setEventData]=React.useState([]);
  const [reportData, setReportData] = React.useState({ "Headings": getColumns(), "RowData": [] });
  // const [selectedRows, setSelectedRows] = useState();
  const [isloaded, setIsLoaded] = React.useState(true);
  const [stitle, setStitle] = useState('ibmsevents')
  // const [age, setAge] = React.useState('');



  // useEffect(()=>{
  //     if(process.env.REACT_APP_ENVIRONMENT=='cloud'){
  //         setTimeout(() => {
  //             eventdata();
  //         }, 2600);
  //         setIsLoaded(false);
  //     }
  //     else
  //     {
  //     setIsLoaded(true);
  //     api.reports.ibmsevents_table().then(res=>{
  //         let sample=[]
  //         sample=res;
  //         res.map((res) =>{
  //           let mes_time=res.Timestamp.split(' ')
  //           res.date=mes_time[0]
  //           res.time=mes_time[1]
  //         })
  //         // setEventData(getTableData(res,myheading));
  //         setEventData(res);    
  //     })


  // }
  // },[]);
  useEffect(() => {
    api['reports']['ibmsevents_table']().then(res => {
      setReportData(prepareReportData(res))
    })
  }, [])
  const handleReportSelection = (myapi, option) => {
    setStitle(option)
    switch (option) {
      case 'DeviceCritical':
        api['reports']['critical_table']().then(res => {
          // setHeadings(getColumns(res[0]));
          //  setEventData(res);

          setReportData(prepareReportData(res));
        });
        break;

      case 'DeviceTypeCritical':
        api['reports']['Device_critical']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'mostActiveAlarmCritical':
        api['reports']['active_alarm']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'restorecriticalalarms':
        api['reports']['restore_critical_alarm']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'alarmDevice':
        api['reports']['noncritical_table']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'alarmDeviceType':
        api['reports']['device_nonCritical']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'mostActiveAlarmNonCritical':
        api['reports']['non_criticalalarm']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'restorenoncriticalalarms':
        api['reports']['restore_noncritical_alarm']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'ibmsevents':
        api['reports']['ibmsevents_table']().then(res => {
          setReportData(prepareReportData(res))
        })
        break;
      case 'UserControlDetails':
        api['reports']['user_control_deatils']().then(res => {
          setReportData(prepareUserReportData(res))
        })
        break;
      case 'AcknowledgedUser':
        api['reports']['acknowledged_by_user']().then(res => {
          setReportData(prepareReportData(res))
        })
        break;
      case 'IgnoredUser':
        api['reports']['ignored_by_user']().then(res => {
          setReportData(prepareReportData(res))
        })
        break;
      case 'UserLoginReport':
          api['reports']['login_login_details']().then(res => {
            setReportData(prepareUserLoginReportData(res))
          })
          break;
      default:
        // api['reports']['ibmsevents_table']().then(res => {    
        //   setReportData(prepareReportData(res))
        // })
        setReportData([])
        break;



    }




  }

  // const handleeclick = () => {
  //   api.reports.critical_table().then(res =>{
  //        setTableData(res)
  //   })
  //     }

  // const modalClick = () => {
  //   api.reports.noncritical_table().then(res =>{
  //      setTestdata(res)
  //   })
  //     }
  // const headerClick = () => {
  //   api.reports.most_activealarm().then(res => {
  //     setData(res)

  //   })
  // }

  // const sizeToFit = useCallback(() => {
  //   gridRef.current.api.sizeColumnsToFit();
  // }, []);

  // const autoSizeAll = useCallback((skipHeader) => {
  //   const allColumnIds = [];
  //   gridRef.current.columnApi.getAllColumns().forEach((column) => {
  //     allColumnIds.push(column.getId());
  //   });
  //   gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader)
  // }, []);

  // const onFirstDataRendered = useCallback((params) => {
  //   gridRef.current.api.sizeColumnsToFit();
  // }, []);
  const onFirstDataRendered1 = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const onSelectionChanged = useCallback((event) => {
    // setSelectedRows(event.api.getSelectedRows());
    // window.alert('selection changed, ' + rowCount + ' rows selected');
  }, []);
  return (

    <div>
      <FormControl variant="outlined" className={classes.formControl} style={{ width: '455px', marginTop: '-30px', marginLeft: '10px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Reports</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Floor"
          value={stitle}
        // onChange={handlefloorchange}
        >
          {reportOptions.map(_item => (
            _item.title !== "User Login Report" || _item.title !== "Trend Log Comparision Report" || _item.title !== "Schedule Activity Report" || _item.title !== "Activites Per User Report" || _item.title !== "Activites Per Server Report" ?
              <MenuItem key={_item.option} value={_item.option} onClick={() => handleReportSelection(_item.api, _item.option)}>{_item.title}</MenuItem>
              :
              <MenuItem key={_item.option} value={_item.option} style={{ "backgroundColor": 'Gray' }}>{_item.title}</MenuItem>
          ))}
        </Select>
      </FormControl>


      <div>
        <button
          onClick={onBtExport}
          style={{ marginBottom: '5px', fontWeight: 'bold' }}
        >
          Export to Csv
        </button>
      </div>



      {isloaded === false ?
        <div style={{ "textAlign": "center", "marginTop": "150px" }}>
          <CircularProgress className={classes.loader} />
        </div>
        :
        <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={reportData["RowData"]}
            columnDefs={reportData["Headings"]}
            rowSelection={'multiple'}
            onSelectionChanged={onSelectionChanged}
            pagination={true}
            paginationPageSize={10}
            onFirstDataRendered={onFirstDataRendered1}

            suppressMenuHide="true"


          >
          </AgGridReact>






        </div>
      }
    </div>
  )
}

export default GlEventsViewer
