const initialState = {
    data: {}
}


const loggedReducer = (state = initialState, action) => {
    switch (action.type) {
        case "login":
            return { ...state, data: action.payload };
        case "logout":
            return state;
        default:
            return state;
    }
}

export default loggedReducer;