import React from 'react'

function userName() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
    <g id="Group_128" data-name="Group 128" transform="translate(-1670 -439)">
      <circle id="Colour" cx="22" cy="22" r="22" transform="translate(1670 439)" fill="rgba(0,122,255,0.12)"/>
      <path id="Placeholder_Label" data-name="Placeholder Label" d="M5.371,2.374h13.5q2.632,0,2.632-1.719a4.748,4.748,0,0,0-.645-2.245A8.131,8.131,0,0,0,19-3.84a9.835,9.835,0,0,0-2.954-1.724,10.858,10.858,0,0,0-3.916-.666A10.891,10.891,0,0,0,8.2-5.564,9.835,9.835,0,0,0,5.248-3.84,8.131,8.131,0,0,0,3.384-1.59,4.748,4.748,0,0,0,2.739.655Q2.739,2.374,5.371,2.374ZM4.866.752A.548.548,0,0,1,4.56.688.27.27,0,0,1,4.469.451a3.142,3.142,0,0,1,.5-1.52,6.285,6.285,0,0,1,1.477-1.67A8.3,8.3,0,0,1,8.852-4.077a9.357,9.357,0,0,1,3.276-.532,9.312,9.312,0,0,1,3.271.532,8.316,8.316,0,0,1,2.4,1.337,6.285,6.285,0,0,1,1.477,1.67,3.142,3.142,0,0,1,.5,1.52.27.27,0,0,1-.091.236.523.523,0,0,1-.3.064Zm7.262-8.314a4.174,4.174,0,0,0,2.331-.687,5.051,5.051,0,0,0,1.681-1.832,5.167,5.167,0,0,0,.628-2.53,5,5,0,0,0-.623-2.487,4.816,4.816,0,0,0-1.676-1.767,4.342,4.342,0,0,0-2.342-.655,4.272,4.272,0,0,0-2.331.666,4.934,4.934,0,0,0-1.681,1.783,4.973,4.973,0,0,0-.628,2.481,5.145,5.145,0,0,0,.628,2.519A5.006,5.006,0,0,0,9.8-8.245,4.2,4.2,0,0,0,12.128-7.562Zm0-1.622a2.488,2.488,0,0,1-1.445-.457,3.335,3.335,0,0,1-1.063-1.23,3.664,3.664,0,0,1-.4-1.719,3.521,3.521,0,0,1,.4-1.681,3.208,3.208,0,0,1,1.058-1.187,2.569,2.569,0,0,1,1.456-.44,2.633,2.633,0,0,1,1.466.43,3.079,3.079,0,0,1,1.053,1.176,3.56,3.56,0,0,1,.392,1.681,3.737,3.737,0,0,1-.4,1.724,3.325,3.325,0,0,1-1.058,1.241A2.485,2.485,0,0,1,12.128-9.185Z" transform="translate(1680 467)" fill="#0123b4"/>
    </g>
  </svg>  
  )
}

export default userName