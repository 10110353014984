import React, {  useEffect, useState } from "react";
import ReactApexCharts from 'react-apexcharts'
import { format, compareAsc, toDate,addMinutes } from 'date-fns'

export default function ApexChart(props)  {
    
    
    const { data,param} = props;
    let minimum = 0, maximum = 0;
    let elements = new Map(), elementsMin = new Map(), elementsMax = new Map();
    data.map((_elem, i) => {
        var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
        elements.set(i, {x: myDate, y: parseFloat(_elem.param_value).toFixed(2)})
    });
    var elemArr = [], elemArrMin = [], elemArrMax = [];
    for(let i of elements.values()) {
        elemArr.push(i)
    }
    const dataset = {
          
        series: [{
          name: param,
          data: elemArr
        }],
        options: {
          chart: {
            type: 'line',
            stacked: false,
            // height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            
             
            },
            toolbar: {
              autoSelected: 'zoom'
            }
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },
          title: {
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.5,
              inverseColors: false,
              opacityFrom: 0.3,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          yaxis: {
             tickAmount:2,
            title: {
            },
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: false,
            // y: {
            //   formatter: function (val) {
            //     return (val / 1000000).toFixed(0)
            //   }
            // }
          }
        },
      
      
      };
    // }  
    return (
    <div id="chart">
        <ReactApexCharts options={dataset.options} series={dataset.series} type="area"   />
    </div>
    );
    }
 




  
